import {BaseAnalyticsEvents} from "./BaseAnalyticsEvents";
import {AnalyticsCategory, MainSearchAnalyticsAction} from "./Analytics";

class MainSearchAnalyticsEvents extends BaseAnalyticsEvents {
    trackSearchFormDatePeriodChoose = (nightsCount: number) => (
        this.trackEvent(
            MainSearchAnalyticsAction.ChooseDatePeriodInSearchForm,
            "выбор дат в форме поиска",
            {nightsCount}
        )
    );

    trackSearchFormGuestsChange = (adultsCount: number, childrenCount: number) => (
        this.trackEvent(
            MainSearchAnalyticsAction.ChangeGuestsInSearchForm,
            "изменение гостей в форме поиска",
            {adultsCount, childrenCount}
        )
    );

    trackSearchFormRunSearch = () => (
        this.trackEvent(
            MainSearchAnalyticsAction.RunSearchInSearchForm,
            "запуск поиска номеров в форме поиска"
        )
    );

    trackRoomCategoriesRunSearch = () => (
        this.trackEvent(
            MainSearchAnalyticsAction.RunSearchInRoomCategories,
            "запуск поиска номеров в списках категорий"
        )
    );

    trackFixedButtonRunSearch = () => (
        this.trackEvent(
            MainSearchAnalyticsAction.RunSearchWithFixedButton,
            "запуск поиска номеров с помощью фиксированной кнопки"
        )
    );
}

export const mainSearchAnalyticsEvents = new MainSearchAnalyticsEvents(AnalyticsCategory.MainSearch);
