import {IconTypes, IIconProps} from "./Icon";
import {People1Icon16Light} from "@skbkontur/icons/People1Icon16Light";
import {People1Icon16Solid} from "@skbkontur/icons/People1Icon16Solid";
import {People1Icon16Regular} from "@skbkontur/icons/People1Icon16Regular";
import {People1Icon20Regular} from "@skbkontur/icons/People1Icon20Regular";
import {People1Icon20Solid} from "@skbkontur/icons/People1Icon20Solid";
import {People1Icon24Regular} from "@skbkontur/icons/People1Icon24Regular";

type PeopleIconTypes = IconTypes.Light16 | IconTypes.Regular16 | IconTypes.Solid16 | IconTypes.Regular20 | IconTypes.Solid20 | IconTypes.Regular24;

interface IPeopleIconProps extends IIconProps {
    type: PeopleIconTypes;
}

const PeopleIcon = ({type, ...iconProps}: IPeopleIconProps) => {
    switch (type) {
        case IconTypes.Light16:
            return <People1Icon16Light {...iconProps} />;
        case IconTypes.Solid16:
            return <People1Icon16Solid {...iconProps} />;
        case IconTypes.Regular16:
            return <People1Icon16Regular {...iconProps} />;
        case IconTypes.Regular20:
            return <People1Icon20Regular {...iconProps} />;
        case IconTypes.Solid20:
            return <People1Icon20Solid {...iconProps} />;
        case IconTypes.Regular24:
            return <People1Icon24Regular {...iconProps} />;
    }
};
PeopleIcon.displayName = "PeopleIcon";
export default PeopleIcon;
