import {Flipper} from "react-flip-toolkit";
import raf from "raf-throttle";
import cn from "classnames";
import {DateCalculate, DateCompare, DateFormat, DateHelper, UnitOfTime} from "@skbkontur/hotel-date";
import {CalendarDataMapType} from "../../data/Calendar/Calendar";
import FlippedElement from "../../common/components/Flipper/Element/FlippedElement";
import {CalendarHelper} from "../../data/Calendar/CalendarHelper";
import {FullDateDayFirstString, MonthWithYearString} from "../../data/Date";
import CalendarControls from "./CalendarControls";
import styles from "./Calendar.scss";
import {CalendarContext} from "./Provider/CalendarContext";
import CalendarMonth from "./Month/CalendarMonth";

export interface ICalendarProps {
    calendarData: CalendarDataMapType;
    initialDate?: string;
    offsetInMinutes?: number;
    onMonthChange?: (yearMonth: FullDateDayFirstString) => void;
}

const dateFormatYearMonth = (date: FullDateDayFirstString): MonthWithYearString => (
    DateHelper.convert(date, DateFormat.FullDateDayFirst, DateFormat.MonthWithYear)
);

const getLastAvailableDate = (monthsToDisplay: 1 | 2, offsetInMinutes?: number): FullDateDayFirstString => {
    const todayDate = DateCalculate.getTodayWithTimezone(DateFormat.FullDateDayFirst, offsetInMinutes);
    const lastAvailableDate = DateCalculate.add({
        amount: 2,
        date: todayDate,
        format: DateFormat.FullDateDayFirst,
        unitOfTime: UnitOfTime.Year
    });
    if (monthsToDisplay === 2) {
        return DateCalculate.add({
            amount: -1,
            date: lastAvailableDate,
            format: DateFormat.FullDateDayFirst,
            unitOfTime: UnitOfTime.Month
        });
    }
    return lastAvailableDate;
};

const CALENDAR_MONTH_MARGIN = 40;

const Calendar = (props: ICalendarProps) => {
    const {
        calendarData,
        offsetInMinutes,
        initialDate = DateCalculate.getTodayWithTimezone(DateFormat.FullDateDayFirst, offsetInMinutes),
        onMonthChange
    } = props;

    const {monthsToDisplay} = React.useContext(CalendarContext);
    const [currentDate, setCurrentDate] = React.useState<FullDateDayFirstString>(initialDate);
    const [datesForSlider, setDatesForSlider] = React.useState<FullDateDayFirstString[]>(
        CalendarHelper.getMonthsForSlider(currentDate, monthsToDisplay)
    );

    React.useEffect(() => {
        onMonthChange(currentDate);
    }, [currentDate]);

    React.useEffect(() => {
        setDatesForSlider(CalendarHelper.getMonthsForSlider(currentDate, monthsToDisplay));
    }, [currentDate, monthsToDisplay]);

    const format = DateFormat.FullDateDayFirst;

    const showNextMonth = () => setCurrentDate(
        DateCalculate.add({
            amount: 1,
            date: currentDate,
            format,
            unitOfTime: UnitOfTime.Month
        })
    );
    const showPrevMonth = () => setCurrentDate(
        DateCalculate.add({
            amount: -1,
            date: currentDate,
            format,
            unitOfTime: UnitOfTime.Month
        })
    );

    const leftArrowIsHidden = DateCompare.isSame({
        firstDate: DateCalculate.getTodayWithTimezone(format, offsetInMinutes),
        secondDate: currentDate,
        format,
        unitOfTime: UnitOfTime.Month
    });

    const rightArrowIsHidden = DateCompare.isSame({
        firstDate: getLastAvailableDate(monthsToDisplay, offsetInMinutes),
        secondDate: currentDate,
        format,
        unitOfTime: UnitOfTime.Month
    });

    const sliderViewportRef = React.useRef<HTMLDivElement>();
    const [width, setWidth] = React.useState<number>(null);

    const updateWidth = React.useMemo(() => (
        raf(() => {
            const calendarWidth = sliderViewportRef.current.clientWidth;
            const monthsMargins = (monthsToDisplay - 1) * CALENDAR_MONTH_MARGIN;
            const widthWithoutMargins = calendarWidth - monthsMargins;
            setWidth(widthWithoutMargins / monthsToDisplay);
        })
    ), [monthsToDisplay]);

    React.useEffect(() => {
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    }, [updateWidth]);

    React.useEffect(() => {
        updateWidth();
    }, [monthsToDisplay, calendarData]);

    return (
        <div
            className={cn(styles.calendar, {
                [styles.oneMonth]: monthsToDisplay === 1,
                [styles.twoMonths]: monthsToDisplay === 2
            })}
        >
            <CalendarControls
                leftArrowIsHidden={leftArrowIsHidden}
                rightArrowIsHidden={rightArrowIsHidden}
                onLeftArrowClick={showPrevMonth}
                onRightArrowClick={showNextMonth}
            />
            <div className={styles.sliderViewport} ref={sliderViewportRef}>
                <Flipper
                    className={styles.slider}
                    flipKey={datesForSlider.join()}
                >
                    {datesForSlider.map(date => {
                        const dateInFormatYearMonth = dateFormatYearMonth(date);
                        return (
                            <FlippedElement
                                key={dateInFormatYearMonth}
                                flipId={dateInFormatYearMonth}
                                withDiv
                            >
                                <CalendarMonth
                                    monthNumberFromZero={DateHelper.getMonth(date, DateFormat.FullDateDayFirst)}
                                    year={DateHelper.getYear(date, DateFormat.FullDateDayFirst)}
                                    monthData={calendarData[dateInFormatYearMonth]}
                                    isSingleMonth={monthsToDisplay === 1}
                                    width={width}
                                />
                            </FlippedElement>
                        );
                    })}
                </Flipper>
            </div>
        </div>
    );
};
Calendar.displayName = "Calendar";
export default Calendar;
