import {useSelector} from "react-redux";
import {IAppState} from "../../../store/AppState";
import {withWidgetContainerAndRefHOC} from "../Widget/withWidgetContainerAndRefHOC";
import HourlyObjectsList from "../../../components/HourlyObjectsList/HourlyObjectsList";

const WidgetHourlyObjectsList = () => {
    const hourlyObjects = useSelector((state: IAppState) => state.hourlyObjects?.hourlyObjects);

    if (!hourlyObjects?.length) return null;

    return (
        <HourlyObjectsList/>
    );
};
WidgetHourlyObjectsList.displayName = "WidgetHourlyObjectsList";
export default withWidgetContainerAndRefHOC(WidgetHourlyObjectsList);
