import dayjs from "../dayjsConfiguration";
import {ConfigDateType} from "./Date";
import {DateFormat} from "./DateFormat";

export class DateCheck {
    static isValid = (
        date: ConfigDateType,
        format: DateFormat = DateFormat.FullDateDayFirst
    ): boolean => (
        dayjs(date, format).isValid()
    );
}

