export type NMapStore<TValue> = Map<unknown, NMapStore<TValue> | TValue>;
const valueRef = () => {};

// TODO Add common size calculation and Sentry warnings on big amount of size

export class NKeysMap<TValue> {
    store: NMapStore<TValue> = new Map();

    set = (keySet: unknown[], value: TValue) => {
        let operationMap = this.store;
        for (let i = 0, l = keySet.length; i < l; ++i) {
            let childOperationMap = operationMap.get(keySet[i]) as NMapStore<TValue>;
            if (childOperationMap === undefined) operationMap.set(keySet[i], childOperationMap = new Map());
            operationMap = childOperationMap;
        }
        operationMap.set(valueRef, value);
    };

    get = (keySet: unknown[]): TValue => {
        const operationMap = this.getOperationMap(keySet);
        if (operationMap === undefined) return;
        return operationMap.get(valueRef) as TValue;
    };

    delete = (keySet: unknown[]): void => {
        const operationMap = this.getOperationMap(keySet);
        if (operationMap === undefined) return;

        operationMap.delete(valueRef);
        if (operationMap !== this.store && operationMap.size === 0) {
            this.tryClean(operationMap, keySet);
        }
    };

    private getOperationMap = (keySet: unknown[]): NMapStore<TValue>  => {
        let operationMap = this.store;
        for (let i = 0, l = keySet.length; i < l; ++i) {
            operationMap = operationMap.get(keySet[i]) as NMapStore<TValue>;
            if (operationMap === undefined) return;
        }
        return operationMap;
    };

    private tryClean = (operationMap: NMapStore<TValue>, keySet: unknown[]): void => {
        for (let i = 0, l = keySet.length; i < l; ++i) {
            const currentKeySetItemIndex = l - i - 1;
            const parentKeySet = keySet.slice(0, currentKeySetItemIndex);
            const parentOperationMap = this.getOperationMap(parentKeySet);

            if (operationMap.size === 0) {
                parentOperationMap.delete(keySet[currentKeySetItemIndex]);
            } else {
                return;
            }

            operationMap = parentOperationMap;
            keySet = parentKeySet;
        }
    };
}
