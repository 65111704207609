import {IHotelFeature} from "../data/HotelFeature";
import {IWithOrder} from "../types/WithOrder";
import {IOccupancy} from "../data/Accommodation";
// TODO скопировано из Отеля

export class Compare {
    static byOrder = <TItem extends IWithOrder>(a: TItem, b: TItem) => (
        (a?.order || 0) - (b?.order || 0)
    );

    static asc = (a: number, b: number) => a - b;

    static strings = (a: string, b: string) => (
        a === b ? 0 : ((a || "").toUpperCase() > (b || "").toUpperCase() ? 1 : -1)
    );

    static byIncludingInRate = (a: IHotelFeature, b: IHotelFeature, rateId: string) => {
        if (a.rateIds.includes(rateId)) return 1;
        if (b.rateIds.includes(rateId)) return -1;

        return Compare.strings(a.name, b.name);
    };

    static occupancies = <T extends IOccupancy>(a: T, b: T) => {
        if (a.mainOccupancy === b.mainOccupancy) {
            return a.additionalOccupancy > b.additionalOccupancy ? 1 : -1;
        }

        return a.mainOccupancy === 0 || b.mainOccupancy === 0
            ? a.mainOccupancy > b.mainOccupancy ? -1 : 1
            : a.mainOccupancy > b.mainOccupancy ? 1 : -1;
    };
}
