import {IRoomCategoryAccommodation} from "../../../data/Accommodation";
import {IAccommodationsPricesMap, IAccommodationsRatesMap, IOccupancyWithValue} from "../AccommodationsMaps";
import {ICurrency} from "../../../types/Currency";
import {IBookingAccommodation, IBookingPaymentInfo} from "../../../data/Booking";
import {OccupancyHelper} from "../../../helpers/OccupancyHelper";
import {Currency} from "@skbkontur/hotel-data/currency";

// TODO Написать тесты

export class AccommodationsPricesHelper {
    static createPricesMap = (roomCategoryAccommodations: IRoomCategoryAccommodation[]): IAccommodationsPricesMap => (
        roomCategoryAccommodations?.reduce((acc, item) => {
            const {roomCategoryId, accommodations} = item;
            return {
                ...acc,
                [roomCategoryId]: accommodations.reduce((acc, item) => {
                    const {mainOccupancy, additionalOccupancy, accommodationPrices} = item;
                    return [
                        ...acc,
                        {
                            mainOccupancy,
                            additionalOccupancy,
                            value: accommodationPrices.reduce((acc, item) => {
                                const {rateId} = item;
                                return {
                                    ...acc,
                                    [rateId]: item
                                };
                            }, {} as IAccommodationsRatesMap)
                        }
                    ];
                }, [] as IOccupancyWithValue<IAccommodationsRatesMap>[])
            };
        }, {} as IAccommodationsPricesMap) || {}
    );

    static getMinRatesPrice = (ratesMap: IAccommodationsRatesMap): ICurrency => {
        const ratePrices = Object.values(ratesMap).map(({price}) => price);
        return ratePrices.length
            ? Currency.getMin(ratePrices)
            : Currency.zero();
    };

    static getBookingPrice = (booking: IBookingAccommodation, pricesMap: IAccommodationsPricesMap): IBookingPaymentInfo => {
        const {rateId, roomCategoryId, mainAccommodation, additionalAccommodation} = booking;
        const ratesPrices = OccupancyHelper.findItemValue(pricesMap[roomCategoryId], mainAccommodation, additionalAccommodation) || OccupancyHelper.findItemValue(pricesMap[roomCategoryId], 0, 0);
        const {price, prepaymentSum} = ratesPrices[rateId];

        return {
            price: Currency.getFloatValue(price),
            prepayment: Currency.getFloatValue(prepaymentSum)
        };
    };
}
