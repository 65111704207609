import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import {IconTypes, BabyIcon, PeopleIcon} from "@skbkontur/hotel-components/icon";

interface IBookingRateItemGuestsProps {
    adultsCount: number;
    childrenCount: number;
}

const BookingRateItemGuests = (props: IBookingRateItemGuestsProps) => {
    const {adultsCount, childrenCount} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    const guestsIcons = (
        <>
            {adultsCount} <PeopleIcon type={IconTypes.Light16} />
            {!!childrenCount && (
                <> + {childrenCount} <BabyIcon type={IconTypes.Light16} /></>
            )}
        </>
    );

    return (
        <div>
            {t("rates.guests", {reactParams: {0: guestsIcons}})}
        </div>
    );
};
BookingRateItemGuests.displayName = "BookingRateItemGuests";
export default BookingRateItemGuests;
