import {useRoomCategory} from "../../../hooks/useRoomCategory";
import {Compare} from "../../../helpers/CompareHelper";
import SliderWithGallery from "../../SliderWithGallery/SliderWithGallery";
import RoomCategoryEmpty from "../../../images/empty/RoomCategoryEmpty.png";
import WidgetStore from "../../../widget/WidgetStore";
import {RoomCategoryLabel, RoomCategoryLabelAmenities, RoomCategoryLabelRooms} from "@skbkontur/hotel-components/roomCategoryLabel";
import EntityCardContainer from "../EntityCardContainer";
import EntityCardTitle from "../EntityCardTitle";
import EntityCardSlider from "../EntityCardSlider";
import EntityCardDescription from "../EntityCardDescription";
import EntityCardContent from "../EntityCardContent";
import EntityCardRight from "../EntityCardRight";

interface IRoomCategoryCardProps {
    id: string;
    title?: string;
    autoPlaySlider?: boolean;
}

const RoomCategoryCard = (props: React.PropsWithChildren<IRoomCategoryCardProps>) => {
    const {id, title, children, autoPlaySlider} = props;

    const roomCategory = useRoomCategory(id);
    const {name, description, imageMetas} = roomCategory;

    return (
        <EntityCardContainer>
            <EntityCardSlider>
                <SliderWithGallery
                    autoPlay={autoPlaySlider}
                    imageMetas={[...imageMetas].sort(Compare.byOrder)}
                    emptyImage={WidgetStore.getUrlWithBase(RoomCategoryEmpty)}
                />
            </EntityCardSlider>
            <EntityCardContent>
                <EntityCardTitle title={title || name}/>
                <RoomCategoryLabelRooms category={roomCategory}/>
                <RoomCategoryLabel category={roomCategory} modifyImageUrl={WidgetStore.getUrlWithBase}/>
                {description && (
                    <EntityCardDescription>
                        {description}
                    </EntityCardDescription>
                )}
                <RoomCategoryLabelAmenities category={roomCategory} modifyImageUrl={WidgetStore.getUrlWithBase}/>
            </EntityCardContent>
            <EntityCardRight>
                {children}
            </EntityCardRight>
        </EntityCardContainer>
    );
};
RoomCategoryCard.displayName = "RoomCategoryCard";
export default React.memo(RoomCategoryCard);
