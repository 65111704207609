import {useTranslation} from "@skbkontur/i18n";
import {Tooltip} from "@skbkontur/react-ui";
import dayjs from "dayjs";
import {HelpDotIcon, IconTypes} from "@skbkontur/hotel-components/icon";
import {DateFormat} from "@skbkontur/hotel-date";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import {useHourlyRate} from "../../../../../hooks/useHourlyRate";
import {HourlyObjectsSearchParamsContext} from "../../../../SearchParams/SearchParamsContext";
import RateConditions from "../../../../../components/RateConditions/RateConditions";
import styles from "./HourlyBookingRateItem.scss";
import {ICurrency} from "@skbkontur/hotel-data/currency";

interface IHourlyBookingRateItemConditionsProps {
    hourlyRateId: string;
    cancellationPenaltySum: ICurrency;
    prepaymentSum: ICurrency;
}

const HourlyBookingRateItemConditions = (props: IHourlyBookingRateItemConditionsProps) => {
    const {hourlyRateId, cancellationPenaltySum, prepaymentSum} = props;
    const {params: {timeFrom}} = React.useContext(HourlyObjectsSearchParamsContext);

    const {t} = useTranslation(TranslationNamespaces.BookingConfirmation);
    const rate = useHourlyRate(hourlyRateId);

    const checkinFormatted = dayjs(timeFrom, DateFormat.UnixMsTimestamp)
        .format(DateFormat.FullDateDayFirstWithTimeWithColons);

    return (
        <div>
            <Tooltip
                render={() => (
                    <RateConditions
                        cancellationPenaltySum={cancellationPenaltySum}
                        prepaymentSum={prepaymentSum}
                        rate={rate}
                        checkin={checkinFormatted}
                    />
                )}
            >
                <span className={styles.tooltip}>
                    <HelpDotIcon type={IconTypes.Light16}/> {t("termsOfPaymentAndReturn")}
                </span>
            </Tooltip>
        </div>
    );
};
HourlyBookingRateItemConditions.displayName = "HourlyBookingRateItemConditions";
export default HourlyBookingRateItemConditions;
