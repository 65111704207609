import {KnownActualWidgetSettings} from "../../settings/WidgetInstanceSettings";
import WidgetStore from "../../WidgetStore";
import WidgetThemeProvider from "./WidgetThemeProvider";

interface IWidgetTypeThemeProps {
    type: KnownActualWidgetSettings["type"];
}

const WidgetTypeTheme = (props: React.PropsWithChildren<IWidgetTypeThemeProps>) => {
    const {type, children} = props;

    const theme = WidgetStore.settings?.theme?.[type];

    return (
        theme ? (
            <WidgetThemeProvider type={type} widgetTheme={theme}>
                {children}
            </WidgetThemeProvider>
        ) : (
            children
        )
    );
};
WidgetTypeTheme.displayName = "WidgetTypeTheme";
export default WidgetTypeTheme;