import {useSelector} from "react-redux";
import {IRoomCategory} from "@skbkontur/hotel-data/roomCategory";
import {getItemById} from "@skbkontur/hotel-data/compositions";
import RoomAvailabilityCalendar from "../RoomAvailabilityCalendar/RoomAvailabilityCalendar";
import {RoomCategorySearchParamsContext} from "../../providers/SearchParams/SearchParamsContext";
import {IAppState} from "../../store/AppState";
import {CalendarContext} from "../Calendar/Provider/CalendarContext";
import {WithProvider} from "../../common/components/WithProvider/WithProvider";
import CalendarProvider from "../Calendar/Provider/CalendarProvider";
import RoomAvailabilityCalendarContainer, {
    IRoomAvailabilityCalendarBaseProps
} from "../RoomAvailabilityCalendar/RoomAvailabilityCalendarContainer";
import RoomAvailabilityCalendarParameters from "./RoomAvailabilityCalendarParameters";
import RoomAvailabilityCalendarSelect from "./RoomAvailabilityCalendarSelect";
import styles from "./RoomAvailabilityCalendarWithFilter.scss";

const getInitialRoomCategory = (initialRoomCategoryId: string, roomCategories: IRoomCategory[]): IRoomCategory => (
    initialRoomCategoryId
        ? roomCategories.find(item => item.id === initialRoomCategoryId)
        : roomCategories[0]
);

const RoomAvailabilityCalendarWithFilter = (props: IRoomAvailabilityCalendarBaseProps) => {
    const {inlayMode} = props;

    const {calendarMode} = React.useContext(CalendarContext);
    const {params, setParams} = React.useContext(RoomCategorySearchParamsContext);
    const {roomCategoryId, calendarRoomCategoryId} = params;

    const roomCategories = useSelector((state: IAppState) => state.roomCategories.roomCategories);
    const isRoomCategoriesLoaded = !!roomCategories.length;
    const roomCategory = getItemById(roomCategories, calendarRoomCategoryId);

    React.useEffect(() => {
        if (isRoomCategoriesLoaded) {
            setParams(params => ({
                ...params,
                calendarRoomCategoryId: getInitialRoomCategory(roomCategoryId, roomCategories)?.id
            }));
        }
    }, [isRoomCategoriesLoaded]);

    const roomCategoryChange = (newRoomCategoryId: string) => {
        setParams(params => ({
            ...params,
            calendarRoomCategoryId: newRoomCategoryId
        }));
    };

    return (
        <RoomAvailabilityCalendarContainer calendarMode={calendarMode} inlayMode={inlayMode}>
            <div className={styles.filterContainer}>
                <RoomAvailabilityCalendarSelect
                    categories={roomCategories}
                    roomCategoryId={calendarRoomCategoryId}
                    onValueChange={roomCategoryChange}
                />
                {roomCategory && <RoomAvailabilityCalendarParameters roomCategory={roomCategory}/>}
            </div>
            <RoomAvailabilityCalendar
                searchParams={params}
                roomCategoryId={calendarRoomCategoryId}
                calendarMode={calendarMode}
                inlayMode
            />
        </RoomAvailabilityCalendarContainer>
    );
};
RoomAvailabilityCalendarWithFilter.displayName = "RoomAvailabilityCalendarWithFilter";
export default WithProvider(RoomAvailabilityCalendarWithFilter, CalendarProvider);
