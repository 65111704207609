import {IconTypes, IIconProps} from "./Icon";
import {ChildBabyIcon16Light} from "@skbkontur/icons/ChildBabyIcon16Light";

type BabyIconTypes = IconTypes.Light16 | IconTypes.Regular16;

interface IBabyIconProps extends IIconProps {
    type: BabyIconTypes;
}

const BabyIcon = ({type, ...iconProps}: IBabyIconProps) => {
    switch (type) {
        case IconTypes.Light16:
            return <ChildBabyIcon16Light {...iconProps} />;
    }
};
BabyIcon.displayName = "BabyIcon";
export default BabyIcon;
