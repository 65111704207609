import styles from "./PlacementParameters.scss";
import {IconTypes, PeopleIcon} from "@skbkontur/hotel-components/icon";

interface IPlacementParametersProps {
    capacity?: number;
}

const PlacementParameters = (props: IPlacementParametersProps) => {
    const {capacity} = props;

    return (
        <div className={styles.iconRow}>
            <span>{capacity}</span>
            <div className={styles.icon}>
                <PeopleIcon type={IconTypes.Light16} />
            </div>
        </div>
    );
};
PlacementParameters.displayName = "PlacementParameters";
export default PlacementParameters;
