import cn from "classnames";
import {ICalendarDayData} from "../../../data/Calendar/Calendar";
import {ICalendarDayBorderRadiusConfig} from "../../../data/Calendar/CalendarHelper";
import styles from "./CalendarDay.scss";

interface ICalendarDayProps {
    day?: number;
    data?: ICalendarDayData;
    dayOfWeek?: React.ReactNode;
    disabled: boolean;
    small: boolean;
    isMin?: boolean;
    isMax?: boolean;
    borderRadiusConfig: ICalendarDayBorderRadiusConfig;
}

const CalendarDay = (props: ICalendarDayProps) => {
    const {day, data, dayOfWeek, disabled, small, isMin, isMax, borderRadiusConfig} = props;
    const {price} = data || {};
    const {tl, tr, bl, br, tlOuter, trOuter, blOuter, brOuter} = borderRadiusConfig || {};

    const priceToRender = !disabled && price;
    const isActive = !!priceToRender;

    return (
        <div
            className={cn(styles.day, {
                [styles.active]: isActive,
                [styles.disabled]: disabled,
                [styles.small]: small,
                [styles.min]: isActive && isMin,
                [styles.max]: isActive && isMax,
                [styles.tl]: tl,
                [styles.tr]: tr,
                [styles.bl]: bl,
                [styles.br]: br
            })}
        >
            <div className={styles.number}>
                {day}
            </div>
            {day && (
                <div className={styles.price}>
                    {priceToRender || dayOfWeek}
                </div>
            )}
            {tlOuter && <div className={styles.tlOuter}/>}
            {trOuter && <div className={styles.trOuter}/>}
            {blOuter && <div className={styles.blOuter}/>}
            {brOuter && <div className={styles.brOuter}/>}
        </div>
    );
};
CalendarDay.displayName = "CalendarDay";
export default CalendarDay;
