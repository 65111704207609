export class StringUtils {
    static capitalizeFirstLetter = (s: string) => (
        s ? `${s[0].toLocaleUpperCase()}${s.slice(1)}` : s
    );

    static normalize = (s: string, normalizeCase: boolean = false): string => {
        const withoutWrongSpaces = (s || "").replace(/ +/g, " ").trim();
        return normalizeCase ? withoutWrongSpaces.toLocaleLowerCase() : withoutWrongSpaces;
    };

    static ellipsis = (s: string, len: number = 160): string => {
        const normalized = StringUtils.normalize(s || "");
        if (normalized.length < len) return normalized;
        const truncated = normalized.substring(0, len).trim();
        if (/\.$|!$|\?$/g.test(truncated)) return truncated;
        const withoutPunctuationAtEnd = truncated.replace(/[,:;@#$%^&*()_\-=+/]+$/g, "");
        return `${withoutPunctuationAtEnd}...`;
    };

    static escapeForRegExp = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    static filterByDigit = (str: string): string => str?.replace(/\D+/g, "") || "";

    static compareWithoutCase = (str1: string, str2: string): boolean => str1?.toLowerCase() === str2?.toLowerCase();

    static isNullOrEmpty = (str: string) => str === null || str === undefined || str === "" || str === "undefined" || str === "null";

    static insertLeadingZero = (num: number | string, len: number): string => {
        const n = num.toString();
        const arr: string[] = [];
        for (let i = 0; i < (len - n.length); ++i)
            arr[arr.length] = "0";
        arr[arr.length] = n;
        return arr.join("");
    };

    static countLeadingZeros = (str: string): [number, string] => {
        let i = 0;
        for (i; i < str.length; i++) {
            if (str[i] !== "0") {
                break;
            }
        }
        return [i, str.substring(i)];
    };

    static toLowerCase = (str: string): string => str.toLowerCase();
}
