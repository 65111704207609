import {BookingLightboxStep, BookingLightboxStepsContext, IBookingLightboxStepsParams} from "./BookingLightboxStepsContext";
import {clearAccommodations} from "../../../../store/accommodations/accommodationsActionCreators";
import {AccommodationsContext} from "../../../Accommodations/AccommodationsContext";
import {useUnmount} from "@skbkontur/hotel-hooks/react";
import {useMemoObject} from "@skbkontur/hotel-hooks/memo";

interface IBookingLightboxStepsState {
    step: BookingLightboxStep;
    params: IBookingLightboxStepsParams;
}

const initialState: IBookingLightboxStepsState = {
    step: BookingLightboxStep.Initial,
    params: null
};

const emptyParams = {} as IBookingLightboxStepsParams;

const BookingLightboxStepsProvider = (props: React.PropsWithChildren<object>) => {
    const {children} = props;

    const {allAccommodations, selectedAccommodationsMap} = React.useContext(AccommodationsContext);

    const [state, setState] = React.useState<IBookingLightboxStepsState>(initialState);
    const {step, params} = state;

    const goToStep = React.useCallback((step: BookingLightboxStep, params?: IBookingLightboxStepsParams) => {
        setState({step, params});
    }, [selectedAccommodationsMap]);

    useUnmount(() => {
        clearAccommodations();
    });

    React.useEffect(() => {
        switch (true) {
            case allAccommodations === null || allAccommodations === undefined:
                goToStep(BookingLightboxStep.Initial);
                break;
            case !allAccommodations?.length:
                goToStep(BookingLightboxStep.NotAvailable);
                break;
            default:
                goToStep(BookingLightboxStep.SelectAccommodations);
        }
    }, [allAccommodations]);

    return (
        <BookingLightboxStepsContext.Provider value={useMemoObject({goToStep, step, params: params || emptyParams})}>
            {children}
        </BookingLightboxStepsContext.Provider>
    );
};
BookingLightboxStepsProvider.displayName = "BookingLightboxStepsProvider";
export default BookingLightboxStepsProvider;
