import {WidgetValidationResult} from "../validation/WidgetValidationResult";
import {WidgetValidation} from "../validation/WidgetValidation";
import {IWidgetSettingsAppearance} from "./WidgetSettings";
import {WidgetType} from "./WidgetType";

export class WidgetSettingsValidator {
    private validationResults: WidgetValidationResult[] = [];

    isValidType(initialType: WidgetType) {
        const result = WidgetValidation.validateWidgetType(initialType);
        this.validationResults.push(result);
        return this.isValidationOk([result]);
    }

    isValidAppearance(
        appearance: IWidgetSettingsAppearance,
        type: WidgetType
    ) {
        const settingsContainerResult = WidgetValidation.validateSettingsContainer(appearance, type);

        const insertResult = appearance.insert
            ? WidgetValidation.validateInsertType(
                appearance.insert,
                type
            )
            : WidgetValidationResult.Ok;

        const containerDomResult = WidgetValidation.validateContainerDomElement(appearance, type);
        const results = [settingsContainerResult, insertResult, containerDomResult];
        this.validationResults = this.validationResults.concat(results);

        return this.isValidationOk(results);
    }

    resetResults = () => this.validationResults = [] as WidgetValidationResult[];

    isValidationOk = (results: WidgetValidationResult[] = this.validationResults) => (
        results.every(result => result === WidgetValidationResult.Ok)
    );
}
