import {ISelectedAccommodationBooking} from "../../../Accommodations/AccommodationsMaps";
import {BookingLightboxStep} from "../Steps/BookingLightboxStepsContext";
import {isEqual} from "lodash";
import {createMapBy} from "@skbkontur/hotel-utils";

export interface IBookingLightboxFlipperState {
    changedAccommodation: ISelectedAccommodationBooking;
    step: BookingLightboxStep;
}

export type ShouldFlipFunc = (prevValue: IBookingLightboxFlipperState, value: IBookingLightboxFlipperState) => boolean;

export class BookingLightboxFlipperHelper {
    static getChangedAccommodation = (
        prevData: ISelectedAccommodationBooking[],
        currentData: ISelectedAccommodationBooking[]
    ): ISelectedAccommodationBooking => {
        prevData = prevData || [];
        currentData = currentData || [];

        const prevRenderKeys = prevData.map(b => b.accommodation.renderKey);
        const currentRenderKeys = currentData.map(b => b.accommodation.renderKey);

        const prevDataMap = createMapBy(prevData, b => b.accommodation.renderKey);
        const currentDataMap = createMapBy(currentData, b => b.accommodation.renderKey);

        if (prevRenderKeys.length < currentRenderKeys.length) {
            const changedRenderKey = currentRenderKeys.find(key => !prevRenderKeys.includes(key));
            return currentDataMap[changedRenderKey];
        }
        if (prevRenderKeys.length > currentRenderKeys.length) {
            const changedRenderKey = prevRenderKeys.find(key => !currentRenderKeys.includes(key));
            return prevDataMap[changedRenderKey];
        }
        return currentData.find(({accommodation}) => (
            !isEqual(accommodation, prevDataMap[accommodation.renderKey].accommodation)
        ));
    };

    static shouldFlipAccommodationItem = (renderKey: string): ShouldFlipFunc => (
        (prev: IBookingLightboxFlipperState, {changedAccommodation}: IBookingLightboxFlipperState) => (
            changedAccommodation.accommodation.renderKey === renderKey
        )
    );

    static shouldFlipAccommodationsList = (roomCategoryId: string, mainOccupancy: number, additionalOccupancy: number): ShouldFlipFunc => (
        (prev: IBookingLightboxFlipperState, {changedAccommodation}: IBookingLightboxFlipperState) => {
            const {params} = changedAccommodation;
            return (
                params.roomCategoryId === roomCategoryId && params.mainOccupancy === mainOccupancy && params.additionalOccupancy === additionalOccupancy
            );
        }
    );

    static getShouldFlipStepsChange = (isAnyHotelFeaturesSelling: boolean): ShouldFlipFunc => (
        ({step: prevStep}: IBookingLightboxFlipperState, {step}: IBookingLightboxFlipperState) => (
            step === BookingLightboxStep.FinishOrder
                ? prevStep !== step && !isAnyHotelFeaturesSelling
                : prevStep !== step
        )
    );
}
