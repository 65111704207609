import {IIconProps, IconTypes} from "./Icon";
import {CheckAIcon16Solid} from "@skbkontur/icons/CheckAIcon16Solid";

type OkIconTypes = IconTypes.Solid16;

interface IOkIconProps extends IIconProps {
    type: OkIconTypes;
}

const OkIcon = ({type, ...iconProps}: IOkIconProps) => {
    switch (type) {
        case IconTypes.Solid16:
            return <CheckAIcon16Solid {...iconProps} />;
    }
};
OkIcon.displayName = "OkIcon";
export default OkIcon;
