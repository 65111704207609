import {ThemeContext, ThemeFactory} from "@skbkontur/react-ui";
import {useCSSTheme} from "@skbkontur/hotel-hooks/theme";
import {ThemeHelper} from "../../theme/ThemeHelper";
import WidgetStore from "../../WidgetStore";
import {BM_DEFAULT_REACT_UI_THEME, BM_THEME_EXCLUDE_KEYS} from "../../theme/ThemeConstants";
import {useColorsCSSTheme} from "../../theme/useColorsCSSTheme";
import {KnownActualWidgetSettings} from "../../settings/WidgetInstanceSettings";
import {createWidgetThemeContext} from "./WidgetThemeContext";

interface IWidgetThemeProviderProps {
    type: KnownActualWidgetSettings["type"];
    widgetTheme: KnownActualWidgetSettings["theme"];
}

/** IMPORTANT Children DOM-node should be located inside provider DOM-node to make CSS theme properties work */
const WidgetThemeProvider = (props: React.PropsWithChildren<IWidgetThemeProviderProps>) => {
    const {type, widgetTheme, children} = props;

    const {common: widgetCommon} = widgetTheme || {};
    const {colors: widgetColors} = widgetCommon || {};

    const {theme} = WidgetStore.settings;
    const {common} = theme || {};
    const {colors} = common || {};

    const themeContainerRef = React.useRef<HTMLDivElement>();

    const finalWidgetTheme = React.useMemo(() => (
        ThemeHelper.getWidgetFinalTheme(theme, widgetTheme, type)
    ), [theme, widgetTheme, type]);

    useCSSTheme({
        theme: finalWidgetTheme,
        ref: themeContainerRef,
        cssPrefix: ThemeHelper.getWidgetCSSPrefix,
        excludeKeys: BM_THEME_EXCLUDE_KEYS
    });

    const widgetReactUITheme = React.useMemo(() => {
        const widgetReactUITheme = ThemeHelper.getReactUITheme(theme, widgetTheme, type);
        return ThemeFactory.create(widgetReactUITheme, BM_DEFAULT_REACT_UI_THEME);
    }, [theme, widgetTheme, type]);

    const finalColorsTheme = React.useMemo(() => ({
        ...colors,
        ...widgetColors,
        /** Fallback React UI colors for custom buttons */
        btnPayBg: widgetReactUITheme.btnPayBg,
        btnPayTextColor: widgetReactUITheme.btnPayTextColor,
        /* Hover */
        btnPayHoverBg: widgetReactUITheme.btnPayHoverBg,
        btnPayHoverTextColor: widgetReactUITheme.btnPayHoverTextColor,
        /* Disabled */
        btnDisabledBg: widgetReactUITheme.btnDisabledBg,
        btnDisabledTextColor: widgetReactUITheme.btnDisabledTextColor
    }), [colors, widgetColors, widgetReactUITheme]);

    useColorsCSSTheme({ref: themeContainerRef, theme: finalColorsTheme});

    const WidgetThemeContext = createWidgetThemeContext();

    return (
        <div ref={themeContainerRef}>
            <WidgetThemeContext.Provider value={finalWidgetTheme}>
                <ThemeContext.Provider value={widgetReactUITheme}>
                    {children}
                </ThemeContext.Provider>
            </WidgetThemeContext.Provider>
        </div>
    );
};
WidgetThemeProvider.displayName = "WidgetThemeProvider";
export default WidgetThemeProvider;
