import {useCSSTheme} from "@skbkontur/hotel-hooks/theme";
import {IThemeColors} from "./ThemeCommon";
import {BM_THEME_CSS_PREFIX} from "./ThemeConstants";

interface IUseColorsCSSThemeProps {
    theme: IThemeColors;
    ref: React.RefObject<HTMLElement>;
}

export const useColorsCSSTheme = (props: IUseColorsCSSThemeProps) => {
    const {theme, ref} = props;

    useCSSTheme({
        theme,
        ref,
        cssPrefix: BM_THEME_CSS_PREFIX
    });
};