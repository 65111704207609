import {HourlyObjectsSearchParamsContext} from "../../../SearchParams/SearchParamsContext";
import {IAppState} from "../../../../store/AppState";
import {useDispatch, useSelector} from "react-redux";
import {HourlyAccommodationsContext} from "../../../HourlyAccommodations/HourlyAccommodationsContext";
import hourlyApi from "../../../../api/hourlyApi";
import {getHourlyAccommodations} from "../../../../store/hourlyAccommodations/hourlyAccommodationsActionCreators";
import {isEqual} from "lodash";
import {HourlyBookingLightboxHelper} from "../HourlyBookingLightboxHelper";
import BookingOverbookingLightbox from "../../../BookingOverbooking/BookingOverbookingLightbox";
import {getAvailabilitiesHourly} from "../../../../store/availabilitiesHourly/availabilitiesHourlyActionCreators";
import {HourlyObjectsAvailableHelper} from "../../../../components/HourlyObjectsList/HourlyObjectsAvailableHelper";
import {HourlyBookingOverbookingContext} from "../../../BookingOverbooking/BookingOverbookingContext";
import {useWatchOverbooking} from "../../../BookingOverbooking/useWatchOverbooking";
import {useMemoObject} from "@skbkontur/hotel-hooks/memo";

interface IHourlyBookingOverbookingProviderState {
    isLightboxOpened: boolean;
    isOverbookingLoading: boolean;
}

const initialState: IHourlyBookingOverbookingProviderState = {
    isOverbookingLoading: false,
    isLightboxOpened: false
};

const HourlyBookingOverbookingProvider = (props: React.PropsWithChildren<object>) => {
    const {children} = props;

    const isLoadingRef = React.useRef<boolean>();

    isLoadingRef.current = useSelector((state: IAppState) => (
        state.hourlyObjectsAccommodations.isLoading || state.hourlyBooking.isLoading || state.payment.isLoading
    ));
    const offsetInMinutes = useSelector((state: IAppState) => state.hotelInfo.info.timeZone.offsetInMinutes);

    const {selectedAccommodation, accommodationPrices} = React.useContext(HourlyAccommodationsContext);
    const {params: searchParams} = React.useContext(HourlyObjectsSearchParamsContext);
    const {timeFrom, timeTo} = searchParams;

    const [state, setState] = React.useState<IHourlyBookingOverbookingProviderState>(initialState);
    const {isOverbookingLoading, isLightboxOpened} = state;

    const isOverbooking = React.useCallback(async (): Promise<boolean> => {
        if (isLoadingRef.current) return false;


        if (!timeFrom || !timeTo) {
            return true;
        }

        setState(state => ({...state, isOverbookingLoading: true}));

        const params = HourlyBookingLightboxHelper.getAccommodationsParams(timeFrom, timeTo, offsetInMinutes);

        const newAccommodations = await hourlyApi.getAccommodations(params);

        const newAccommodationPrices =
            HourlyBookingLightboxHelper.getAccommodation(newAccommodations, selectedAccommodation.hourlyObjectId).accommodations || [];

        const isOverbooking = !isEqual(accommodationPrices, newAccommodationPrices);

        setState({
            isOverbookingLoading: false,
            isLightboxOpened: isOverbooking
        });

        return isOverbooking;
    }, [selectedAccommodation, accommodationPrices, timeTo, timeFrom]);

    const stopWatchOverbooking = useWatchOverbooking({
        isOverbooking,
        accommodation: accommodationPrices,
        selectedAccommodation: selectedAccommodation
    });

    const dispatch = useDispatch();
    const closeLightbox = () => setState(state => ({...state, isLightboxOpened: false}));

    const updateResults = async () => {
        const hourlyParams = HourlyObjectsAvailableHelper.getAvailabilitiesHourlyParams(searchParams);
        const accommodationsParams = HourlyBookingLightboxHelper.getAccommodationsParams(timeFrom, timeTo, offsetInMinutes);

        /* eslint-disable @typescript-eslint/await-thenable */
        await dispatch(getAvailabilitiesHourly(hourlyParams));
        await dispatch(getHourlyAccommodations(accommodationsParams));

        closeLightbox();
    };

    return (
        <HourlyBookingOverbookingContext.Provider value={useMemoObject({isOverbooking, isOverbookingLoading, stopWatchOverbooking})}>
            {children}
            {isLightboxOpened && (
                <BookingOverbookingLightbox
                    onClose={closeLightbox}
                    onUpdate={updateResults}
                />
            )}
        </HourlyBookingOverbookingContext.Provider>
    );
};
HourlyBookingOverbookingProvider.displayName = "HourlyBookingOverbookingProvider";
export default HourlyBookingOverbookingProvider;
