import {IThemeLocaleText} from "./ThemeWidgets";
import {KonturI18NContext} from "@skbkontur/i18n";

export const useThemeText = (theme: IThemeLocaleText): string => {
    const {locale} = React.useContext(KonturI18NContext);

    if (typeof theme === "string") {
        // eslint-disable-next-line no-console
        console.error(`Theme error: Provide text like object which keys is locale-type instead of string "${String(theme)}". Example: "{ru: "Забронировать", en: "Book"}".`);
        return null;
    }

    return theme?.[locale];
};