import {BedType, IBedStructure, IRoomCategory, KnownBedTypes} from "@skbkontur/hotel-data/roomCategory";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "@skbkontur/hotel-constants/TranslationNamespaces";
import {AdditionalBedType} from "@skbkontur/hotel-data/roomCategory/RoomCategory";
import {SvgIcon} from "@skbkontur/hotel-components/svgIcon";
import styles from "../RoomCategoryLabel.scss";
import {ADDITIONAL_BED_ICONS, BED_ICONS} from "./RoomCategoryLabelIcons";

interface IRoomCategoryLabelHintProps {
    category: IRoomCategory;
    beds: IBedStructure<BedType>[];
    additionalBeds: IBedStructure<AdditionalBedType>[];
    modifyImageUrl?: (url: string) => string;
}

const createBedCountsMap = <T extends KnownBedTypes>(beds: IBedStructure<KnownBedTypes>[]): Record<T, number> => (
    beds?.reduce((bedsMap, {type}) => ({
        ...bedsMap,
        [type]: bedsMap[type] ? bedsMap[type] + 1 : 1
    }), {} as Record<T, number>)
);

const RoomCategoryLabelHint = (props: IRoomCategoryLabelHintProps) => {
    const {category: {placesMin, additionalPlaces}, beds, additionalBeds, modifyImageUrl} = props;

    const {t} = useTranslation(TranslationNamespaces.RoomCategories);

    const bedCountsMap = createBedCountsMap<BedType>(beds);
    const additionalBedCountsMap = createBedCountsMap<AdditionalBedType>(additionalBeds);

    return (
        <div>
            <div className={styles.hintTitle}>
                {t("placesCount", {count: placesMin})}
            </div>
            {Object.keys(bedCountsMap).map(bedType => (
                <div key={bedType} className={styles.hintBedRow}>
                    <SvgIcon key={bedType} type={BED_ICONS[bedType]} modifyImageUrl={modifyImageUrl}/>
                    <span>&nbsp;—&nbsp;</span>
                    {bedCountsMap[bedType] > 1
                        ? t(`bedTypesWithCount.${bedType}`, {count: bedCountsMap[bedType]})
                        : t(`bedTypes.${bedType}`)}
                </div>
            ))}
            {additionalPlaces > 0 && (
                <>
                    <div className={styles.hintSecondTitle}>
                        {t("additionalPlacesCount", {count: additionalPlaces})}
                    </div>
                    {Object.keys(additionalBedCountsMap).map(bedType => (
                        <div key={bedType} className={styles.hintBedRow}>
                            <SvgIcon
                                key={bedType}
                                type={ADDITIONAL_BED_ICONS[bedType]}
                                modifyImageUrl={modifyImageUrl}
                            />
                            <span>&nbsp;—&nbsp;</span>
                            {additionalBedCountsMap[bedType] > 1
                                ? t(`additionalBedTypesWithCount.${bedType}`, {count: additionalBedCountsMap[bedType]})
                                : t(`additionalBedTypes.${bedType}`)}
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};
RoomCategoryLabelHint.displayName = "RoomCategoryLabelHint";
export default RoomCategoryLabelHint;