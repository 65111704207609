import {BaseAnalyticsEvents} from "./BaseAnalyticsEvents";
import {AnalyticsCategory, BookingLightboxSearchPanelAnalyticsAction} from "./Analytics";

class BookingLightboxSearchPanelAnalyticsEvents extends BaseAnalyticsEvents {
    trackDatePeriodChange = (nightsCount: number) => (
        this.trackEvent(
            BookingLightboxSearchPanelAnalyticsAction.ChangeDatePeriod,
            "изменение дат в лайтбоксе бронирования",
            {
                nightsCount
            }
        )
    );

    trackGuestsChange = (adultsCount: number, childrenCount: number) => (
        this.trackEvent(
            BookingLightboxSearchPanelAnalyticsAction.ChangeGuests,
            "изменение гостей в лайтбоксе бронирования",
            {
                adultsCount, childrenCount
            }
        )
    );

    trackRestartSearch = () => (
        this.trackEvent(
            BookingLightboxSearchPanelAnalyticsAction.RestartSearch,
            "повторный запуск поиска номеров внутри лайтбокса бронирования"
        )
    );
}

export const bookingLightboxSearchPanelAnalyticsEvents = new BookingLightboxSearchPanelAnalyticsEvents(
    AnalyticsCategory.BookingLightboxSearchPanel
);
