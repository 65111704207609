import {DateCalculate, DateCompare, DateFormat, UnitOfTime} from "@skbkontur/hotel-date";
import {ICalendarDatesPeriod} from "../Calendar/Calendar";

import {FullDateDayFirstString} from "../Date";

export class RoomAvailabilityCalendarHelper {
    private static getMonthFirstDate = (date: FullDateDayFirstString, offsetInMinutes?: number): FullDateDayFirstString => {
        const currentDate = DateCalculate.getTodayWithTimezone(DateFormat.FullDateDayFirst, offsetInMinutes);
        const monthIsCurrent = DateCompare.isSame({
            firstDate: date,
            secondDate: currentDate,
            format: DateFormat.FullDateDayFirst,
            unitOfTime: UnitOfTime.Month
        });

        return monthIsCurrent ? currentDate : DateCalculate.getFirstMonthDate(date, DateFormat.FullDateDayFirst);
    };

    static getDatesForSearch = (startDate: FullDateDayFirstString, offsetInMinutes?: number): ICalendarDatesPeriod => {
        const calculatedFromDate = this.getMonthFirstDate(startDate, offsetInMinutes);
        const nextMonthDate = DateCalculate.add({
            amount: 1,
            date: calculatedFromDate,
            format: DateFormat.FullDateDayFirst,
            unitOfTime: UnitOfTime.Month
        });
        const calculatedToDate = DateCalculate.getLastMonthDate(nextMonthDate, DateFormat.FullDateDayFirst);

        return {
            fromDate: calculatedFromDate,
            toDate: calculatedToDate
        };
    };
}
