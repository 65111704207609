import {DateCalculate, DateFormat, DateHelper, UnitOfTime} from "@skbkontur/hotel-date";
import {pickBy} from "lodash";
import {FullDateDayFirstString} from "../Date";
import {CalendarMonthDataMapType} from "./Calendar";

const LAST_DAY_OF_WEEK_INDEX = 6;

export interface ICalendarMinMaxMonthPrices {
    minPrice: number;
    maxPrice: number;
}

export interface ICalendarDayBorderRadiusConfig {
    tl: boolean;
    tr: boolean;
    bl: boolean;
    br: boolean;
    tlOuter: boolean;
    trOuter: boolean;
    blOuter: boolean;
    brOuter: boolean;
}

export class CalendarHelper {

    static getDayBorderRadiusConfig = (
        monthData: CalendarMonthDataMapType,
        day: number,
        index: number,
        daysDisabledMap: Record<number, boolean>
    ): ICalendarDayBorderRadiusConfig => {
        if (!this.isActiveDay(monthData, day, daysDisabledMap))
            return null;

        const weekday = (index + 1) % 7 || 7;

        const isTopActive = this.isActiveDay(monthData, day - 7, daysDisabledMap);
        const isBottomActive = this.isActiveDay(monthData, day + 7, daysDisabledMap);
        const isRightActive = weekday !== 7 && this.isActiveDay(monthData, day + 1, daysDisabledMap);
        const isLeftActive = weekday !== 1 && this.isActiveDay(monthData, day - 1, daysDisabledMap);

        const isTopLeftActive = weekday !== 1 && this.isActiveDay(monthData, day - 7 - 1, daysDisabledMap);
        const isTopRightActive = weekday !== 7 && this.isActiveDay(monthData, day - 7 + 1, daysDisabledMap);
        const isBottomLeftActive = weekday !== 1 && this.isActiveDay(monthData, day + 7 - 1, daysDisabledMap);
        const isBottomRightActive = weekday !== 7 && this.isActiveDay(monthData, day + 7 + 1, daysDisabledMap);

        const config: ICalendarDayBorderRadiusConfig = {
            tl: !isTopActive && !isLeftActive,
            tr: !isTopActive && !isRightActive,
            bl: !isBottomActive && !isLeftActive,
            br: !isBottomActive && !isRightActive,
            tlOuter: !isTopLeftActive && isTopActive && isLeftActive,
            trOuter: !isTopRightActive && isTopActive && isRightActive,
            blOuter: !isBottomLeftActive && isBottomActive && isLeftActive,
            brOuter: !isBottomRightActive && isBottomActive && isRightActive
        };

        const filteredConfig = pickBy(config, Boolean);

        return Object.keys(filteredConfig).length
            ? filteredConfig as ICalendarDayBorderRadiusConfig
            : null;
    };

    private static isActiveDay = (
        monthData: CalendarMonthDataMapType,
        day: number,
        daysDisabledMap: Record<number, boolean>
    ) => (
        !daysDisabledMap[day] && !!monthData?.[day]
    );

    static getMinMaxMonthPrices = (monthData: CalendarMonthDataMapType): ICalendarMinMaxMonthPrices => {
        const prices = Object.values(monthData || {}).map(p => p.price);
        const min = Math.min(...prices);
        const max = Math.max(...prices);
        const isSame = min === max;
        return {
            minPrice: isSame ? null : min,
            maxPrice: isSame ? null : max
        };
    };

    static getDaysInMonthRange = (date: FullDateDayFirstString): number[] => {
        const daysInMonth = DateHelper.getDaysCountInMonth(date, DateFormat.FullDateDayFirst);
        return Array.from(Array(daysInMonth), (_, i) => i + 1);
    };

    static getDaysInMonthArrayWithSpaces = (year: number, monthFromZero: number): number[] => {
        const firstDayOfMonth = DateCalculate.getFirstMonthDate(
            DateCalculate.getDate(year, monthFromZero, 1, DateFormat.FullDateDayFirst),
            DateFormat.FullDateDayFirst
        );
        const daysInMonthArray = this.getDaysInMonthRange(firstDayOfMonth);

        const dayOfWeekFromSunday = DateHelper.getWeekDay(firstDayOfMonth, DateFormat.FullDateDayFirst);
        const firstDayOfWeekOfMonthFromMonday = dayOfWeekFromSunday === 0
            ? LAST_DAY_OF_WEEK_INDEX
            : dayOfWeekFromSunday - 1;
        const firstSpaces: number[] = Array(firstDayOfWeekOfMonthFromMonday).fill(null);

        return [...firstSpaces, ...daysInMonthArray];
    };

    static getMonthsForSlider = (
        currentDate: FullDateDayFirstString,
        monthsToDisplay: number
    ): FullDateDayFirstString[] => [
        DateCalculate.add({
            amount: -1,
            date: currentDate,
            format: DateFormat.FullDateDayFirst,
            unitOfTime: UnitOfTime.Month
        }),
        currentDate,
        DateCalculate.add({
            amount: 1,
            date: currentDate,
            format: DateFormat.FullDateDayFirst,
            unitOfTime: UnitOfTime.Month
        }),
        ...monthsToDisplay === 2
            ? [
                DateCalculate.add({
                    amount: 2,
                    date: currentDate,
                    format: DateFormat.FullDateDayFirst,
                    unitOfTime: UnitOfTime.Month
                })
            ]
            : []
    ];
}
