import {NKeysMap} from "@skbkontur/hotel-utils";
import {captureSentryError, SentryErrorType} from "@skbkontur/hotel-sentry";

// TODO rewrite on quick-lru (https://www.npmjs.com/package/quick-lru)

interface IMemoizeOptions<TArgs extends unknown[], TStoredArgs extends unknown[]> {
    /**
     * Разрешает использование и мемоизирует аргументы с типом object
     */
    memoizeObjectArgs?: boolean;
    /**
     * Разрешает использование, но НЕ мемоизирует аргументы с типом object
     */
    allowObjectArgs?: boolean;
    /**
     * Извлекает хранимые аргументы из аргументов фактических
     */
    getStoredArgs?: (...args: TArgs) => TStoredArgs;
}

const MAX_CACHE_VOLUME = 1000;

const memoize = <TResult, TArgs extends unknown[], TStoredArgs extends unknown[]>(
    func: (...args: TArgs) => TResult,
    options?: IMemoizeOptions<TArgs, TStoredArgs>
): (...args: TArgs) => TResult => {
    const {memoizeObjectArgs, allowObjectArgs, getStoredArgs} = options || {} as IMemoizeOptions<TArgs, TStoredArgs>;

    let cache: NKeysMap<TResult>, cacheVolume: number;

    const clearCache = () => {
        cache = new NKeysMap<TResult>();
        cacheVolume = 0;
    };
    clearCache();

    return (...args: TArgs) => {
        const storedArgs = getStoredArgs ? getStoredArgs(...args) : args;
        const cacheResult = cache.get(storedArgs);

        if (cacheResult !== undefined) {
            return cacheResult;
        }

        const result = func(...args);
        const haveObjectArguments = storedArgs.some(arg => arg !== null && typeof arg === "object");

        if (haveObjectArguments) {
            if (!allowObjectArgs && !memoizeObjectArgs) {
                captureSentryError(
                    "Not allowed to use object-type arguments to prevent memory leaks",
                    SentryErrorType.Memoize
                );
            }
            if (!memoizeObjectArgs) {
                return result;
            }
        }

        if (cacheVolume >= MAX_CACHE_VOLUME) {
            clearCache();
        }
        cache.set(storedArgs, result);
        cacheVolume += storedArgs.length;

        return result;
    };
};

export default memoize;
