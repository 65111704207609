import {useTranslation} from "@skbkontur/i18n";
import {Gapped} from "@skbkontur/react-ui";
import cn from "classnames";
import {DateFormat, DateCalculate} from "@skbkontur/hotel-date";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import {SumWithLocale} from "../../../../../components/SumWithLocale/SumWithLocale";
import PrimaryButton from "../../../../../components/PrimaryButton/PrimaryButton";
import {IHourlyAccommodationPrice, IHourlyBookingAccommodation} from "../../../../../data/HourlyBooking";
import {useHourlyRate} from "../../../../../hooks/useHourlyRate";
import {HourlyObjectsSearchParamsContext} from "../../../../SearchParams/SearchParamsContext";
import HourlyBookingRateItemIncludes from "./HourlyBookingRateItemIncludes";
import HourlyBookingRateItemConditions from "./HourlyBookingRateItemConditions";
import HourlyBookingRateItemName from "./HourlyBookingRateItemName";
import styles from "./HourlyBookingRateItem.scss";

interface IHourlyBookingRateItemProps {
    accommodation: IHourlyBookingAccommodation;
    price: IHourlyAccommodationPrice;
    single: boolean;
    onSelectRate: (hourlyRateId: string) => void;
}

const HourlyBookingRateItem = (props: IHourlyBookingRateItemProps) => {
    const {accommodation, price: priceRate, single, onSelectRate} = props;
    const {renderKey, hourlyRateId: accommodationRateId} = accommodation;
    const {rateId, price, cancellationPenaltySum, prepaymentSum} = priceRate;

    const {params: {timeFrom, timeTo}} = React.useContext(HourlyObjectsSearchParamsContext);
    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);
    const {name} = useHourlyRate(rateId);

    const selected = accommodationRateId === rateId;

    const selectRate = () => onSelectRate(rateId);
    const changeVariant = () => {
        onSelectRate(null);
    };

    const hours = DateCalculate.getDiffByHours(timeFrom, timeTo, DateFormat.UnixMsTimestamp);

    return (
        <div className={cn(styles.item, {[styles.selected]: selected})}>
            <div className={styles.conditions}>
                <Gapped gap={10} vertical>
                    <div className={styles.caption}>
                        <HourlyBookingRateItemName renderKey={renderKey} name={name}/>
                    </div>
                    <HourlyBookingRateItemIncludes hourlyRateId={rateId}/>
                    <HourlyBookingRateItemConditions
                        hourlyRateId={rateId}
                        prepaymentSum={prepaymentSum}
                        cancellationPenaltySum={cancellationPenaltySum}
                    />
                </Gapped>
            </div>
            <div className={styles.summary}>
                <div className={styles.line}/>
                <div className={styles.cost}>
                    <div className={styles.sum}>
                        <SumWithLocale sum={price}/>
                        <div className={styles.hours}>
                            {t("hourlyRates.hours", {hours})}
                        </div>
                    </div>
                    {!single && (
                        <div>
                            {selected ? (
                                <PrimaryButton
                                    use="default"
                                    onClick={changeVariant}
                                >
                                    {t("hourlyRates.changeRate")}
                                </PrimaryButton>
                            ) : (
                                <PrimaryButton onClick={selectRate}>
                                    {t("hourlyRates.selectRate")}
                                </PrimaryButton>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
HourlyBookingRateItem.displayName = "HourlyBookingRateItem";
export default HourlyBookingRateItem;
