import {useSelector} from "react-redux";
import {ValidationContainer} from "@skbkontur/react-ui-validations";
import styles from "./BookingLightboxOrder.scss";
import {useTranslation} from "@skbkontur/i18n";
import {BookingPaymentSum} from "../../../../components/BookingPaymentSum/BookingPaymentSum";
import {PaymentSystem} from "../../../../data/Payment";
import {IAppState} from "../../../../store/AppState";
import OrderFormEmail from "../../../BookingOrder/Fields/OrderFormEmail";
import OrderFormPhone from "../../../BookingOrder/Fields/OrderFormPhone";
import OrderFormFio from "../../../BookingOrder/Fields/OrderFormFio";
import OrderFormIsAgreed from "../../../BookingOrder/Fields/OrderFormIsAgreed";
import OrderFormComment from "../../../BookingOrder/Fields/OrderFormComment";
import {IBookingOrderState} from "../../../../data/BookingOrder";
import BookingLightboxOrderCheckInOut from "./BookingLightboxOrderCheckInOut";
import {BookingOrderContext} from "../../../BookingOrder/BookingOrderContext";
import {TranslationNamespaces} from "../../../../constants/TranslationNamespaces";
import {PaymentContext} from "../../../Payment/PaymentContext";
import {AccommodationsContext} from "../../../Accommodations/AccommodationsContext";
import {SelectedAccommodationsHelper} from "../../../Accommodations/helpers/SelectedAccommodationsHelper";
import {RoomCategorySearchParamsContext} from "../../../SearchParams/SearchParamsContext";
import {BookingOverbookingContext} from "../../../BookingOverbooking/BookingOverbookingContext";
import {Checkbox, Link} from "@skbkontur/react-ui";
import {BookingType} from "../../../../data/Booking";
import qs from "qs";
import {useLocation} from "react-router";
import {bookingLightboxOrderAnalyticsEvents} from "../../../../analytics/bookingLightboxOrderAnalyticsEvents";
import {useGetPaymentSourceSettingsId} from "../../../../hooks/useGetPaymentSourceSettingsId";
import {BookingContactInfoHelper} from "../../../../helpers/BookingContactInfoHelper";
import BookingMakeOrder from "../../../../components/BookingMakeOrder/BookingMakeOrder";
import {usePaymentSystem} from "../../../../hooks/usePaymentSystem";
import BookingLightboxContainer from "../BookingLightboxContainer";
import {Currency} from "@skbkontur/hotel-data/currency";
import {useMemoObject} from "@skbkontur/hotel-hooks/memo";

interface IBookingLightboxOrderProps {
    onBooking: (bookingParams: BookingType) => Promise<void>;
}

const initialState: IBookingOrderState = {
    customer: {fio: "", email: "", phone: ""},
    comment: "",
    isAgreed: false,
};

export const BOOKING_LIGHTBOX_ORDER_ID = "BookingLightboxOrder";

/* eslint-disable i18next/no-literal-string */
const BookingLightboxOrder = (props: IBookingLightboxOrderProps) => {
    const {onBooking} = props;

    const location = useLocation();
    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const getPaymentSourceSettingsId = useGetPaymentSourceSettingsId();
    const {isPaymentProcess, paymentUrl} = React.useContext(PaymentContext);
    const {params} = React.useContext(RoomCategorySearchParamsContext);
    const {isOverbookingLoading, isOverbooking, stopWatchOverbooking} = React.useContext(BookingOverbookingContext);

    const isLoading = useSelector((state: IAppState) => state.booking.isLoading);
    const {promoCodeId, prepaymentDiscount} = useSelector((state: IAppState) => state.promoCode?.applyResult) || {};
    const {selectedAccommodationsMap, accommodationsPricesMap} = React.useContext(AccommodationsContext);
    const bookings = SelectedAccommodationsHelper.toSelectedAccommodationBookings(selectedAccommodationsMap);

    const availableCashbackTerminals = useSelector(
        (state: IAppState) => state.cashbackInfo?.availableCashbackTerminals
    );
    const isCashbackAvailable = !!availableCashbackTerminals?.length;

    const [waitPaymentFromSystem, setWaitPaymentFromSystem] = React.useState<PaymentSystem>();
    const [form, setForm] = React.useState<IBookingOrderState>(initialState);
    const {isCashback, isPaymentSystemEnabled, setIsCashback} = usePaymentSystem();

    const validationRef = React.useRef<ValidationContainer>();

    const prepaymentSum = SelectedAccommodationsHelper.getTotalPrepayment(bookings, accommodationsPricesMap);
    const prepaymentSumWithPromoCodeDiscount = Currency.subtract(prepaymentSum, prepaymentDiscount);
    const hasPrepayment = isPaymentSystemEnabled && Currency.getFloatValue(prepaymentSum) > 0;
    const isTBankPaymentProcess = isPaymentProcess && waitPaymentFromSystem === PaymentSystem.TBankSbp;

    const cashbackLink = `https://privetmir.ru/russiatravel/?${qs.stringify(
        {redirectUrlFrame: location},
        {encode: true}
    )}`;

    const isBookingProcessRef = React.useRef(false);
    const book = React.useCallback(
        async (paymentSource?: PaymentSystem) => {
            if (isBookingProcessRef.current) return;
            isBookingProcessRef.current = true;
            if (await validationRef.current.validate()) {
                const {comment, customer} = form;
                const contactInfo = BookingContactInfoHelper.getContactInfo(form);
                const {fromDate: checkin, toDate: checkout} = params;

                const bookingsToCreate = bookings.map(({accommodation}) => ({
                    ...accommodation,
                    customer,
                    comment,
                    checkin,
                    checkout,
                    promoCodeId,
                }));

                if (!(await isOverbooking())) {
                    stopWatchOverbooking();
                    const paymentSourceSettingsId = getPaymentSourceSettingsId(paymentSource, isCashback);
                    const bookingParams = {
                        paymentSourceSettingsId,
                        bookings: bookingsToCreate,
                        paymentSystem: paymentSource,
                        contactInfo,
                        prepaymentSum: Currency.getFloatValue(prepaymentSum),
                    };
                    await onBooking(bookingParams);
                    setWaitPaymentFromSystem(paymentSource);

                }
            }
            isBookingProcessRef.current = false;
        },
        [onBooking, bookings, form, params, isCashback, getPaymentSourceSettingsId, isOverbooking, stopWatchOverbooking]
    );

    const handleIsCashbackChange = (isCashback: boolean) => {
        bookingLightboxOrderAnalyticsEvents.trackCashbackChoose(isCashback);
        setIsCashback(isCashback);
    };

    return (
        <BookingOrderContext.Provider value={useMemoObject({form, setForm, book})}>
            <BookingLightboxContainer id={BOOKING_LIGHTBOX_ORDER_ID}>
                <BookingLightboxOrderCheckInOut />
                <div className={styles.form}>
                    <div className={styles.title}>{t("fillPersonalData")}</div>
                    <div className={styles.fieldWrapper}>
                        <ValidationContainer ref={validationRef}>
                            <OrderFormFio />
                            <OrderFormPhone />
                            <OrderFormEmail />
                            <OrderFormComment />
                            <OrderFormIsAgreed />
                        </ValidationContainer>
                        {hasPrepayment && (
                            <div className={styles.prepayment}>
                                <BookingPaymentSum sumValue={prepaymentSumWithPromoCodeDiscount || {roubles: 2000, copecks: 0}} />
                            </div>
                        )}
                    </div>
                    {isCashbackAvailable && (
                        <Checkbox
                            style={{padding: 0}}
                            checked={isCashback}
                            onValueChange={handleIsCashbackChange}
                            className={styles.isCashbackCheckbox}
                        >
                            {t("cashback.payWithCashback", {raw: true})}
                        </Checkbox>
                    )}
                    {isCashback && (
                        <div className={styles.isCashbackText}>
                            {t("cashback.registerCard", {
                                raw: true,
                                reactParams: {
                                    0: text => <Link href={cashbackLink}>{text}</Link>,
                                },
                            })}
                        </div>
                    )}
                    <div className={styles.prepayment}>
                        <BookingMakeOrder
                            isTBankPaymentProcess={isTBankPaymentProcess}
                            isOverbookingLoading={isOverbookingLoading}
                            hasPrepayment={hasPrepayment}
                            paymentUrl={paymentUrl}
                            isCashback={isCashback}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </BookingLightboxContainer>
        </BookingOrderContext.Provider>
    );
};
BookingLightboxOrder.displayName = "BookingLightboxOrder";
export default BookingLightboxOrder;
