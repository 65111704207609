import styles from "./CalendarControls.scss";
import {IconTypes, ArrowCurveRightIcon, ArrowCurveLeftIcon} from "@skbkontur/hotel-components/icon";

interface ICalendarControlsProps {
    leftArrowIsHidden?: boolean;
    rightArrowIsHidden?: boolean;
    onLeftArrowClick: () => void;
    onRightArrowClick: () => void;
}

const CalendarControls = (props: ICalendarControlsProps) => {
    const {
        leftArrowIsHidden,
        rightArrowIsHidden,
        onLeftArrowClick,
        onRightArrowClick
    } = props;

    return (
        <div className={styles.calendarControls}>
            <div className={styles.buttonWrapper}>
                {!leftArrowIsHidden && (
                    <button
                        className={styles.button}
                        onClick={onLeftArrowClick}
                    >
                        <ArrowCurveLeftIcon type={IconTypes.Regular16} size={14} />
                    </button>
                )}
            </div>
            <div className={styles.buttonWrapper}>
                {!rightArrowIsHidden && (
                    <button
                        className={styles.button}
                        onClick={onRightArrowClick}
                    >
                        <ArrowCurveRightIcon type={IconTypes.Regular16} size={14} />
                    </button>
                )}
            </div>
        </div>
    );
};
CalendarControls.displayName = "CalendarControls";
export default CalendarControls;
