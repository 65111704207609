import {DateFormat, DateHelper} from "@skbkontur/hotel-date";
import {AvailabilitiesDailyType} from "../Availability";
import {CalendarDataMapType} from "../Calendar/Calendar";
import {Currency} from "@skbkontur/hotel-data/currency";

export class RoomAvailabilityCalendarPipes {
    static toFrontend = (availabilities: AvailabilitiesDailyType, offsetInMinutes: number): CalendarDataMapType => (
        Object.keys(availabilities).reduce((calendarData, timestamp) => {
            const date = DateHelper.convertWithTimezone(timestamp, {
                formatIn: DateFormat.UnixMsTimestamp,
                formatOut: DateFormat.FullDateYearFirst,
                offsetInMinutes
            });

            const yearMonth = DateHelper.convertWithTimezone(date, {
                formatIn: DateFormat.FullDateYearFirst,
                formatOut: DateFormat.MonthWithYear,
                offsetInMinutes
            });

            const dayNumber = DateHelper.getDayNumber(date, DateFormat.FullDateYearFirst);

            calendarData[yearMonth] = calendarData[yearMonth] ?? {};
            calendarData[yearMonth][dayNumber] = {
                price: Currency.getFloatValue(availabilities[timestamp].price),
            };
            return calendarData;
        }, {} as CalendarDataMapType)
    );
}
