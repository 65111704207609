import {KnownSearchParams} from "../../data/SearchParams";
import {
    HourlyObjectsSearchParamsContext,
    ISearchParamsContext,
    PlaceSearchParamsContext,
    RoomCategorySearchParamsContext,
} from "./SearchParamsContext";
import {ApiBase} from "../../api/apiBase";
import {useHistory} from "react-router";
import {UrlParamsExtractor} from "../../helpers/UrlParamsExtractor";
import {useMemoObject} from "@skbkontur/hotel-hooks/memo";

interface ISearchParamsProvider {
    syncUrlParams?: boolean;
}

const DEFAULT_PARAMS: KnownSearchParams = {
    adultsCount: 2,
    kidsCount: 0,
    fromDate: null,
    toDate: null,
};

const SearchParamsProviderHOC = <TParams extends KnownSearchParams>(
    Context: React.Context<ISearchParamsContext<TParams>>,
    needToShowPlace: boolean = false
): React.FunctionComponent<React.PropsWithChildren<ISearchParamsProvider>> => {
    const WrappedComponent = (props: React.PropsWithChildren<ISearchParamsProvider>) => {
        const {children, syncUrlParams} = props;

        const {push, location} = useHistory();

        const [calendarRoomCategoryId, setCalendarRoomCategoryId] = React.useState<string>(null);

        const [params, setParams] = React.useState<TParams>(() => {
            const locationParams = UrlParamsExtractor.getSearchParams<TParams>(location);
            return syncUrlParams && locationParams ? locationParams : (DEFAULT_PARAMS as TParams);
        });

        const setParamsSafety = React.useCallback((action: (params: TParams) => TParams) => {
            setParams(params => {
                const newParams = action(params);
                return {
                    ...newParams,
                    kidsCount: newParams.kidsCount || 0,
                    adultsCount: newParams.adultsCount || 1
                };
            });
        }, []);

        React.useEffect(() => {
            const paramsForSearch = {...params};
            const {fromDate, toDate, adultsCount} = paramsForSearch;
            const {kidsCount, ...withoutKidsCountParams} = paramsForSearch;
            const isParamsValid = !!fromDate && !!adultsCount;
            if (syncUrlParams && isParamsValid) {
                const paramsToSave = kidsCount
                    ? needToShowPlace
                        ? paramsForSearch
                        : {fromDate, toDate, adultsCount, kidsCount}
                    : needToShowPlace
                        ? withoutKidsCountParams
                        : {fromDate, toDate, adultsCount};
                push({search: ApiBase.toQueryParams(paramsToSave)});
            }
        }, [params]);

        return (
            <Context.Provider
                value={useMemoObject({
                    params,
                    setParams: setParamsSafety,
                    calendarRoomCategoryId,
                    setCalendarRoomCategoryId,
                })}
            >
                {children}
            </Context.Provider>
        );
    };

    WrappedComponent.displayName = "WithSearchParamsProviderHOC";
    return WrappedComponent;
};

export const RoomCategorySearchParamsProvider = SearchParamsProviderHOC(RoomCategorySearchParamsContext);
RoomCategorySearchParamsProvider.displayName = "RoomCategorySearchParamsProvider";

export const HourlyObjectsSearchParamsProvider = SearchParamsProviderHOC(HourlyObjectsSearchParamsContext);
HourlyObjectsSearchParamsProvider.displayName = "HourlyObjectsSearchParamsProvider";

export const PlaceSearchParamsProvider = SearchParamsProviderHOC(PlaceSearchParamsContext, true);
PlaceSearchParamsProvider.displayName = "PlaceSearchParamsProvider";
