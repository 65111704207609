import {PaymentSystem} from "../data/Payment";
import {BaseAnalyticsEvents} from "./BaseAnalyticsEvents";
import {AnalyticsCategory, BookingLightboxPaymentAnalyticsAction} from "./Analytics";

class BookingLightboxPaymentAnalyticsEvents extends BaseAnalyticsEvents {
    trackBookingWithoutPrepayment = () => (
        this.trackEvent(
            BookingLightboxPaymentAnalyticsAction.BookingWithoutPrepayment,
            "бронирование без предоплаты"
        )
    );

    trackPaymentStart = (paymentSystem: PaymentSystem, sum: number) => (
        this.trackEvent(
            BookingLightboxPaymentAnalyticsAction.PaymentStart,
            "старт оплаты (с указанием системы оплаты)",
            {
                paymentSystem, sum
            }
        )
    );

    trackSuccessfulPayment = () => (
        this.trackEvent(
            BookingLightboxPaymentAnalyticsAction.PaymentSuccessful,
            "успешная оплата"
        )
    );

    trackCanceledPayment = () => (
        this.trackEvent(
            BookingLightboxPaymentAnalyticsAction.PaymentCanceled,
            "неуспешная оплата по причине клиента"
        )
    );

    trackPaymentFailedButBooked = () => (
        this.trackEvent(
            BookingLightboxPaymentAnalyticsAction.PaymentFailedButBooked,
            "не смогли принять оплаты, забронировали без оплаты"
        )
    );
}

export const bookingLightboxPaymentAnalyticsEvents = new BookingLightboxPaymentAnalyticsEvents(AnalyticsCategory.BookingLightboxPayment);
