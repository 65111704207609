import {BaseAnalyticsEvents} from "./BaseAnalyticsEvents";
import {AnalyticsCategory, VkMiniAppsAnalyticsAction} from "./Analytics";

class VkMiniAppsEvent extends BaseAnalyticsEvents {
    initializationMOB = (organizationName: string) => (
        this.trackEvent(
            VkMiniAppsAnalyticsAction.InitializationMOB,
            "открыли МОБ в приложении ВК",
            {organizationName}
        )
    );

    initializationWidget = (organizationName: string, organizationId: string) => (
        this.trackEvent(
            VkMiniAppsAnalyticsAction.InitializationWidget,
            "загрузился виджет в приложении ВК",
            {organizationName, organizationId}
        )
    );
}

export const vkMiniAppsEventAnalyticsEvents = new VkMiniAppsEvent(AnalyticsCategory.VkMiniApps);
