import {isEqual} from "lodash";
import {useDispatch} from "react-redux";
import BookingSearchForm from "../../../components/BookingSearchForm/BookingSearchForm";
import {IRoomCategorySearchParams} from "../../../data/SearchParams";
import {getAccommodations} from "../../../store/accommodations/accommodationsActionCreators";
import {RoomCategorySearchParamsContext} from "../../SearchParams/SearchParamsContext";
import {DeviceContext} from "../../Device/DeviceContext";
import WidgetTypeTheme from "../../../widget/components/WidgetTheme/WidgetTypeTheme";
import {WidgetType} from "../../../widget/settings/WidgetType";
import {BookingLightboxStep, BookingLightboxStepsContext} from "./Steps/BookingLightboxStepsContext";
import BookingLightboxContainer from "./BookingLightboxContainer";

const BookingLightboxSearchForm = () => {
    const dispatch = useDispatch();

    const {isMobileMode} = React.useContext(DeviceContext);
    const {step, goToStep} = React.useContext(BookingLightboxStepsContext);

    const {
        params: searchParams,
        setParams: setSearchParams
    } = React.useContext(RoomCategorySearchParamsContext);

    const search = (newSearchParams: IRoomCategorySearchParams) => {
        /**
         * Необходимо обновить searchParams до accommodations,
         * чтобы обновленные параметры попали в BookingOverbookingProvider */
        setSearchParams(currentSearchParams => ({
            ...currentSearchParams,
            ...newSearchParams,
        }));
        dispatch(getAccommodations(newSearchParams));
    };

    const changeParams = (changedParams: IRoomCategorySearchParams) => {
        const {fromDate, toDate} = changedParams;
        const isInitial = step === BookingLightboxStep.Initial;
        const isParamsChanged = !isEqual(searchParams, changedParams);

        if (isParamsChanged && !isInitial) {
            goToStep(BookingLightboxStep.Initial);
        }

        if (!isParamsChanged && isInitial && toDate && fromDate) {
            goToStep(BookingLightboxStep.SelectAccommodations);
        }
    };

    return (
        <BookingLightboxContainer>
            <WidgetTypeTheme type={WidgetType.BookingForm}>
                <BookingSearchForm
                    params={searchParams}
                    onSearch={search}
                    onChange={changeParams}
                    noPaddings={isMobileMode}
                    inline
                    searchOnMount
                    autoFocus
                />
            </WidgetTypeTheme>
        </BookingLightboxContainer>
    );
};
BookingLightboxSearchForm.displayName = "BookingLightboxSearchForm";
export default BookingLightboxSearchForm;