import styles from "./HourlyBookingRateItem.scss";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import {useSelector} from "react-redux";
import {IAppState} from "../../../../../store/AppState";
import {useHourlyRate} from "../../../../../hooks/useHourlyRate";
import {IconTypes, OkIcon} from "@skbkontur/hotel-components/icon";
import {createMapBy} from "@skbkontur/hotel-utils";

interface IHourlyBookingRateItemIncludesProps {
    hourlyRateId: string;
}

const HourlyBookingRateItemIncludes = (props: IHourlyBookingRateItemIncludesProps) => {
    const {hourlyRateId} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);
    const hourlyFeatures = useSelector((state: IAppState) => state.hourlyFeatures.hourlyFeatures);
    const hourlyFeaturesMap = createMapBy(hourlyFeatures, hf => hf.id);

    const {rateFeatures} = useHourlyRate(hourlyRateId);
    const rateFeaturesNames = rateFeatures?.map(rf => hourlyFeaturesMap[rf]?.name) || [];

    const includesItems = [t("hourlyRates.includes", {stringFormat: true}), ...rateFeaturesNames].filter(Boolean);

    return (
        <div className={styles.includes}>
            {includesItems.map((el: string) => (
                <span key={el}>
                    {<OkIcon type={IconTypes.Solid16} />} {el}
                </span>
            ))}
        </div>
    );
};
HourlyBookingRateItemIncludes.displayName = "HourlyBookingRateItemIncludes";
export default HourlyBookingRateItemIncludes;
