import {ThemeContext} from "@skbkontur/react-ui";
import {Provider} from "react-redux";
import {KonturI18NProvider, Locale} from "@skbkontur/i18n";
import {captureSentryError, SentryErrorType} from "@skbkontur/hotel-sentry";
import store from "../store";
import translationManagerApi, {TranslationManagerApi} from "../api/translationManagerApi";
import {TranslationNamespaces} from "../constants/TranslationNamespaces";
import DeviceProvider from "../providers/Device/DeviceProvider";
import GalleryProvider from "../providers/Gallery/GalleryProvider";
import TranslationLoader from "../common/components/TranslationLoader/TranslationLoader";
import {BM_DEFAULT_REACT_UI_THEME} from "../widget/theme/ThemeConstants";

interface IAppRootProps {
    keepDocumentLangUpdated?: boolean;
}

const supportedLocales = [
    Locale.English, Locale.Russian, Locale.Turkish, Locale.Finnish, Locale.Spanish, Locale.Chinese, Locale.Kazakh
];

const AppRoot = (props: React.PropsWithChildren<IAppRootProps>) => {
    const {keepDocumentLangUpdated = false, children} = props;

    const onError = React.useCallback((errorMessage: string) => (
        captureSentryError(errorMessage, SentryErrorType.I18)
    ), []);

    return (
        <Provider store={store}>
            <KonturI18NProvider
                options={{
                    backendOptions: {
                        loadPath: TranslationManagerApi.createTranslationsUrl(["{{lng}}", "{{ns}}"])
                    },
                    detectorOptions: {
                        useDetector: true,
                        defaultLocale: Locale.English
                    }
                }}
                translationManagerApi={translationManagerApi}
                supportedLocales={supportedLocales}
                namespaces={Object.values(TranslationNamespaces)}
                keepDocumentLangUpdated={keepDocumentLangUpdated}
                loader={<TranslationLoader/>}
                onError={onError}
            >
                <ThemeContext.Provider value={BM_DEFAULT_REACT_UI_THEME}>
                    <DeviceProvider>
                        <GalleryProvider>
                            {children}
                        </GalleryProvider>
                    </DeviceProvider>
                </ThemeContext.Provider>
            </KonturI18NProvider>
        </Provider>
    );
};
AppRoot.displayName = "AppRoot";
export default AppRoot;
