import cn from "classnames";
import {useSelector} from "react-redux";
import {useTranslation} from "@skbkontur/i18n";
import {WidgetClientGlobalStyles} from "../../widget/WidgetClientGlobalStyles";
import WidgetStore from "../../widget/WidgetStore";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import {IAppState} from "../../store/AppState";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {IGuestSelectField} from "./GuestsSelectControl";
import GuestsSelectTooltipRow from "./GuestsSelectTooltipRow";
import styles from "./GuestsSelectControl.scss";

interface IGuestsSelectTooltipProps {
    adults: IGuestSelectField;
    kids: IGuestSelectField;
    alwaysShowKids: boolean;
    onApply: () => void;
}

const GuestsSelectTooltip = (props: IGuestsSelectTooltipProps) => {
    const {adults, kids, alwaysShowKids, onApply} = props;

    const {t, tcn} = useTranslation(TranslationNamespaces.BookingModule);
    const {freeChildrenOn, freeChildrenAge} = useSelector((state: IAppState) => state.hotelInfo.info);

    const btnClasses = cn(styles.selectButton, {
        [WidgetClientGlobalStyles.WidgetGuestSelectControlButtonContainer]: WidgetStore.isWidget()
    });

    return (
        <div className={styles.tooltip}>
            <GuestsSelectTooltipRow field={adults} minGuestsCount={1}>
                {freeChildrenOn && !alwaysShowKids
                    ? t("adultsAndChildrenAbove", {
                        age: freeChildrenAge,
                        reactParams: {
                            0: text => <span className={styles.subCaption}>{text}</span>
                        }
                    }) : tcn("adults")}
            </GuestsSelectTooltipRow>
            {(freeChildrenOn || alwaysShowKids) && (
                <GuestsSelectTooltipRow field={kids} minGuestsCount={0}>
                    {!alwaysShowKids ? tcn("childrenUnderAge", {count: freeChildrenAge, raw: true}) : tcn("children")}
                </GuestsSelectTooltipRow>
            )}
            <div className={btnClasses}>
                <PrimaryButton onClick={onApply}>
                    {tcn("buttons.selectGerund")}
                </PrimaryButton>
            </div>
        </div>
    );
};
GuestsSelectTooltip.displayName = "GuestsSelectTooltip";
export default GuestsSelectTooltip;
