import {ItemOf} from "@skbkontur/hotel-types/utils";

export class Async {
    static delay = (time = 2000) => (
        new Promise<void>((resolve) => {
            setTimeout(resolve, time);
        })
    );

    static map = <TItem, TResult>(
        items: TItem[],
        func: (item: TItem, index: number) => TResult | Promise<TResult>
    ): Promise<TResult[]> => {
        const results = [];
        for (const item of items) {
            const result = func(item, results.length);
            const promiseResult = (result as Promise<TResult>)?.then ? result : Promise.resolve(result);
            results.push(promiseResult);
        }
        return Promise.all(results);
    };

    static some = async <TItem>(
        items: TItem[],
        func: (item: TItem, index: number) => Promise<boolean>
    ): Promise<boolean> => {
        const firstBoolean = await Async.first(items, func, Boolean);
        return !!firstBoolean;
    };

    static first = async <TItem, TResult>(
        items: TItem[],
        func: (item: TItem, index: number) => Promise<TResult>,
        compare: (result: TResult) => boolean
    ): Promise<TResult> => {
        for (let i = 0; i < items.length; ++i) {
            const result = await func(items[i], i);
            if (compare(result))
                return result;
        }
        return null;
    };

    // TODO учесть, что список может мутироваться в процессе вызова
    static chain = async <TFunctions extends ChainFunction[]>(functions: TFunctions, runner?: ChainRunnerType): Promise<void> => {
        for (const func of functions) {
            const result = runner ? runner(func) : func();
            await Promise.resolve(result);
        }
    };

    // eslint-disable-next-line @typescript-eslint/naming-convention
    static debounce<T, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10>(
        action: DebounceActionType<T, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10>,
        timeoutMs: number
    ): DebounceActionType<T, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10> {
        let timeout: NodeJS.Timeout;
        return (...args) => (
            new Promise<T>(resolve => {
                clearTimeout(timeout);
                timeout = setTimeout(() => resolve(action(...args)), timeoutMs);
            })
        );
    }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type DebounceActionType<T, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10> =
    (a1?: A1, a2?: A2, a3?: A3, a4?: A4, a5?: A5, a6?: A6, a7?: A7, a8?: A8, a9?: A9, a10?: A10) => Promise<T>;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
type ChainFunction = (...args: any) => ChainResult;
type ChainRunnerType = (func: ChainFunction) => ChainResult;

export type ChainResult = Promise<void> | void;
export type GetChainResult<TFunctions extends ChainFunction[]> = Promise<void> extends ReturnType<ItemOf<TFunctions>>
    ? Promise<void> : void;
