import {IIconProps, IconTypes} from "./Icon";
import {MarketShoppingCartIcon16Solid} from "@skbkontur/icons/MarketShoppingCartIcon16Solid";

type ShoppingCartIconTypes = IconTypes.Solid16;

interface IShoppingCartIconProps extends IIconProps {
    type: ShoppingCartIconTypes;
}

const ShoppingCartIcon = ({type, ...iconProps}: IShoppingCartIconProps) => {
    switch (type) {
        case IconTypes.Solid16:
            return <MarketShoppingCartIcon16Solid {...iconProps} />;
    }
};
ShoppingCartIcon.displayName = "ShoppingCartIcon";
export default ShoppingCartIcon;
