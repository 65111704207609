import {Gapped, Tooltip} from "@skbkontur/react-ui";
import {YandexCheckoutLogo} from "../../PaymentLogos/YandexCheckoutLogo";
import {TBankSbpLogo} from "../../PaymentLogos/TBankSbpLogo";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {PaymentSystem} from "../../../data/Payment";
import styles from "../BookingMakeOrder.scss";
import {bookingLightboxOrderAnalyticsEvents} from "../../../analytics/bookingLightboxOrderAnalyticsEvents";
import {IconTypes, HelpDotIcon} from "@skbkontur/hotel-components/icon";
import {RoundButton} from "@skbkontur/hotel-components/roundButton";

interface IBookingPaymentSystemInfoProps {
    paymentSystem: PaymentSystem;
}

export const BookingPaymentSystemInfo = (props: IBookingPaymentSystemInfoProps) => {
    const {paymentSystem} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const [isInfoShown, setIsInfoShown] = React.useState<boolean>(false);

    const trackHover = () => {
        if (!isInfoShown) {
            bookingLightboxOrderAnalyticsEvents.trackShowPaymentSystemInfo();
            setIsInfoShown(true);
        }
    };

    const renderHelpTooltip = () => (
        <div style={{width: 320}}>
            {t(`paymentSourceInfo.${paymentSystem}`)}
        </div>
    );

    return (
        <Gapped gap={10} verticalAlign="middle" className={styles.paymentSystemInfo}>
            {paymentSystem === PaymentSystem.YandexCheckout && <YandexCheckoutLogo/>}
            {paymentSystem === PaymentSystem.TBankSbp && <TBankSbpLogo/>}
            <div onMouseOver={trackHover}>
                <Tooltip render={renderHelpTooltip} pos="top center">
                    <RoundButton icon={<HelpDotIcon type={IconTypes.Solid16} size={14}/>}/>
                </Tooltip>
            </div>
        </Gapped>
    );
};

BookingPaymentSystemInfo.displayName = "BookingPaymentSystemInfo";
