import {useUnmount} from "@skbkontur/hotel-hooks/react";
import ReactDOM from "react-dom";

export const usePortalRender = () => {
    const containerRef = React.useRef<HTMLDivElement>();

    React.useMemo(() => {
        const container = document.createElement("div");
        containerRef.current = document.body.insertBefore(container, null);
    }, []);

    useUnmount(() => {
        const container = containerRef.current;
        container.parentNode.removeChild(container);
    });

    return React.useCallback((component: React.ReactNode) => (
        ReactDOM.createPortal(component, containerRef.current)
    ), []);
};