import {useSelector} from "react-redux";
import {useTranslation} from "@skbkontur/i18n";
import {IThemeElementPeriod} from "../../../widget/theme/ThemeWidgets";
import {IHourlyObjectsItemAvailableTime} from "../HourlyObjectsListAvailableTime";
import {IAppState} from "../../../store/AppState";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {useThemeText} from "../../../widget/theme/useThemeText";
import {ThemeHelper} from "../../../widget/theme/ThemeHelper";
import styles from "../HourlyObjectsList.scss";
import HourlyObjectsPeriodPickerSelect from "./HourlyObjectsPeriodPickerSelect";

interface IHourlyObjectsPeriodPickerField {
    value: IHourlyObjectsItemAvailableTime;
    onChange: (value: IHourlyObjectsItemAvailableTime) => void;
    items: IHourlyObjectsItemAvailableTime[];
}

interface IHourlyObjectsPeriodPickerProps {
    from: IHourlyObjectsPeriodPickerField;
    to: IHourlyObjectsPeriodPickerField;
    date: string;
    isError: boolean;
    inline?: boolean;
    theme?: IThemeElementPeriod;
}

const HourlyObjectsPeriodPicker = (props: IHourlyObjectsPeriodPickerProps) => {
    const {inline, date, from, to, isError, theme} = props;
    const {value: fromValue, onChange: onFromChange, items: fromItems} = from;
    const {value: toValue, onChange: onToChange, items: toItems} = to;
    const {fromText, toText, separatorText} = theme || {};

    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    const isLoading = useSelector((state: IAppState) => state.availabilitiesHourly.isLoading);

    const fromLocaleText = useThemeText(fromText);
    const toLocaleText = useThemeText(toText);
    const separatorLocaleText = useThemeText(separatorText);

    ThemeHelper.validatePeriodPicker(separatorLocaleText, fromLocaleText, toLocaleText);
    const fromToMode = !separatorLocaleText && !inline;

    const fromFinalText = fromLocaleText || t("Select.from");
    const toFinalText = toLocaleText || t("Select.to");
    const dashText = separatorLocaleText || "−";

    return (
        <div className={styles.control}>
            {fromToMode && <span>{fromFinalText}</span>}
            <HourlyObjectsPeriodPickerSelect
                error={!from}
                placeholder=" "
                items={fromItems}
                value={fromValue}
                disabled={isLoading}
                selectedDate={date}
                onValueChange={onFromChange}
            />
            {fromToMode && <span>{toFinalText}</span>}
            {!fromToMode && <span className={styles.dash}>{dashText}</span>}
            <HourlyObjectsPeriodPickerSelect
                placeholder=" "
                error={isError || !to}
                items={toItems}
                value={toValue}
                disabled={isLoading}
                selectedDate={date}
                withoutPlaceholderPrice
                onValueChange={onToChange}
            />
        </div>
    );
};
HourlyObjectsPeriodPicker.displayName = "HourlyObjectsPeriodPicker";
export default HourlyObjectsPeriodPicker;
