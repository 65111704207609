import {Textarea} from "@skbkontur/react-ui";
import {useTranslation} from "@skbkontur/i18n";
import OrderFormRow from "../Row/OrderFormRow";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {BookingOrderContext} from "../BookingOrderContext";
import {IBookingOrderState} from "../../../data/BookingOrder";
import {FormFieldName, useTrackFormFieldFill} from "../../../hooks/useTrackFormFieldFill";

const OrderFormComment = () => {
    const {tcn} = useTranslation(TranslationNamespaces.Common);

    const {setForm} = React.useContext(BookingOrderContext);

    const handleChange = (comment: string) => {
        setForm((value: IBookingOrderState) => ({
            ...value,
            comment,
        }));
    };

    const onBlur = useTrackFormFieldFill(FormFieldName.Comment);

    return (
        <OrderFormRow caption={tcn("bookingComment")}>
            <Textarea
                width="100%"
                resize="vertical"
                onValueChange={handleChange}
                onBlur={onBlur}
            />
        </OrderFormRow>
    );
};
OrderFormComment.displayName = "OrderFormComment";
export default OrderFormComment;
