export class ObjectUtils {
    static isObjectStructureEquals<T>(objectA: T, objectB: T): boolean {
        const isArrayA = Array.isArray(objectA);
        const isArrayB = Array.isArray(objectB);
        if (isArrayA !== isArrayB)
            return false;

        const isObjectA = this.isObject(objectA);
        const isObjectB = this.isObject(objectB);
        if (isObjectA !== isObjectB)
            return false;

        if (isArrayA && isArrayB) {
            return objectA.length === objectB.length && objectA.every((_, index) => (
                this.isObjectStructureEquals(objectA[index], objectB[index])
            ));
        }
        if (isObjectA && isObjectB) {
            const keysA = Object.keys(objectA), keysB = Object.keys(objectB);
            return keysA.length === keysB.length && keysA.every((key, index) => (
                keysA[index] === keysB[index] && this.isObjectStructureEquals(objectA[key], objectB[key])
            ));
        }
        return true;
    }

    static isObject = (data: unknown): data is object => (
        data !== null
        && typeof data === "object"
        && !Array.isArray(data)
    );

    static isSoftEqual = (obj1: object = {}, obj2: object = {}): boolean => {
        obj1 = this.filterObject(obj1);
        obj2 = this.filterObject(obj2);

        // нужно фильтровать и проверять на равенство для случая, если хотим сравнить null или undefined с { ... }
        if (Object.keys(obj1).length !== Object.keys(obj2).length)
            return false;

        for (const p in obj1) {
            if (p in obj1) {
                const obj1Value = obj1[p];
                const obj2Value = obj2[p];

                switch (typeof (obj1Value)) {
                    // Deep compare objects
                    case "object":
                        if (!this.isSoftEqual(obj1Value, obj2Value))
                            return false;
                        break;
                    // Compare functions
                    case "function":
                        if (typeof (obj2Value) === "undefined" || (p !== "compare" && String(obj1Value) !== String(obj2Value))) return false;
                        break;
                    case "boolean":
                        if (obj1Value !== obj2Value) return false;
                        break;
                    // Compare values
                    default:
                        if (
                            (!!obj1Value || !!obj2Value || obj1Value === 0 || obj2Value === 0)
                            && (obj1Value !== obj2Value)
                        ) {
                            return false;
                        }
                }
            }
        }
        return true;
    };

    static filterObject = <TObj extends object>(object: TObj): TObj => (
        Object.keys(object || {}).reduce((filteredObject, key: string) => {
            const value = object[key];
            const isEmptyArray = Array.isArray(value) && !value.length;

            if (
                value !== undefined &&
                value !== null &&
                !isEmptyArray &&
                value !== ""
            ) {
                filteredObject[key] = value;
            }

            return filteredObject;
        }, {} as TObj)
    );

    static mapObject = <TObject extends object, TProp extends TObject[keyof TObject]>(
        obj: TObject = {} as TObject,
        mapFunc: (objProp: TProp, key?: string) => TProp
    ): TObject => (
        Object.keys(obj).reduce((result, key) => {
            result[key] = mapFunc(obj[key], key);
            return result;
        }, {} as TObject)
    );
}