import cn from "classnames";
import {Loader, LoaderProps} from "@skbkontur/react-ui";
import {useMemoObject} from "@skbkontur/hotel-hooks/memo";
import {isFuncTest} from "@skbkontur/hotel-process-env";
import styles from "./DelayLoader.scss";
import {DelayLoaderContext} from "./DelayLoaderContext";

export interface IDelayLoaderProps extends Partial<LoaderProps> {
    isHeight?: boolean;
    isFullHeight?: boolean;
    isHeight100?: boolean;
    isLightboxLoader?: boolean;
    isTopVerticalAlign?: boolean;
    duration?: number;
}

const DELAY_DURATION = 500;

const DelayLoader = (props: React.PropsWithChildren<IDelayLoaderProps>) => {
    const {
        isHeight100,
        isHeight,
        isFullHeight,
        isLightboxLoader,
        active,
        isTopVerticalAlign = false,
        duration = DELAY_DURATION,
        ...loaderProps
    } = props;

    const {className = ""} = loaderProps;
    const {isParentLoading} = React.useContext(DelayLoaderContext);

    const classNames = cn(className, {
        [styles.loaderHeight]: isHeight,
        [styles.loaderFullHeight]: isFullHeight,
        [styles.loaderHeight100]: isHeight100,
        [styles.isTopVerticalAlign]: isTopVerticalAlign
    });

    const [showLoader, setShowLoader] = React.useState(false);

    React.useEffect(() => {
        if (active) {
            const timer = setTimeout(() => setShowLoader(true), duration);
            return () => clearTimeout(timer);
        } else {
            setShowLoader(false);
        }
    }, [active]);

    const isAllowed = (!isParentLoading || isLightboxLoader) && !isFuncTest();
    const isLoaderVisible = showLoader && isAllowed;

    return (
        <DelayLoaderContext.Provider value={useMemoObject({isParentLoading: active})}>
            <Loader
                {...loaderProps}
                active={isLoaderVisible}
                delayBeforeSpinnerShow={0}
                className={classNames}
            />
        </DelayLoaderContext.Provider>
    );
};
DelayLoader.displayName = "DelayLoader";
export default DelayLoader;
