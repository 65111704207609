import {useSelector} from "react-redux";
import {DateCompare, DateFormat, DateHelper, UnitOfTime} from "@skbkontur/hotel-date";
import {IAppState} from "../../../../store/AppState";
import {HourlyBookingLightboxHelper} from "../HourlyBookingLightboxHelper";

export const useHourlyPeriod = (timeFrom: number, timeTo: number) => {
    const offsetInMinutes = useSelector((state: IAppState) => state.hotelInfo.info.timeZone.offsetInMinutes);
    const date = DateHelper.convertWithTimezone(timeFrom, {
        formatIn: DateFormat.UnixMsTimestamp,
        formatOut: DateFormat.FullDateDayFirst,
        offsetInMinutes,
    });

    const {fromDate, toDate} = HourlyBookingLightboxHelper.getAccommodationsParams(timeFrom, timeTo, offsetInMinutes);
    const isSameDate = DateCompare.isSame({
        firstDate: fromDate,
        secondDate: toDate,
        format:  DateFormat.FullDateYearFirstWithTime,
        unitOfTime: UnitOfTime.Day
    });

    return {
        date,
        hourFrom: DateHelper.convertWithTimezone(timeFrom, {
            formatIn: DateFormat.UnixMsTimestamp,
            formatOut: DateFormat.OnlyTime,
            offsetInMinutes,
        }),
        hourTo: DateHelper.convertWithTimezone(timeTo, {
            formatIn: DateFormat.UnixMsTimestamp,
            formatOut: isSameDate ? DateFormat.OnlyTime : DateFormat.FullDateDayFirstWithTimeWithColons,
            offsetInMinutes,
        }),
    };
};
