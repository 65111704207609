import {ConfigType} from "dayjs";
import dayjs from "../dayjsConfiguration";
import {HotelDate} from "../hotelDate";

export type DateType = string | HotelDate;
export type ConfigDateType = ConfigType;


export enum Inclusivity {
    WithBorder = "[]",
    OnlyLeftBorder = "[)",
    OnlyRightBorder = "(]",
    WithoutBorder = "()"
}

export enum UnitOfTime {
    Year = "year",
    Month = "month",
    Week = "week",
    Day = "day",
    Hour = "hour",
    Minute = "minute",
    Second = "second",
    Millisecond = "millisecond"
}

export const InvalidDate = dayjs("");
