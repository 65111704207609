import numeral, {NumeralJSLocale} from "numeral";
import {Locale} from "@skbkontur/i18n-locales";
import {isNumber} from "lodash";

const delimiters = {
    thousands: " ",
    decimal: ","
};

numeral.register("locale", "ru", {
    delimiters,
    abbreviations: {
        million: "млн",
        billion: "млрд",
        trillion: "трлн"
    }
} as NumeralJSLocale);

numeral.locale(Locale.Russian);

/**
 * format(v, n, x, s, c)
 *
 * @param v: number to format
 * @param n: length of decimal
 * @param x: length of whole part
 * @param s: sections delimiter
 * @param c: decimal delimiter
 */
function format(v: number, n: number, x: number, s: string, c: string) {
    const re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")",
        num = v.toFixed(Math.max(0, ~~n));
    return (c ? num.replace(".", c) : num).replace(new RegExp(re, "g"), "$&" + (s || ","));
}

// Examples:
// format(12345678.9, 2, 3, ".", ",");  // "12.345.678,90"
// format(123456.789, 4, 4, " ", ":");  // "12 3456:7890"
// format(12345678.9, 0, 3, "-");       // "12-345-679"

export class NumberUtils {
    static formatLargeNumber(value: number): string {
        return numeral(value)
            .format("0 a.0")
            .replace(",0", "");
    }

    static format(value: number, decimal = 0, hideDecimalIfWhole = false): string {
        if (value < 1000000) {
            if (decimal) {
                decimal = hideDecimalIfWhole && (value - Math.round(value) === 0) ? 0 : decimal;
            }
            return format(value, decimal, 3, delimiters.thousands, delimiters.decimal);
        }
        return this.formatLargeNumber(value);
    }

    static isNumber = (value: number | string): boolean => isNumber(value) && !isNaN(value);

    static toNumber = (value: string | number): number => {
        if (typeof value === "string")
            return Number(value.replace(/,/g, ".")).valueOf();
        return Number(value).valueOf();
    };

    static canBeParsedAsNumber = (n: string | number) => (
        !isNaN(parseFloat(String(n))) && isFinite(Number(n))
    );
}