import styles from "./RoomAvailabilityCalendarParameters.scss";

interface IRoomAvailabilityCalendarParametersItemProps {
    title: React.ReactNode;
}

const RoomAvailabilityCalendarParametersItem = (props: React.PropsWithChildren<IRoomAvailabilityCalendarParametersItemProps>) => {
    const {title, children} = props;

    return (
        <div className={styles.parameterItem}>
            <div className={styles.parameterName}>
                {title}
            </div>
            {children}
        </div>
    );
};
RoomAvailabilityCalendarParametersItem.displayName = "RoomAvailabilityCalendarParametersItem";
export default RoomAvailabilityCalendarParametersItem;
