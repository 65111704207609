import {useTranslation} from "@skbkontur/i18n";
import cn from "classnames";
import {RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";
import {Currency} from "@skbkontur/hotel-data/currency";
import CounterText from "../../../../../components/Counter/CounterText";
import {IAccommodation} from "../../../../../data/Accommodation";
import {AccommodationsContext} from "../../../../Accommodations/AccommodationsContext";
import {BookingAccommodationsHelper} from "../BookingAccommodationsHelper";
import {useRoomCategory} from "../../../../../hooks/useRoomCategory";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import BookingLightboxSumWithLabel from "../../SumWithLabel/BookingLightboxSumWithLabel";
import {AccommodationsPricesHelper} from "../../../../Accommodations/helpers/AccommodationsPricesHelper";
import {DeviceContext} from "../../../../Device/DeviceContext";
import {bookingLightboxOccupancyAnalyticsEvents} from "../../../../../analytics/bookingLightboxOccupancyAnalyticsEvents";
import {OccupancyHelper} from "../../../../../helpers/OccupancyHelper";
import styles from "./BookingAccommodationsList.scss";
import BookingAccommodationsOccupancy from "./ByOccupancy/BookingAccommodationsOccupancy";

interface IBookingAccommodationsItemProps {
    roomCategoryId: string;
    accommodation: IAccommodation;
}

const BookingAccommodationsItem = (props: IBookingAccommodationsItemProps) => {
    const {roomCategoryId, accommodation} = props;
    const {mainOccupancy, additionalOccupancy} = accommodation;

    const {isMobileMode} = React.useContext(DeviceContext);
    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);
    const roomCategory = useRoomCategory(roomCategoryId);
    const {roomCategoryType} = roomCategory;

    const {
        accommodationsAvailabilityMap,
        selectedAccommodationsMap,
        accommodationsPricesMap,
        addAccommodation,
        removeAccommodation
    } = React.useContext(AccommodationsContext);

    const {availableCount} = accommodationsAvailabilityMap[roomCategoryId];
    const accommodationsByOccupancy = OccupancyHelper.findItemValue(selectedAccommodationsMap?.[roomCategoryId] || [], mainOccupancy, additionalOccupancy) || [];
    const accommodationsByOccupancyCount = accommodationsByOccupancy.length;

    const ratesMap = OccupancyHelper.findItemValue(accommodationsPricesMap?.[roomCategoryId] || [], mainOccupancy, additionalOccupancy) || {};
    const minRatePrice = AccommodationsPricesHelper.getMinRatesPrice(ratesMap);
    const minTotalSum = accommodationsByOccupancyCount
        ? Currency.multiply(minRatePrice, accommodationsByOccupancyCount)
        : minRatePrice;

    const getDefaultAccommodation = React.useCallback(() => {
        const rates = Object.keys(ratesMap);
        const rateId = rates.length === 1 ? rates[0] : null;
        return BookingAccommodationsHelper.getDefaultAccommodation(
            roomCategory,
            mainOccupancy,
            additionalOccupancy,
            rateId
        );
    }, [roomCategory, mainOccupancy, additionalOccupancy, ratesMap]);

    const emptyAccommodationItems = React.useMemo(() => [getDefaultAccommodation()], [getDefaultAccommodation]);

    const onChangeAccommodationsCount = (newCount: number) => {
        bookingLightboxOccupancyAnalyticsEvents.trackOccupancyCountChange(newCount);
        if (newCount > accommodationsByOccupancyCount) {
            addAccommodation({
                roomCategoryId,
                mainOccupancy,
                additionalOccupancy,
                accommodation: getDefaultAccommodation()
            });
        }
        if (newCount < accommodationsByOccupancyCount) {
            removeAccommodation({
                roomCategoryId,
                mainOccupancy,
                additionalOccupancy,
                index: accommodationsByOccupancyCount - 1
            });
        }
    };

    const isListActive = accommodationsByOccupancyCount > 0;
    const accommodationsList = isListActive ? accommodationsByOccupancy : emptyAccommodationItems;

    const maxValueHint = RoomCategoryHelper.isHostel(roomCategoryType)
        ? t("validations.noMorePlaces")
        : t("validations.noMoreRooms");

    const counter = (
        <div className={styles.counter}>
            <CounterText
                maxValue={availableCount + accommodationsByOccupancyCount}
                maxValueHint={maxValueHint}
                value={accommodationsByOccupancyCount}
                onChange={onChangeAccommodationsCount}
            />
        </div>
    );

    const sumWithLabel = (
        <div className={cn(styles.sumWithLabel, {[styles.disabled]: !isListActive})}>
            <BookingLightboxSumWithLabel
                sum={minTotalSum}
                isHostel={RoomCategoryHelper.isHostel(roomCategoryType)}
                accommodationsCount={accommodationsList.length}
                shortOnMobile
            />
        </div>
    );

    const accommodations = (
        <BookingAccommodationsOccupancy
            roomCategoryId={roomCategoryId}
            mainOccupancy={mainOccupancy}
            additionalOccupancy={additionalOccupancy}
            accommodations={accommodationsList}
            cost={minTotalSum}
            disabled={!isListActive}
        />
    );

    if (isMobileMode)
        return (
            <div className={cn(styles.byOccupancy, styles.rowItems)}>
                {sumWithLabel}
                <div className={styles.columnRightItems}>
                    {counter}
                    {accommodations}
                </div>
            </div>
        );

    return (
        <div className={cn(styles.byOccupancy, styles.columnRightItems)}>
            {counter}
            {sumWithLabel}
            {accommodations}
        </div>
    );
};
BookingAccommodationsItem.displayName = "BookingAccommodationsItem";
export default React.memo(BookingAccommodationsItem);
