import React from "react";
import {useSelector} from "react-redux";
import {captureSentryError, SentryErrorType} from "@skbkontur/hotel-sentry";
import {useMount} from "@skbkontur/hotel-hooks/react";
import WidgetBookingControl from "../WidgetBookingForm/WidgetBookingForm";
import WidgetRoomList from "../WidgetRoomList/WidgetRoomList";
import styles from "../../WidgetApp.scss";
import CssHelper from "../../../helpers/CssHelper";
import WidgetStore from "../../WidgetStore";
import {IAppState} from "../../../store/AppState";
import AllBookingProviders from "../../../providers/AllBookingProviders";
import {
    HourlyObjectsSearchParamsProvider,
    RoomCategorySearchParamsProvider
} from "../../../providers/SearchParams/SearchParamsProvider";
import WidgetCalendar from "../WidgetCalendar/WidgetCalendar";
import WidgetHourlyObjectsList from "../WidgetHourlyObjectsList/WidgetHourlyObjectsList";
import WidgetAvailabilityButtonForMobileDevices
    from "../WidgetAvailabilityButtonForMobileDevices/WidgetAvailabilityButtonForMobileDevices";
import {useInitVkBridge} from "../../../hooks/useInitVkBridge";
import {useIframeEventAnalytics} from "../../../hooks/useIframeEventAnalytics";
import {WidgetClass} from "../../Widget";
import {useColorsCSSTheme} from "../../theme/useColorsCSSTheme";
import {IWidgetAvailabilityButtonForMobileDevices} from "../../settings/WidgetInstanceSettings";
import {WidgetType} from "../../settings/WidgetType";
import WidgetCommonTheme from "../WidgetTheme/WidgetCommonTheme";

let needSetReactUIPortalStyle = true;

const Widget = () => {
    const {hotelId, theme} = WidgetStore.settings;
    const {common} = theme || {};

    const widgetSettingsError = useSelector((state: IAppState) => state.globalError?.widgetSettingsError);

    if (widgetSettingsError)
        return null;

    useInitVkBridge();
    useIframeEventAnalytics();

    useMount(() => {
        if (needSetReactUIPortalStyle) {
            CssHelper.copyCssRule(styles.container, "react-ui");
            needSetReactUIPortalStyle = false;
        }
    });

    const {colors} = common || {};
    const commonThemeRef = React.useRef(document.documentElement);

    useColorsCSSTheme({ref: commonThemeRef, theme: colors});

    const getWidget = (widget: WidgetClass): JSX.Element => {
        const {id, settings, ref} = widget;
        const {type} = settings;

        switch (type) {
            case WidgetType.BookingForm:
            case WidgetType.VerticalBookingForm:
            case WidgetType.HorizontalBookingForm: {
                return (
                    <WidgetBookingControl
                        ref={ref}
                        hotelId={hotelId}
                        widget={widget}
                    />
                );
            }
            case WidgetType.RoomsList: {
                return (
                    <WidgetRoomList
                        ref={ref}
                        hotelId={hotelId}
                        widget={widget}
                    />
                );
            }
            case WidgetType.HourlyObjectsList: {
                return (
                    <WidgetHourlyObjectsList
                        ref={ref}
                        hotelId={hotelId}
                        widget={widget}
                    />
                );
            }
            case WidgetType.AvailabilityCalendar:
            case WidgetType.VerticalAvailabilityCalendar:
            case WidgetType.HorizontalAvailabilityCalendar: {
                return (
                    <WidgetCalendar
                        ref={ref}
                        hotelId={hotelId}
                        widget={widget}
                    />
                );
            }
            case WidgetType.ShowCheckAvailabilityButtonForMobileDevices: {
                return (
                    <WidgetAvailabilityButtonForMobileDevices
                        widget={widget as WidgetClass<IWidgetAvailabilityButtonForMobileDevices>}
                    />
                );
            }
            default: {
                captureSentryError(
                    `Incorrect widget type id=${id}, type=${type as WidgetType}`,
                    SentryErrorType.SwitchCase
                );
                return null;
            }
        }
    };

    return (
        <WidgetCommonTheme>
            <RoomCategorySearchParamsProvider>
                <HourlyObjectsSearchParamsProvider>
                    <AllBookingProviders>
                        {WidgetStore.settings.widgets.map(widget => (
                            <React.Fragment key={widget.id}>
                                {getWidget(widget)}
                            </React.Fragment>
                        ))}
                    </AllBookingProviders>
                </HourlyObjectsSearchParamsProvider>
            </RoomCategorySearchParamsProvider>
        </WidgetCommonTheme>
    );
};
Widget.displayName = "Widget";
export default Widget;
