import {UnitOfTime, hotelDate} from "@skbkontur/hotel-date";
import BookingApi from "../../api/bookingApi";
import {clearGlobalError} from "../globalError/actions";
import {IPaymentsCreateResponse} from "../../api/paymentsApi";
import {CustomThunkAction} from "../ThunkAction";
import {IPaymentData, PaymentResult, PaymentStatus} from "../../data/Payment";
import {IPaymentsByLinkCreateResponse} from "../../api/paymentsByLinkApi";
import {Async} from "@skbkontur/hotel-utils";

export enum PaymentActionTypes {
    GET_PAYMENT_RESULT_REQUEST = "payment/GET_PAYMENT_RESULT_REQUEST",
    GET_PAYMENT_RESULT_SUCCESS = "payment/GET_PAYMENT_RESULT_SUCCESS",
    GET_PAYMENT_RESULT_ERROR = "payment/GET_PAYMENT_RESULT_ERROR",

    CREATE_PAYMENT_REQUEST = "payment/CREATE_PAYMENT_REQUEST",
    CREATE_PAYMENT_SUCCESS = "payment/CREATE_PAYMENT_SUCCESS",
    CREATE_PAYMENT_ERROR = "payment/CREATE_PAYMENT_ERROR",

    GET_PAYMENT_LINK_REQUEST = "payment/GET_PAYMENT_LINK_REQUEST",
    GET_PAYMENT_LINK_SUCCESS = "payment/GET_PAYMENT_LINK_SUCCESS",
    GET_PAYMENT_LINK_ERROR = "payment/GET_PAYMENT_LINK_ERROR",
    CLEAR_PAYMENT = "payment/CLEAR_PAYMENT"
}

interface IPaymentGetResultRequestAction {
    type: PaymentActionTypes.GET_PAYMENT_RESULT_REQUEST;
}

export interface IPaymentGetResultSuccessAction {
    type: PaymentActionTypes.GET_PAYMENT_RESULT_SUCCESS;
    response: PaymentResult;
}

export interface IPaymentGetResultErrorAction {
    type: PaymentActionTypes.GET_PAYMENT_RESULT_ERROR;
    error: Error;
}

interface IPaymentCreateRequestAction {
    type: PaymentActionTypes.CREATE_PAYMENT_REQUEST;
}

export interface IPaymentCreateSuccessAction {
    type: PaymentActionTypes.CREATE_PAYMENT_SUCCESS;
    response: ICreatePaymentResult;
}

export interface ICreatePaymentResult extends IPaymentsCreateResponse {
    result: PaymentResult;
}

export interface IPaymentCreateErrorAction {
    type: PaymentActionTypes.CREATE_PAYMENT_ERROR;
    error: Error;
}

interface IPaymentGetLinkRequestAction {
    type: PaymentActionTypes.GET_PAYMENT_LINK_REQUEST;
}

interface IPaymentGetLinkSuccessAction {
    type: PaymentActionTypes.GET_PAYMENT_LINK_SUCCESS;
    response: IPaymentsByLinkCreateResponse;
}

interface IPaymentGetLinkErrorAction {
    type: PaymentActionTypes.GET_PAYMENT_LINK_ERROR;
    error: Error;
}

interface IPaymentClearPaymentAction {
    type: PaymentActionTypes.CLEAR_PAYMENT;
}

export type KnownPaymentAction =
    | IPaymentGetResultRequestAction
    | IPaymentGetResultSuccessAction
    | IPaymentGetResultErrorAction
    | IPaymentCreateRequestAction
    | IPaymentCreateSuccessAction
    | IPaymentCreateErrorAction
    | IPaymentGetLinkRequestAction
    | IPaymentGetLinkSuccessAction
    | IPaymentGetLinkErrorAction
    | IPaymentClearPaymentAction;

export const createPayment = (
    paymentData: IPaymentData,
    language: string
): CustomThunkAction<ICreatePaymentResult> => (
    // @ts-expect-error TODO Remove ignore after Hotel types coming
    (dispatch, getState, {paymentsApi}) => dispatch({
        type: {
            REQUEST: PaymentActionTypes.CREATE_PAYMENT_REQUEST,
            SUCCESS: PaymentActionTypes.CREATE_PAYMENT_SUCCESS,
            FAILURE: PaymentActionTypes.CREATE_PAYMENT_ERROR
        },
        asyncAction: async () => {
            const {confirmationUrl, payment} = await paymentsApi.createPayment(paymentData, language);
            const result = payment.status === PaymentStatus.canceled ? PaymentResult.failed : null;
            return {confirmationUrl, payment, result};
        },
        // @ts-expect-error TODO Remove ignore after Hotel types coming
        onError: () => dispatch(clearGlobalError())
    })
);

const paymentStatusToPaymentResult = async (status: PaymentStatus, bookingIds: string[]): Promise<PaymentResult> => {
    switch (status) {
        case PaymentStatus.succeeded:
            return PaymentResult.succeeded;
        case PaymentStatus.canceled:
            const bookingCancellationInfos = await BookingApi.getBookingCancellationInfos(bookingIds);
            return bookingCancellationInfos.every(i => i.cancellation && i.cancellation.isCanceled)
                ? PaymentResult.canceled
                : PaymentResult.failed;
        default:
            return PaymentResult.failed;
    }
};

export const getPaymentResult = (paymentId: string, timeLimitInSeconds?: number): CustomThunkAction<PaymentResult> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {paymentsApi}) => dispatch({
        type: {
            REQUEST: PaymentActionTypes.GET_PAYMENT_RESULT_REQUEST,
            SUCCESS: PaymentActionTypes.GET_PAYMENT_RESULT_SUCCESS,
            FAILURE: PaymentActionTypes.GET_PAYMENT_RESULT_ERROR
        },
        asyncAction: async () => {
            const timeLimit = timeLimitInSeconds ? hotelDate().add(timeLimitInSeconds, UnitOfTime.Second) : null;
            const getPayment = async (): Promise<PaymentResult> => {
                // TODO @mozalov: Тут происходит бесконечный опрос getPaymentInfo, разобраться - https://yt.skbkontur.ru/issue/FMS-8088
                const {status, bookingIds} = await paymentsApi.getPaymentInfo(paymentId);
                const isOutOfTimeLimit = !!timeLimit && hotelDate().isAfter(timeLimit);
                if (isOutOfTimeLimit || status === PaymentStatus.succeeded || status === PaymentStatus.canceled) {
                    return paymentStatusToPaymentResult(status, bookingIds);
                }
                await Async.delay(1000);
                return getPayment();
            };
            return getPayment();
        }
    })
);

export const getPaymentLink = (language: string, linkId: string): CustomThunkAction<IPaymentsByLinkCreateResponse> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {paymentsByLinkApi}) => dispatch({
        type: {
            REQUEST: PaymentActionTypes.GET_PAYMENT_LINK_REQUEST,
            SUCCESS: PaymentActionTypes.GET_PAYMENT_LINK_SUCCESS,
            FAILURE: PaymentActionTypes.GET_PAYMENT_LINK_ERROR
        },
        asyncAction: async () => {
            const {confirmationUrl, payment, linkStatus} = await paymentsByLinkApi.getPaymentLink(language, linkId);
            const paymentSource = payment?.source || null;
            return {url: confirmationUrl, paymentSource, linkStatus};
        }
    })
);

export const clearPayment = () => ({
    type: PaymentActionTypes.CLEAR_PAYMENT,
});
