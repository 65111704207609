import {useSelector} from "react-redux";
import RoomCategoriesList from "../../../components/RoomCategoriesList/RoomCategoriesList";
import {IAppState} from "../../../store/AppState";
import {withWidgetContainerAndRefHOC} from "../Widget/withWidgetContainerAndRefHOC";

const WidgetRoomList = () => {
    const categories = useSelector((state: IAppState) => state.roomCategories?.roomCategories);

    if (!categories?.length)
        return null;

    return (
        <RoomCategoriesList/>
    );
};
WidgetRoomList.displayName = "WidgetRoomList";
export default withWidgetContainerAndRefHOC(WidgetRoomList);
