import {useTranslation} from "@skbkontur/i18n";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {BookingContext} from "../../providers/Booking/BookingContext";
import {mainSearchAnalyticsEvents} from "../../analytics/mainSearchAnalyticsEvents";
import {IThemeElementButton} from "../../widget/theme/ThemeWidgets";
import styles from "./FixedButton.scss";

export interface IFixedButtonProps {
    buttonTheme?: IThemeElementButton;
}

const FixedButton = (props: IFixedButtonProps) => {
    const {buttonTheme} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    const {openBookingLightbox} = React.useContext(BookingContext);


    const handleOpenBookingLightbox = () => {
        mainSearchAnalyticsEvents.trackFixedButtonRunSearch();
        openBookingLightbox();
    };

    return (
        <div className={styles.container}>
            <div className={styles.button}>
                <PrimaryButton onClick={handleOpenBookingLightbox} theme={buttonTheme}>
                    {t("checkAvailability")}
                </PrimaryButton>
            </div>
        </div>
    );
};
FixedButton.displayName = "FixedButton";
export default FixedButton;
