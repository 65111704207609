import {once} from "lodash";

import {KnownAllWidgetSettings} from "../../settings/WidgetInstanceSettings";

export const createWidgetThemeContext = once(
    <TTheme extends KnownAllWidgetSettings["theme"]>() => React.createContext({} as TTheme)
);

export const useWidgetThemeContext = <TTheme extends KnownAllWidgetSettings["theme"]>() => (
    React.useContext(createWidgetThemeContext<TTheme>())
);