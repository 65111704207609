import {Gapped, Select} from "@skbkontur/react-ui";
import {DateCompare, DateFormat, DateHelper} from "@skbkontur/hotel-date";
import {tType, useTranslation} from "@skbkontur/i18n";
import {useSelector} from "react-redux";
import {IHourlyObjectsItemAvailableTime} from "../HourlyObjectsListAvailableTime";
import HourlyObjectsTimeItem from "../HourlyObjectsTimeItem";
import {useWidgetThemeContext} from "../../../widget/components/WidgetTheme/WidgetThemeContext";
import {IThemeHourlyObjectsList} from "../../../widget/theme/ThemeWidgets";
import styles from "../HourlyObjectsList.scss";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {IAppState} from "../../../store/AppState";

interface IHourlyObjectsListSelectProps {
    items: IHourlyObjectsItemAvailableTime[];
    disabled?: boolean;
    value: IHourlyObjectsItemAvailableTime | null;
    error?: boolean;
    selectedDate: string;
    withoutPlaceholderPrice?: boolean;
    placeholder?: React.ReactNode;
    onValueChange: (value: IHourlyObjectsItemAvailableTime) => void;
}

declare type SelectItem = IHourlyObjectsItemAvailableTime | React.ReactElement | (() => React.ReactElement);

const getNoAvailableOptions = (selectedDate: string, t: tType) => (
    Select.staticElement(() => (
        <Select.Item isNotSelectable>
            <div className={styles.nextDay}>
                <Gapped vertical gap={5}>
                    {selectedDate}
                    {t("noAvailableOptions")}
                </Gapped>
            </div>
        </Select.Item>
    ))
);

const addDateSeparators = (
    items: IHourlyObjectsItemAvailableTime[],
    selectedDate: string,
    t: tType,
    offsetInMinutes: number
) => {
    let dayPrev = "";

    return items.reduce((result: SelectItem[], item: IHourlyObjectsItemAvailableTime, index: number) => {
        const day = DateHelper.convertWithTimezone(item.timestamp, {
            formatIn: DateFormat.UnixMsTimestamp,
            formatOut: DateFormat.FullDateDayFirst,
            offsetInMinutes,
        });
        const isNotBeforeOrSameDate = DateCompare.isBefore({
            firstDate: selectedDate,
            secondDate: day,
            format: DateFormat.FullDateDayFirst,
        });
        const isNotFirst = index !== 0;

        const isAnotherDay = day !== dayPrev;
        dayPrev = day;

        return [
            ...result,
            isNotBeforeOrSameDate && !isNotFirst && getNoAvailableOptions(selectedDate, t),
            isNotBeforeOrSameDate && !isNotFirst && Select.SEP,
            isAnotherDay && isNotFirst && Select.SEP,
            isAnotherDay &&
            Select.staticElement(() => (
                <Select.Item isNotSelectable>
                    <div className={styles.nextDay}>{day}</div>
                </Select.Item>
            )),
            item
        ].filter(Boolean);
    }, [!items.length && getNoAvailableOptions(selectedDate, t)] as SelectItem[]);
};

const HourlyObjectsPeriodPickerSelect = (props: IHourlyObjectsListSelectProps) => {
    const {items, value, disabled, error, selectedDate, withoutPlaceholderPrice, placeholder, onValueChange} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    const {searchForm} = useWidgetThemeContext<IThemeHourlyObjectsList>();
    const {hoursPicker} = searchForm || {};
    const {width} = hoursPicker || {};

    const offsetInMinutes = useSelector((state: IAppState) => state.hotelInfo.info?.timeZone?.offsetInMinutes);

    return (
        <Select<IHourlyObjectsItemAvailableTime>
            error={error}
            width={width || 90}
            size="medium"
            placeholder={placeholder}
            menuWidth={110}
            items={addDateSeparators(items, selectedDate, t, offsetInMinutes)}
            disabled={disabled}
            renderValue={(value: IHourlyObjectsItemAvailableTime) => (
                <div>
                    {DateHelper.convertWithTimezone(value.timestamp, {
                        formatIn: DateFormat.UnixMsTimestamp,
                        formatOut: DateFormat.OnlyTime,
                        offsetInMinutes,
                    })}
                </div>
            )}
            renderItem={(value: IHourlyObjectsItemAvailableTime) => (
                <HourlyObjectsTimeItem
                    isNotAvailable={value.isUnavailable}
                    withoutPlaceholderPrice={withoutPlaceholderPrice}
                    {...value}
                />
            )}
            value={value}
            onValueChange={onValueChange}
        />
    );
};

HourlyObjectsPeriodPickerSelect.displayName = "HourlyObjectsPeriodPickerSelect";
export default HourlyObjectsPeriodPickerSelect;
