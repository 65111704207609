import {BaseAnalyticsEvents} from "./BaseAnalyticsEvents";
import {AnalyticsCategory, ThemeAnalyticsAction} from "./Analytics";

class ThemeAnalyticsEvents extends BaseAnalyticsEvents {
    useOnInit = (organizationId: string, theme: object) => (
        this.trackEvent(
            ThemeAnalyticsAction.UseOnInit,
            "Использование визуальной темы при инициализации",
            {organizationId, theme}
        )
    );

    useOnAdd = (organizationId: string, theme: object) => (
        this.trackEvent(
            ThemeAnalyticsAction.UseOnAdd,
            "Использование визуальной темы при добавлении виджета",
            {organizationId, theme}
        )
    );
}

export const themeAnalyticsEvents = new ThemeAnalyticsEvents(AnalyticsCategory.Theme);
