import styles from "./HourlyObjectsCard.scss";
import {Compare} from "../../../helpers/CompareHelper";
import HourlyObjectEmpty from "../../../images/empty/HourlyObjectEmpty.png";
import PlacementParameters from "../../PlacementParameters/PlacementParameters";
import {IHourlyObject} from "../../../data/HourlyObjects";
import SliderWithGallery from "../../SliderWithGallery/SliderWithGallery";
import WidgetStore from "../../../widget/WidgetStore";
import EntityCardContainer from "../EntityCardContainer";
import EntityCardTitle from "../EntityCardTitle";
import EntityCardSlider from "../EntityCardSlider";
import EntityCardDescription from "../EntityCardDescription";
import EntityCardContent from "../EntityCardContent";
import EntityCardRight from "../EntityCardRight";

interface IHourlyObjectsCardProps {
    hourlyObject: IHourlyObject;
    hourlyObjectItemId?: string;
    title?: string;
    autoPlaySlider?: boolean;
    isListView?: boolean;
}

const HourlyObjectsCard = (props: React.PropsWithChildren<IHourlyObjectsCardProps>) => {
    const {hourlyObject, title, children, autoPlaySlider, hourlyObjectItemId, isListView} = props;

    const {name, imageMetas, capacity, description, items} = hourlyObject;
    const item = hourlyObjectItemId && items?.find(el => el.id === hourlyObjectItemId);

    return (
        <EntityCardContainer>
            <EntityCardSlider miniMode={!isListView}>
                <SliderWithGallery
                    autoPlay={autoPlaySlider}
                    imageMetas={[...imageMetas].sort(Compare.byOrder)}
                    emptyImage={WidgetStore.getUrlWithBase(HourlyObjectEmpty)}
                />
            </EntityCardSlider>
            <EntityCardContent>
                <EntityCardTitle title={title || name} secondTitle={item?.name}/>
                {capacity > 0 && (
                    <div className={styles.label}>
                        <PlacementParameters capacity={capacity}/>
                    </div>
                )}
                {description && (
                    <EntityCardDescription>
                        {item?.description || description}
                    </EntityCardDescription>
                )}
            </EntityCardContent>
            <EntityCardRight>
                {children}
            </EntityCardRight>
        </EntityCardContainer>
    );
};
HourlyObjectsCard.displayName = "HourlyObjectsCard";
export default React.memo(HourlyObjectsCard);
