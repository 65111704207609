// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fea_7D{min-height:80vh}.xLQBqi{height:100vh}.W19aLs{height:100%}.UmMBog{vertical-align:top}", "",{"version":3,"sources":["webpack://./../packages/components/delayLoader/DelayLoader.scss"],"names":[],"mappings":"AAAA,QACI,eAAA,CAGJ,QACI,YAAA,CAGJ,QACI,WAAA,CAGJ,QACI,kBAAA","sourcesContent":[".loaderHeight {\r\n    min-height: 80vh;\r\n}\r\n\r\n.loaderFullHeight {\r\n    height: 100vh;\r\n}\r\n\r\n.loaderHeight100 {\r\n    height: 100%;\r\n}\r\n\r\n.isTopVerticalAlign {\r\n    vertical-align: top;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderHeight": "fea_7D",
	"loaderFullHeight": "xLQBqi",
	"loaderHeight100": "W19aLs",
	"isTopVerticalAlign": "UmMBog"
};
export default ___CSS_LOADER_EXPORT___;
