import {IIconProps, IconTypes} from "./Icon";
import {ArrowCRightIcon16Regular} from "@skbkontur/icons/ArrowCRightIcon16Regular";
import {ArrowCRightIcon16Light} from "@skbkontur/icons/ArrowCRightIcon16Light";

type ArrowCurveRightIconTypes = IconTypes.Regular16 | IconTypes.Light16;

interface IArrowCurveRightIconProps extends IIconProps {
    type: ArrowCurveRightIconTypes;
}

const ArrowCurveRightIcon = ({type, ...iconProps}: IArrowCurveRightIconProps) => {
    switch (type) {
        case IconTypes.Regular16:
            return <ArrowCRightIcon16Regular {...iconProps} />;
        case IconTypes.Light16:
            return <ArrowCRightIcon16Light {...iconProps} />;
    }
};
ArrowCurveRightIcon.displayName = "ArrowCurveRightIcon";
export default ArrowCurveRightIcon;
