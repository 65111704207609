import {useDispatch, useSelector} from "react-redux";
import {throttle} from "lodash";
import {useEffectWithoutInitCall} from "@skbkontur/hotel-hooks/react";
import {DateCalculate, DateFormat} from "@skbkontur/hotel-date";
import Calendar from "../Calendar/Calendar";
import {getAvailabilities} from "../../store/availabilities/availabilitiesActionCreators";
import {IRoomCategorySearchParams} from "../../data/SearchParams";
import {CalendarDataMapType} from "../../data/Calendar/Calendar";
import {RoomAvailabilityCalendarPipes} from "../../data/RoomAvailabilityCalendar/RoomAvailabilityCalendarPipes";
import {RoomAvailabilityCalendarHelper} from "../../data/RoomAvailabilityCalendar/RoomAvailabilityCalendarHelper";
import {FullDateDayFirstString} from "../../data/Date";
import {CalendarContext} from "../Calendar/Provider/CalendarContext";
import {AvailabilitiesDailyType} from "../../data/Availability";
import {IAppState} from "../../store/AppState";
import {WithProvider} from "../../common/components/WithProvider/WithProvider";
import CalendarProvider from "../Calendar/Provider/CalendarProvider";
import RoomAvailabilityCalendarContainer, {
    IRoomAvailabilityCalendarBaseProps
} from "./RoomAvailabilityCalendarContainer";

interface IRoomAvailabilityCalendarProps extends IRoomAvailabilityCalendarBaseProps {
    searchParams: IRoomCategorySearchParams;
    roomCategoryId: string;
    withContainer?: boolean;
}

interface ILoadAvailabilitiesParams {
    startDate: string;
    adultsCount: number;
    kidsCount: number;
    roomCategoryId: string;
}

const THROTTLE_DELAY = 300;

const RoomAvailabilityCalendar = (props: IRoomAvailabilityCalendarProps) => {
    const {searchParams, roomCategoryId, inlayMode, calendarMode, withContainer} = props;
    const {fromDate, adultsCount, kidsCount} = searchParams;

    const {setLoading} = React.useContext(CalendarContext);
    const dispatch = useDispatch();

    const offsetInMinutes = useSelector((state: IAppState) => state.hotelInfo.info?.timeZone?.offsetInMinutes);
    const today = DateCalculate.getTodayWithTimezone(DateFormat.FullDateDayFirst, offsetInMinutes);

    const initialStartDate: FullDateDayFirstString = fromDate || today;
    const [availabilities, setAvailabilities] = React.useState<AvailabilitiesDailyType>({});

    const [calendarData, setCalendarData] = React.useState<CalendarDataMapType>({});
    const [startDate, setStartDate] = React.useState<FullDateDayFirstString>(initialStartDate);

    const loadAvailabilities = React.useMemo(() => (
        throttle(async (loadParams: ILoadAvailabilitiesParams) => {
            const {roomCategoryId, startDate, kidsCount, adultsCount} = loadParams;
            setLoading(true);
            // @ts-expect-error Redux wrong types
            // eslint-disable-next-line @typescript-eslint/await-thenable
            const {response} = await dispatch(getAvailabilities({
                adultsCount,
                kidsCount,
                roomCategoryId,
                ...RoomAvailabilityCalendarHelper.getDatesForSearch(startDate, offsetInMinutes)
            }));
            setAvailabilities(response);
            setLoading(false);
        }, THROTTLE_DELAY, {leading: true})
    ), [offsetInMinutes]);

    React.useEffect(() => {
        loadAvailabilities({roomCategoryId, startDate, kidsCount, adultsCount});
    }, [roomCategoryId, startDate, kidsCount, adultsCount]);

    useEffectWithoutInitCall(() => {
        setCalendarData({});
    }, [roomCategoryId]);

    useEffectWithoutInitCall(() => {
        setCalendarData((calendarData) => ({
            ...calendarData,
            ...RoomAvailabilityCalendarPipes.toFrontend(availabilities, offsetInMinutes)
        }));
    }, [availabilities, offsetInMinutes]);

    const calendar = (
        <Calendar
            calendarData={calendarData}
            initialDate={initialStartDate}
            onMonthChange={setStartDate}
        />
    );

    return withContainer ? (
        <RoomAvailabilityCalendarContainer calendarMode={calendarMode} inlayMode={inlayMode}>
            {calendar}
        </RoomAvailabilityCalendarContainer>
    ) : (
        calendar
    );
};
RoomAvailabilityCalendar.displayName = "RoomAvailabilityCalendar";
export default WithProvider(RoomAvailabilityCalendar, CalendarProvider);
