/* Usage: https://guides.kontur.ru/principles/base/color/ */

export enum Colors {
    fontBlackActiveColor = "#3d3d3d",
    fontBlackColor = "rgba(0, 0, 0, 87%)",      // #222
    fontGrayColor = "rgba(0, 0, 0, 54%)",       // #757575
    fontDisabledColor = "rgba(0, 0, 0, 32%)",   // #adadad

    bgGrayLightColor = "rgba(0, 0, 0, 4%)", // #F6F6F6
    bgGrayColor = "rgba(0, 0, 0, 8%)",      // #EBEBEB
    bgGrayDarkColor = "rgba(0, 0, 0, 10%)", // #E6E6E6

    bgYellowLightColor = "#FFEEC2",
    bgYellowColor = "#FCB73E",
    fontYellowColor = "#EF8B17",

    bgRedLightColor = "#FFEBEB",
    bgRedColor = "#FE4C4C",
    fontRedColor = "#CC2626",

    bgGreenLightColor = "#C7F9CC",
    bgGreenColor = "#26AD50",
    fontGreenColor = "#1C8A3F",

    bgBlueLightColor = "#CDEDFF",
    bgBlueColor = "#2291FF",
    fontBlueColor = "#1874cf",

    whiteColor = "#FFF"
}