import {WidgetSettingsNormalizer} from "../settings/WidgetSettingsNormalizer";
import {WidgetInsertType} from "../settings/WidgetCommon";
import {IWidgetSettingsAppearance} from "../settings/WidgetSettings";
import {WidgetType} from "../settings/WidgetType";
import {
    WidgetContainerDomValidationResult,
    WidgetEnumValidationResult,
    WidgetSettingsContainerValidationResult,
    WidgetValidationResult
} from "./WidgetValidationResult";
import {EnumValue, WidgetValidationMessages} from "./WidgetValidationMessages";

export class WidgetValidation {

    static validateInsertType(value: WidgetInsertType, type: WidgetType) {
        const validationResult = this.validateEnum(value, WidgetInsertType);
        if (validationResult === WidgetValidationResult.EnumError) {
            const insertKey: keyof IWidgetSettingsAppearance = "insert";
            this.catch(WidgetValidationMessages[WidgetValidationResult.EnumError](
                `"${insertKey}" field at widget type "${type}"`,
                value,
                WidgetInsertType
            ));
        }
        return validationResult;
    }

    static validateWidgetType(value: WidgetType) {
        const validationResult = this.validateEnum(value, WidgetType);
        if (validationResult === WidgetValidationResult.EnumError) {
            this.catch(WidgetValidationMessages[WidgetValidationResult.EnumError](
                "\"type\" field",
                value,
                WidgetType
            ));
        }
        return validationResult;
    }

    private static validateEnum<T>(value: EnumValue<T>, enumObject: T): WidgetEnumValidationResult {
        if (!value || typeof value !== "string")
            return WidgetValidationResult.EnumError;

        if (!Object.values(enumObject).some((enumValue: EnumValue<T>) => enumValue === value))
            return WidgetValidationResult.EnumError;

        return WidgetValidationResult.Ok;
    }

    static validateContainerDomElement(
        settingsContainer: IWidgetSettingsAppearance,
        type: WidgetType
    ): WidgetContainerDomValidationResult {

        let validationResult = WidgetValidationResult.Ok;
        let {container} = settingsContainer;

        const isVisible = (el: HTMLElement) => window.getComputedStyle(el).display !== "none";
        const isStringType = typeof container === "string";
        const isHtmlType = container instanceof HTMLElement;

        if ((!isStringType && !isHtmlType) || !container) {
            validationResult = WidgetValidationResult.ContainerIncorrectType;
        } else {
            container = WidgetSettingsNormalizer.normalizeContainer(container);
            if (!container) validationResult = WidgetValidationResult.ContainerNotFound;
            else if (!isVisible(container)) validationResult = WidgetValidationResult.ContainerIsHidden;
        }
        if (validationResult !== WidgetValidationResult.Ok) {
            this.catch(WidgetValidationMessages[validationResult](type));
        }
        return validationResult;
    }

    static validateSettingsContainer(
        container: IWidgetSettingsAppearance,
        type: WidgetType
    ): WidgetSettingsContainerValidationResult {
        if (!container) {
            const validationResult = WidgetValidationResult.ContainerIsEmpty;
            this.catch(WidgetValidationMessages[validationResult](type));
            return validationResult;
        }
        return WidgetValidationResult.Ok;
    }

    private static catch(message: string) {
        // error intended to a client, so there is no need for Sentry
        /* eslint-disable-next-line no-console */
        console.error(message);
    }
}
