import {DateFormat, DateHelper} from "@skbkontur/hotel-date";
import {useSelector} from "react-redux";
import {IHourlyObjectSearchParams} from "../../../data/SearchParams";
import {
    RoomCategorySearchParamsContext,
    HourlyObjectsSearchParamsContext,
} from "../../../providers/SearchParams/SearchParamsContext";
import {IAppState} from "../../../store/AppState";

interface IUseGetSearchParamsHourlyObjects {
    dateFromSearchParams: string;
    params: IHourlyObjectSearchParams;
    setParams: (action: (params: IHourlyObjectSearchParams) => IHourlyObjectSearchParams) => void
}

export const useGetSearchParamsHourlyObjects = (): IUseGetSearchParamsHourlyObjects => {
    const offsetInMinutes = useSelector((state: IAppState) => state.hotelInfo.info?.timeZone?.offsetInMinutes);

    const {params: paramsRoom} = React.useContext(RoomCategorySearchParamsContext);
    const {params, setParams} = React.useContext(HourlyObjectsSearchParamsContext);

    const {fromDate: fromDateFromParams, timeFrom} = params;

    const fromDate = fromDateFromParams || paramsRoom.fromDate;

    const dateFromSearchParams = timeFrom
        ? DateHelper.convertWithTimezone(timeFrom, {
            formatIn: DateFormat.UnixMsTimestamp,
            formatOut: DateFormat.FullDateDayFirst,
            offsetInMinutes
        })
        : fromDate;

    return {dateFromSearchParams, params, setParams};
};
