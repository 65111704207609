import {BookingOverbookingContext} from "../../../BookingOverbooking/BookingOverbookingContext";
import {AccommodationsContext} from "../../../Accommodations/AccommodationsContext";
import dailyApi from "../../../../api/dailyApi";
import {RoomCategorySearchParamsContext} from "../../../SearchParams/SearchParamsContext";
import {SelectedAccommodationsHelper} from "../../../Accommodations/helpers/SelectedAccommodationsHelper";
import {IAppState} from "../../../../store/AppState";
import {useDispatch, useSelector} from "react-redux";
import {BookingOverbookingHelper} from "./BookingOverbookingHelper";
import BookingOverbookingLightbox from "../../../BookingOverbooking/BookingOverbookingLightbox";
import {getAccommodations} from "../../../../store/accommodations/accommodationsActionCreators";
import {useWatchOverbooking} from "../../../BookingOverbooking/useWatchOverbooking";
import {useMemoObject} from "@skbkontur/hotel-hooks/memo";

interface IBookingOverbookingProviderState {
    isLightboxOpened: boolean;
    isOverbookingLoading: boolean;
}

const initialState: IBookingOverbookingProviderState = {
    isOverbookingLoading: false,
    isLightboxOpened: false
};

const BookingOverbookingProvider = (props: React.PropsWithChildren<object>) => {
    const {children} = props;

    const isLoadingRef = React.useRef<boolean>();

    isLoadingRef.current = useSelector((state: IAppState) => (
        state.accommodations.isLoading || state.booking.isLoading || state.payment.isLoading
    ));

    const {allAccommodations, selectedAccommodationsMap} = React.useContext(AccommodationsContext);
    const {params: searchParams} = React.useContext(RoomCategorySearchParamsContext);

    const [state, setState] = React.useState<IBookingOverbookingProviderState>(initialState);
    const {isOverbookingLoading, isLightboxOpened} = state;

    const dispatch = useDispatch();

    const isOverbooking = React.useCallback(async (): Promise<boolean> => {
        if (isLoadingRef.current) return false;

        setState(state => ({...state, isOverbookingLoading: true}));
        const newAllAccommodations = await dailyApi.getAccommodations(searchParams);

        const selectedAccommodations = SelectedAccommodationsHelper.toSelectedAccommodationBookings(selectedAccommodationsMap);
        const selectedParams = selectedAccommodations.map(sa => sa.params);
        const isOverbooking = BookingOverbookingHelper.isOverbooking(allAccommodations, newAllAccommodations, selectedParams);

        setState({
            isOverbookingLoading: false,
            isLightboxOpened: isOverbooking
        });

        return isOverbooking;
    }, [allAccommodations, selectedAccommodationsMap, searchParams]);

    const stopWatchOverbooking = useWatchOverbooking({
        isOverbooking,
        accommodation: allAccommodations,
        selectedAccommodation: selectedAccommodationsMap
    });

    const closeLightbox = () => setState(state => ({...state, isLightboxOpened: false}));

    const updateResults = async () => {
        /* eslint-disable @typescript-eslint/await-thenable */
        await dispatch(getAccommodations(searchParams));
        closeLightbox();
    };

    return (
        <BookingOverbookingContext.Provider value={useMemoObject({isOverbooking, isOverbookingLoading, stopWatchOverbooking})}>
            {children}
            {isLightboxOpened && (
                <BookingOverbookingLightbox
                    onClose={closeLightbox}
                    onUpdate={updateResults}
                />
            )}
        </BookingOverbookingContext.Provider>
    );
};
BookingOverbookingProvider.displayName = "BookingOverbookingProvider";
export default BookingOverbookingProvider;
