import {Guid} from "@skbkontur/hotel-data/guid";
import {IWidgetRefresh} from "./settings/WidgetCommon";
import {KnownAllWidgetSettings} from "./settings/WidgetInstanceSettings";
import {WidgetType} from "./settings/WidgetType";

export class WidgetClass<TSettings extends KnownAllWidgetSettings = KnownAllWidgetSettings> {
    readonly id: string;
    readonly ref = React.createRef<IWidgetRefresh>();

    constructor(readonly settings: TSettings) {
        this.id = WidgetClass.createWidgetId(this.settings.type);
    }

    get exist() {
        return !!this.ref.current;
    }

    update() {
        this.ref.current.refresh();
    }

    private static createWidgetId = (type: WidgetType): string => (
        `${type}_${Guid.getUuid(4, 16)}`
    );
}