import {themeAnalyticsEvents} from "../analytics/themeAnalyticsEvents";
import {WidgetClass} from "./Widget";
import {KnownAllWidgetSettings} from "./settings/WidgetInstanceSettings";
import {IWidgetSettings} from "./settings/WidgetSettings";

export default class WidgetStore {
    static settings: IWidgetSettings = null;
    static baseUrl: string = null;

    static getUrlWithBase = (url: string) => (
        WidgetStore.baseUrl
            ? WidgetStore.baseUrl + `/${url}`.replace(/\/+/g, "/")
            : url
    );

    static isWidget = () => !!WidgetStore?.settings?.hotelId;

    static addWidgetSettings = (settings: IWidgetSettings) => {
        this.settings = settings;

        const {theme, hotelId} = this.settings;
        if (this.isNotEmptyTheme(theme))
            themeAnalyticsEvents.useOnInit(hotelId, theme);
    };

    static addWidget = (widgetSettings: KnownAllWidgetSettings): string => {
        const widget = new WidgetClass(widgetSettings);
        this.settings.widgets.push(widget);

        const {theme} = widget.settings;
        if (this.isNotEmptyTheme(theme))
            themeAnalyticsEvents.useOnAdd(this.settings.hotelId, theme);

        return widget.id;
    };

    private static isNotEmptyTheme = (theme: object) => (
        !!theme && typeof theme === "object" && !!Object.keys(theme).length
    );

    static removeElement = (widgetId: string): WidgetClass[] => (
        this.settings.widgets = this.settings.widgets.filter(widget => widget.id !== widgetId)
    );

    static getWidgetById = (widgetId: string): WidgetClass | null => (
        this.settings.widgets.find(widget => widget.id === widgetId)
    );
}
