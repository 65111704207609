import {Button, ButtonProps} from "@skbkontur/react-ui";
import {IThemeElementButton} from "../../widget/theme/ThemeWidgets";
import {useThemeText} from "../../widget/theme/useThemeText";

interface IPrimaryButtonProps extends Omit<ButtonProps, "theme"> {
    theme?: IThemeElementButton;
}

const PrimaryButton = (props: React.PropsWithChildren<IPrimaryButtonProps>) => {
    const {children, className, theme, ...buttonProps} = props;
    const {width, text} = theme || {};

    const themeText = useThemeText(text);

    return (
        <Button
            className={className}
            use="pay"
            size="medium"
            {...buttonProps}
            width={width || buttonProps.width}
        >
            {themeText || children}
        </Button>
    );
};
PrimaryButton.displayName = "PrimaryButton";
export default PrimaryButton;
