import {IAccommodationRestrictionsRecommendations} from "../data/Accommodation";
import {BaseAnalyticsEvents} from "./BaseAnalyticsEvents";
import {AnalyticsCategory, BookingLightboxRecommendedAnalyticsAction} from "./Analytics";

class BookingLightboxRecommendedAnalyticsEvents extends BaseAnalyticsEvents {
    trackShowRecommendedList = (itemsCount: number) => (
        this.trackEvent(
            BookingLightboxRecommendedAnalyticsAction.ShowRecommendedList,
            "Показ списка рекомендованных номеров",
            {itemsCount}
        )
    );

    trackApplyRecommendedFilter = (recommendations: IAccommodationRestrictionsRecommendations) => (
        this.trackEvent(
            BookingLightboxRecommendedAnalyticsAction.ApplyRecommendedFilter,
            "Применение рекомендованного фильтра поиска номеров",
            {recommendations}
        )
    );
}

export const bookingLightboxRecommendedAnalyticsEvents = new BookingLightboxRecommendedAnalyticsEvents(
    AnalyticsCategory.BookingLightboxRecommended
);
