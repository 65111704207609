export interface IRoomCategoryLabelTheme {
    gap?: string;
    bg?: string;
    border?: string;
    boxShadow?: string;
    outline?: string;
    borderRadius?: string;
    fontSize?: string;
    lineHeight?: string;
    color?: string;
}

export const ROOM_CATEGORY_LABEL_THEME_PREFIX = "label";