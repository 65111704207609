import styles from "../Slider.scss";
import {ISliderImage} from "../types/SliderImage";
import Slide from "./Slide";

interface ISlideListProps {
    slideNumber: number;
    images: ISliderImage[];
    emptyImage?: string;
}

const SlidesList = (props: ISlideListProps) => {
    const {images, emptyImage, slideNumber} = props;

    return (
        <div
            className={styles.slideList}
            style={{transform: `translateX(-${slideNumber * 100}%)`}}
        >
            {images.length === 0
                ? <Slide url={emptyImage}/>
                : images.map(slide => <Slide key={slide.url} url={slide.url}/>)}
        </div>
    );
};

SlidesList.displayName = "SlidesList";
export default SlidesList;
