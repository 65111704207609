import {captureSentryError, SentryErrorType} from "@skbkontur/hotel-sentry";
import RoomAvailabilityCalendarWithFilter
    from "../../../components/RoomAvailabilityCalendarWithFilter/RoomAvailabilityCalendarWithFilter";
import {CalendarMode} from "../../../data/Calendar/Calendar";
import {withWidgetContainerAndRefHOC} from "../Widget/withWidgetContainerAndRefHOC";
import {WidgetClass} from "../../Widget";
import {useWidgetThemeContext} from "../WidgetTheme/WidgetThemeContext";
import {IThemeCalendar} from "../../theme/ThemeWidgets";
import {WidgetType} from "../../settings/WidgetType";

interface IWidgetCalendarProps {
    widget: WidgetClass;
}

const WidgetCalendar = (props: IWidgetCalendarProps) => {
    const {widget} = props;
    const {settings} = widget;

    const {inlayMode} = useWidgetThemeContext<IThemeCalendar>();

    const getCalendarMode = (): CalendarMode => {
        switch (settings.type) {
            case WidgetType.HorizontalAvailabilityCalendar:
                return CalendarMode.TwoMonths;
            case WidgetType.VerticalAvailabilityCalendar:
                return CalendarMode.OneMonth;
            case WidgetType.AvailabilityCalendar:
                return settings.months === 1 ? CalendarMode.OneMonth : CalendarMode.TwoMonths;
            default:
                captureSentryError(`Unexpected widget calendar type ${settings.type}`, SentryErrorType.SwitchCase);
        }
    };

    const getInlayMode = (): boolean => {
        switch (settings.type) {
            case WidgetType.HorizontalAvailabilityCalendar:
            case WidgetType.VerticalAvailabilityCalendar:
                return false;
            case WidgetType.AvailabilityCalendar:
                return !!inlayMode;
            default:
                captureSentryError(`Unexpected widget calendar type ${settings.type}`, SentryErrorType.SwitchCase);
        }
    };

    return (
        <RoomAvailabilityCalendarWithFilter
            calendarMode={getCalendarMode()}
            inlayMode={getInlayMode()}
        />
    );
};
WidgetCalendar.displayName = "WidgetCalendar";
export default withWidgetContainerAndRefHOC(WidgetCalendar);
