import {useUnmount} from "@skbkontur/hotel-hooks/react";
import {useMemoObject} from "@skbkontur/hotel-hooks/memo";
import {HourlyAccommodationsContext} from "../../../HourlyAccommodations/HourlyAccommodationsContext";
import {clearHourlyAccommodations} from "../../../../store/hourlyAccommodations/hourlyAccommodationsActionCreators";
import {HourlyBookingLightboxStep as HourlyBookingLightboxStep, HourlyBookingLightboxStepsContext, IHourlyBookingLightboxStepsParams,} from "./HourlyBookingLightboxStepsContext";

interface IHourlyBookingLightboxStepsState {
    step: HourlyBookingLightboxStep;
    params: IHourlyBookingLightboxStepsParams;
}

const initialState: IHourlyBookingLightboxStepsState = {
    step: HourlyBookingLightboxStep.Initial,
    params: null,
};

const emptyParams = {} as IHourlyBookingLightboxStepsParams;

const HourlyBookingLightboxStepsProvider = (props: React.PropsWithChildren<object>) => {
    const {children} = props;

    const {selectedAccommodation, accommodationPrices} = React.useContext(HourlyAccommodationsContext);

    const [state, setState] = React.useState<IHourlyBookingLightboxStepsState>(initialState);
    const {step, params} = state;

    const goToStep = React.useCallback((
        step: HourlyBookingLightboxStep,
        params?: IHourlyBookingLightboxStepsParams
    ) => {
        setState({step, params});
    }, [selectedAccommodation]);

    useUnmount(() => {
        clearHourlyAccommodations();
    });

    React.useEffect(() => {
        switch (true) {
            case accommodationPrices === null || accommodationPrices === undefined:
                goToStep(HourlyBookingLightboxStep.Initial);
                break;
            case !accommodationPrices.length:
                goToStep(HourlyBookingLightboxStep.NotAvailable);
                break;
            default:
                goToStep(HourlyBookingLightboxStep.SelectRates);
        }
    }, [accommodationPrices]);

    return (
        <HourlyBookingLightboxStepsContext.Provider
            value={useMemoObject({goToStep, step, params: params || emptyParams})}
        >
            {children}
        </HourlyBookingLightboxStepsContext.Provider>
    );
};
HourlyBookingLightboxStepsProvider.displayName = "HourlyBookingLightboxStepsProvider";
export default HourlyBookingLightboxStepsProvider;
