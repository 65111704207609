import {IconTypes, IIconProps} from "./Icon";
import {ToolPencilIcon16Light} from "@skbkontur/icons/ToolPencilIcon16Light";
import {ToolPencilIcon20Regular} from "@skbkontur/icons/ToolPencilIcon20Regular";

type EditIconTypes = IconTypes.Light16 | IconTypes.Regular20;

interface IEditIconProps extends IIconProps {
    type: EditIconTypes;
}

const EditIcon = ({type, ...iconProps}: IEditIconProps) => {
    switch (type) {
        case IconTypes.Light16:
            return <ToolPencilIcon16Light {...iconProps} />;
        case IconTypes.Regular20:
            return <ToolPencilIcon20Regular {...iconProps} />;
    }
};
EditIcon.displayName = "EditIcon";
export default EditIcon;