import {BaseAnalyticsEvents} from "./BaseAnalyticsEvents";
import {AnalyticsCategory, BookingLightboxRatesAnalyticsAction} from "./Analytics";

class BookingLightboxRatesAnalyticsEvents extends BaseAnalyticsEvents {
    trackGoToOccupancies = () => (
        this.trackEvent(
            BookingLightboxRatesAnalyticsAction.GoToOccupancies,
            "переход на этап размещений через кнопку \"Изменить вариант\""
        )
    );

    trackSelectRate = (ratesCount: number) => (
        this.trackEvent(
            BookingLightboxRatesAnalyticsAction.SelectRate,
            "выбор тарифа",
            {ratesCount}
        )
    );

    trackChangeRate = () => (
        this.trackEvent(
            BookingLightboxRatesAnalyticsAction.ChangeRate,
            "изменение тарифа (нажали на кнопку \"Изменить тариф\")"
        )
    );

    trackGoToPayment = () => (
        this.trackEvent(
            BookingLightboxRatesAnalyticsAction.GoToPayment,
            "переход к этапу оформления оплаты"
        )
    );
}

export const bookingLightboxRatesAnalyticsEvents = new BookingLightboxRatesAnalyticsEvents(AnalyticsCategory.BookingLightboxRates);
