import {IconTypes, IIconProps} from "./Icon";
import {ArrowFigureACircleDownIcon32Regular} from "@skbkontur/icons/ArrowFigureACircleDownIcon32Regular";

type ArrowDownIconTypes = IconTypes.Regular32;

interface IArrowFigureCircleDownIconProps extends IIconProps {
    type: ArrowDownIconTypes;
}

const ArrowFigureCircleDownIcon = ({type, ...iconProps}: IArrowFigureCircleDownIconProps) => {
    switch (type) {
        case IconTypes.Regular32:
            return <ArrowFigureACircleDownIcon32Regular {...iconProps} />;
    }
};
ArrowFigureCircleDownIcon.displayName = "ArrowFigureCircleDownIcon";
export default ArrowFigureCircleDownIcon;
