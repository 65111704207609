import {IBookingPaymentInfo} from "../../../data/Booking";
import {IHourlyAccommodation, IHourlyBookingAccommodation} from "../../../data/HourlyBooking";
import {AccommodationsRatesMapType, IAccommodationsPricesMap} from "../HourlyAccommodationsMaps";
import {Currency} from "@skbkontur/hotel-data/currency";

export class HourlyAccommodationsPricesHelper {
    static createHourlyPricesMap = (
        hourlyObjectsAccommodation: IHourlyAccommodation[]
    ): IAccommodationsPricesMap => (
        hourlyObjectsAccommodation?.reduce((acc, item) => {
            const {hourlyObjectId, accommodations} = item;
            return {
                ...acc,
                [hourlyObjectId]: accommodations.reduce((acc, item) => {
                    const {rateId} = item;
                    return {
                        ...acc,
                        [rateId]: item,
                    };
                }, {} as AccommodationsRatesMapType),
            };
        }, {} as IAccommodationsPricesMap) || {}
    );

    static getHourlyBookingPrice = (
        booking: IHourlyBookingAccommodation,
        pricesMap: IAccommodationsPricesMap
    ): IBookingPaymentInfo => {
        const {hourlyObjectId, hourlyRateId} = booking;

        const ratesPrices = pricesMap[hourlyObjectId];
        const {price, prepaymentSum} = ratesPrices[hourlyRateId];

        return {
            price: Currency.getFloatValue(price),
            prepayment: Currency.getFloatValue(prepaymentSum),
        };
    };
}
