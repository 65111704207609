import {HotelDate, hotelDate, memoizedHotelDate} from "../hotelDate";
import {ConfigDateType, Inclusivity, UnitOfTime} from "./Date";
import {DateFormat} from "./DateFormat";
import {ICompareArgs} from "./DateInterface";

interface IIsBetweenArgs extends ICompareArgs {
    date: string;
    inclusivity?: Inclusivity;
}

export class DateCompare {
    static isBeforeDateByDay = (
        firstDate: ConfigDateType,
        secondDate: ConfigDateType,
        format: DateFormat = DateFormat.FullDateDayFirst
    ): boolean => (
        hotelDate(firstDate, format).isBefore(hotelDate(secondDate, format), UnitOfTime.Day)
    );

    static isToday = (date: string, dateFormat: DateFormat): boolean => {
        const dateFormatted = hotelDate(date, dateFormat);
        return hotelDate().isSame(dateFormatted, UnitOfTime.Day);
    };

    static isBirthdayToday = (birthday: string): boolean => {
        const birthdayDayjsWithCurrentYear = hotelDate(birthday, DateFormat.FullDateYearFirstWithTime)
            .set(UnitOfTime.Year, hotelDate().get(UnitOfTime.Year))
            .format(DateFormat.FullDateYearFirstWithTime);
        return DateCompare.isToday(birthdayDayjsWithCurrentYear, DateFormat.FullDateYearFirstWithTime);
    };

    static isNowBefore = (date: string, dateFormat: DateFormat): boolean => {
        const formattedDate = hotelDate().format(dateFormat);
        return DateCompare.isFirstBeforeSecond(formattedDate, date, dateFormat, UnitOfTime.Day);
    };

    static isFirstBeforeSecond = (
        date: string,
        otherDate: string,
        dateFormat: DateFormat = DateFormat.FullDateDayFirst,
        startOf?: UnitOfTime
    ): boolean => {
        if (startOf) {
            const dateStartOf = memoizedHotelDate(date, dateFormat).startOf(startOf);
            const otherDateStartOf = memoizedHotelDate(otherDate, dateFormat).startOf(startOf);
            return dateStartOf.isBefore(otherDateStartOf);
        }
        return memoizedHotelDate(date, dateFormat).isBefore(memoizedHotelDate(otherDate, dateFormat));
    };

    static isFirstAfterSecond = (
        date: string,
        otherDate: string,
        dateFormat: DateFormat = DateFormat.FullDateDayFirst,
        startOf?: UnitOfTime
    ): boolean => {
        if (startOf) {
            const dateStartOf = memoizedHotelDate(date, dateFormat).startOf(startOf);
            const otherDateStartOf = memoizedHotelDate(otherDate, dateFormat).startOf(startOf);
            return dateStartOf.isAfter(otherDateStartOf);
        }
        return memoizedHotelDate(date, dateFormat).isAfter(memoizedHotelDate(otherDate, dateFormat));
    };
    static isFirstSameOrAfterSecond = (
        date: string,
        otherDate: string,
        dateFormat: DateFormat = DateFormat.FullDateDayFirst,
        startOf?: UnitOfTime
    ): boolean => {
        if (startOf) {
            const dateStartOf = memoizedHotelDate(date, dateFormat).startOf(startOf);
            const otherDateStartOf = memoizedHotelDate(otherDate, dateFormat).startOf(startOf);
            return dateStartOf.isSameOrAfter(otherDateStartOf);
        }
        return memoizedHotelDate(date, dateFormat).isSameOrAfter(memoizedHotelDate(otherDate, dateFormat));
    };

    static isFirstSameOrBeforeSecond = (
        date: string,
        otherDate: string,
        format: DateFormat = DateFormat.FullDateDayFirst,
        startOf?: UnitOfTime
    ): boolean => {
        if (startOf) {
            const dateStartOf = memoizedHotelDate(date, format).startOf(startOf);
            const otherDateStartOf = memoizedHotelDate(otherDate, format).startOf(startOf);
            return dateStartOf.isSameOrBefore(otherDateStartOf);
        }
        return memoizedHotelDate(date, format).isSameOrBefore(memoizedHotelDate(otherDate, format));
    };

    static isBetween = (args: IIsBetweenArgs): boolean => {
        const {
            date,
            firstDate,
            secondDate,
            format = DateFormat.FullDateDayFirst,
            inclusivity = Inclusivity.WithBorder,
            unitOfTime = UnitOfTime.Day,
        } = args;

        if (!firstDate || !secondDate || !date) return false;

        const dayjsDate: HotelDate = memoizedHotelDate(date, format);

        const startDateDayjs = hotelDate(firstDate, format).startOf(UnitOfTime.Day);
        const endDateDayjs = hotelDate(secondDate, format).endOf(UnitOfTime.Day);

        return dayjsDate.isBetween(startDateDayjs, endDateDayjs, unitOfTime, inclusivity);
    };

    static isAfterNow = (date: string, format: DateFormat): boolean => {
        const nowDate = hotelDate().format(format);
        return DateCompare.isFirstAfterSecond(date, nowDate, format);
    };

    static isSameOrBefore = (args: ICompareArgs): boolean => {
        const {firstDate, secondDate, format, unitOfTime} = args;
        return hotelDate(firstDate, format).isSameOrBefore(hotelDate(secondDate, format), unitOfTime);
    };

    static isSame = (args: ICompareArgs): boolean => {
        const {firstDate, secondDate, format, unitOfTime} = args;
        return hotelDate(firstDate, format).isSame(hotelDate(secondDate, format), unitOfTime);
    };

    static isBefore = (args: ICompareArgs): boolean => {
        const {firstDate, secondDate, format, unitOfTime} = args;
        return hotelDate(firstDate, format).isBefore(hotelDate(secondDate, format), unitOfTime);
    };

    static isWeekend = (date: string | number, isUTC = false): boolean => {
        const parsedDate = new Date(date);
        let day = parsedDate.getDay();

        if (isUTC) {
            day = parsedDate.getUTCDay();
        }

        return day === 6 || day === 0;
    };
}

