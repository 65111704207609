import FixedButton from "../../../components/FixedButton/FixedButton";
import {DeviceContext} from "../../../providers/Device/DeviceContext";
import {createWidgetThemeContext} from "../WidgetTheme/WidgetThemeContext";
import {IThemeAvailabilityButtonForMobileDevices} from "../../theme/ThemeWidgets";
import WidgetThemeProvider from "../WidgetTheme/WidgetThemeProvider";
import {WidgetClass} from "../../Widget";
import {usePortalRender} from "../../../hooks/usePortalRender";

import {IWidgetAvailabilityButtonForMobileDevices} from "../../settings/WidgetInstanceSettings";

interface IWidgetAvailabilityButtonForMobileDevicesProps {
    widget: WidgetClass<IWidgetAvailabilityButtonForMobileDevices>;
}

const WidgetAvailabilityButtonForMobileDevices = (props: IWidgetAvailabilityButtonForMobileDevicesProps) => {
    const {widget} = props;
    const {settings} = widget;
    const {type, theme} = settings;

    const {isMobileMode} = React.useContext(DeviceContext);
    const portalRender = usePortalRender();

    if (!isMobileMode) {
        return null;
    }

    const WidgetThemeContext = createWidgetThemeContext<IThemeAvailabilityButtonForMobileDevices>();

    return portalRender(
        <WidgetThemeProvider type={type} widgetTheme={theme}>
            <WidgetThemeContext.Consumer>
                {({checkButton}) => (
                    <FixedButton buttonTheme={checkButton}/>
                )}
            </WidgetThemeContext.Consumer>
        </WidgetThemeProvider>
    );
};
WidgetAvailabilityButtonForMobileDevices.displayName = "WidgetAvailabilityButtonForMobileDevices";
export default WidgetAvailabilityButtonForMobileDevices;
