import {captureSentryError, SentryErrorType} from "@skbkontur/hotel-sentry";
import {DateType, InvalidDate} from "./date/Date";
import {DateFormat} from "./date/DateFormat";
import {memoize} from "@skbkontur/hotel-utils";
import _dayjs from "./dayjsConfiguration";
import {Dayjs} from "dayjs";

export type KnownDateType = DateType | number;
export type HotelDate = Dayjs;

const getNumberRegexFromFormat = (format: DateFormat): RegExp => {
    // this format is just the number
    if (format === DateFormat.UnixMsTimestamp)
        return /^[0-9]+$/;
    // format with the timezone not matches the exact same structure of the date
    if (format === DateFormat.FullDateYearFirstWithTimeWithSecondsWithTimeZone)
        return /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d\+\d\d:\d\d+$/;
    // replace all literals to \d except of T
    return new RegExp(`^${format?.replace(/(?!T)[a-zA-Z]/g, "\\d")?.replace(".", "\\.")}+$`);
};

const numberRegexByFormatMap: Record<string, RegExp> = Object.values(DateFormat).reduce((acc, format) => (
    {...acc, [format]: getNumberRegexFromFormat(format)}
), {});

const getDayjsMessage = {
    missingFormat: (date: KnownDateType) => (
        `Dayjs: Missing format parameter at dayjs(...) constructor for date «${String(date)}»`
    ),
    wrongFormat: (date: string | number, format: DateFormat) => (
        `Dayjs: Wrong format «${format}» for date «${date}», please use appropriate format`
    ),
    formatNotFound: (date: string | number, format: DateFormat) => (
        `Dayjs: Format «${format}» for date «${date}» not found in format's list`
    ),
    criticalError: (date: KnownDateType, format: DateFormat, error: unknown) => (
        `Dayjs: Some critical error appears for date «${String(date)}» with format «${format}»:\n${JSON.stringify(error)}`
    )
};

type DayjsType = typeof _dayjs;

export const hotelDate = Object.assign((date?: KnownDateType, format?: DateFormat, locale?: string, strict?: boolean) => {
    const captureError = (error: string) => (
        captureSentryError({error, date, format, locale, strict}, SentryErrorType.Dayjs)
    );
    if (!!date && !format) {
        captureError(getDayjsMessage.missingFormat(date));
    }
    try {
        const dayjsInstance = _dayjs(date, format, locale, strict);
        const isNeedFormatCheck = typeof date === "string" || typeof date === "number";
        if (isNeedFormatCheck) {
            const regexFormat = numberRegexByFormatMap[format];
            if (regexFormat) {
                const doesDateMatchFormat = regexFormat?.test(String(date));
                if (!doesDateMatchFormat && !dayjsInstance.isValid()) {
                    captureError(getDayjsMessage.wrongFormat(date, format));
                }
            } else {
                captureError(getDayjsMessage.formatNotFound(date, format));
            }

        }
        return dayjsInstance;
    } catch (error) {
        captureError(getDayjsMessage.criticalError(date, format, error));
        return InvalidDate;
    }
}, _dayjs) as DayjsType;

export let memoizedHotelDate: typeof hotelDate;
export let memoizedUTCHotelDate: typeof hotelDate.utc;

export const updateMemoizedHotelDate = () => {
    memoizedHotelDate = memoize(hotelDate, {
        allowObjectArgs: true
    }) as typeof hotelDate;
    memoizedUTCHotelDate = memoize(hotelDate.utc, {
        allowObjectArgs: true
    });
};
updateMemoizedHotelDate();

export const memoizedFormatHotelDate = (date: string, formatFrom: DateFormat, formatTo: DateFormat): string => (
    memoizedHotelDate(date, formatFrom).format(formatTo)
);
