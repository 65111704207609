/**
 * Тип вставки виджета в DOM-контейнер
 */
export enum WidgetInsertType {
    /**
     * Вставка виджета перед контентом внутри контейнера
     */
    Before = "before",
    /**
     * Вставка виджета после контента внутри контейнера
     */
    After = "after",
    /**
     * Вставка виджета вместо контента внутри контейнера
     */
    Replace = "replace"
}

export interface IWidgetRefresh {
    refresh: () => void;
}

/**
 * ID контейнера или ссылка на DOM-элемент для встраивания виджета
 */
export type WidgetContainer = string | HTMLElement;