// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OkQHSv{display:-webkit-box;white-space:normal;-webkit-line-clamp:1;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./../packages/components/ellipsis/Ellipsis.scss"],"names":[],"mappings":"AAAA,QAEI,mBAAA,CADA,kBAAA,CAEA,oBAAA,CACA,2BAAA,CACA,eAAA,CACA,sBAAA","sourcesContent":[".root {\r\n    white-space: normal;\r\n    display: -webkit-box;\r\n    -webkit-line-clamp: 1;\r\n    -webkit-box-orient: vertical;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "OkQHSv"
};
export default ___CSS_LOADER_EXPORT___;
