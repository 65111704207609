import styles from "./EntityCard.scss";

interface IEntityCardTitleProps {
    title: React.ReactNode;
    secondTitle?: React.ReactNode;
}

const EntityCardTitle = (props: IEntityCardTitleProps) => {
    const {title, secondTitle} = props;

    return (
        <div>
            <div className={styles.title}>{title}</div>
            {secondTitle && <div className={styles.secondTitle}>{secondTitle}</div>}
        </div>
    );
};
EntityCardTitle.displayName = "EntityCardTitle";
export default EntityCardTitle;
