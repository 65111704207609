import {CalendarMode} from "../../../data/Calendar/Calendar";

export interface ICalendarContext {
    calendarMode: CalendarMode;
    monthsToDisplay: 1 | 2;
    loading: boolean;
    setLoading: (a: React.SetStateAction<boolean>) => void;
}

export const CalendarContext = React.createContext({} as ICalendarContext);
