import {ButtonUse} from "@skbkontur/react-ui/cjs/components/Button/Button";
import {LIGHT_THEME, ThemeFactory} from "@skbkontur/react-ui";
import {Colors} from "@skbkontur/hotel-styles";
import {IThemeButtons} from "./ThemeCommon";

export const BM_THEME_BUTTONS_MAP: Record<keyof IThemeButtons, ButtonUse> = {
    primary: "pay",
    secondary: "default"
};

export const BM_THEME_REACT_UI_SIZE_PROPS = [
    "btnBorderRadius",
    "inputBorderRadius",
    "selectBorderRadius"
];

export const BM_THEME_CSS_PREFIX = "bm-theme";

// This is RegExp of keys used in typescript (not CSS keys)
export const BM_THEME_EXCLUDE_KEYS = /(^.*text$)|(^.*Button$)|(^.*Input$)/i;

export const BM_DEFAULT_REACT_UI_THEME = ThemeFactory.create({
    linkColor: Colors.fontBlueColor,
    linkHoverColor: Colors.fontBlueColor,
    linkLineBorderBottomStyle: "none",
    linkLineHoverBorderBottomStyle: "solid",
    btnLinkHoverTextDecoration: "underline",
    dateSelectLinkColor: Colors.fontBlackColor
}, LIGHT_THEME);