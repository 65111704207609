import {useTranslation} from "@skbkontur/i18n";
import {DateCalculate, DateFormat, UnitOfTime} from "@skbkontur/hotel-date";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";

interface IRateConditionsFreeCancellationProps {
    freeCancellationHoursCount: number;
    checkin: string;
}

export const RateConditionsFreeCancellation = (props: IRateConditionsFreeCancellationProps) => {
    const {freeCancellationHoursCount, checkin} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingConfirmation);

    const dateTime = freeCancellationHoursCount
        ? DateCalculate.add({
            amount: -freeCancellationHoursCount,
            date: checkin,
            format: DateFormat.FullDateDayFirstWithTimeWithColons,
            unitOfTime: UnitOfTime.Hour
        })
        : checkin;

    return (
        <div data-tid="cancellationPolicy">
            {t("cancellationPolicy", {dateTime, raw: true})}
        </div>
    );
};
RateConditionsFreeCancellation.displayName = "BookingConditionsFreeCancellation";
