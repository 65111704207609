import {HotelDate, KnownDateType, hotelDate, memoizedHotelDate} from "../hotelDate";
import {Inclusivity, UnitOfTime} from "./Date";
import {DateFormat} from "./DateFormat";
import {DateHelper} from "./DateHelper";
import {DateMs} from "./DateMs";

interface ISetRangeCacheArgs {
    startDate: KnownDateType;
    endDate: KnownDateType;
    format: DateFormat;
    unitOfTime?: UnitOfTime;
}

interface IGetDatesRangeArgs {
    fromDate: string;
    toDate: string;
    format: DateFormat;
    rangeBy?: UnitOfTime;
    removeLast?: boolean;
}

interface IGetCachedDatesRangeArgs extends ISetRangeCacheArgs {
    inclusivity?: Inclusivity;
}

type IRangeDatesCacheType = Record<string, Record<string, string[]>>;

export class DateRange {
    static inRange = (
        date: string,
        startDate: string,
        endDate: string,
        dateFormat: DateFormat = DateFormat.FullDateDayFirst,
        bordersFormat: DateFormat = DateFormat.FullDateYearFirstWithDashes,
        inclusivity: Inclusivity = Inclusivity.WithBorder
    ): boolean => {
        if (!startDate || !endDate || !date) return false;

        const dayjsDate: HotelDate = memoizedHotelDate(date, dateFormat);

        const startDateStartOfDay = hotelDate(startDate, bordersFormat).startOf(UnitOfTime.Day);
        const endDateStartOtDay = hotelDate(endDate, bordersFormat).startOf(UnitOfTime.Day);

        return dayjsDate.isBetween(startDateStartOfDay, endDateStartOtDay, UnitOfTime.Day, inclusivity);
    };

    static isBirthdayInRange = (birthday: string, startDate: string, endDate: string): boolean => {
        if (!startDate || !endDate || !birthday) return false;
        const birthdayWithCurrentYear = hotelDate(birthday, DateFormat.FullDateYearFirstWithTime)
            .set(UnitOfTime.Year, memoizedHotelDate(startDate, DateFormat.FullDateYearFirstWithTime).get(UnitOfTime.Year))
            .format(DateFormat.FullDateDayFirst);

        return DateRange.inRange(birthdayWithCurrentYear, startDate, endDate);
    };

    private static rangeDatesCache: IRangeDatesCacheType = {};

    static setRangeByDayCache(args: ISetRangeCacheArgs) {
        const {
            format,
            unitOfTime = UnitOfTime.Day
        } = args;

        let {startDate, endDate} = args;

        startDate = DateHelper.startOf({date: startDate, startOf: unitOfTime, format}).format(format);
        endDate = DateHelper.startOf({date: endDate, startOf: unitOfTime, format}).format(format);

        DateRange.rangeDatesCache[unitOfTime] = DateRange.rangeDatesCache[unitOfTime] || {};

        const formattedDates = DateRange.rangeDatesCache[unitOfTime][format] = DateRange.rangeDatesCache[unitOfTime][format] || [];

        const firstDateIndex = formattedDates.indexOf(startDate);
        const lastDateIndex = formattedDates.indexOf(endDate);

        if (firstDateIndex === -1 || lastDateIndex === -1) {
            const firstDate = firstDateIndex > -1
                ? memoizedHotelDate(formattedDates[0], format)
                : memoizedHotelDate(startDate, format);

            const lastDate = lastDateIndex > -1
                ? memoizedHotelDate(formattedDates[formattedDates.length - 1], format)
                : memoizedHotelDate(endDate, format);

            DateRange.rangeDatesCache[unitOfTime][format] = DateRange.getDatesRange({
                fromDate: firstDate.isValid() ? firstDate?.format(format) : null,
                toDate: lastDate.isValid() ? lastDate?.format(format) : null,
                rangeBy: unitOfTime,
                format
            });
        }
    }

    static getCachedDatesRange(args: IGetCachedDatesRangeArgs): string[] {
        const {
            format,
            inclusivity = Inclusivity.WithBorder,
            unitOfTime = UnitOfTime.Day
        } = args;

        let {startDate, endDate} = args;

        DateRange.setRangeByDayCache({startDate, endDate, format, unitOfTime});

        startDate = DateHelper.startOf({date: startDate, startOf: unitOfTime, format}).format(format);
        endDate = DateHelper.startOf({date: endDate, startOf: unitOfTime, format}).format(format);

        let firstDateIndex = DateRange.rangeDatesCache[unitOfTime][format].indexOf(startDate);
        let lastDateIndex = DateRange.rangeDatesCache[unitOfTime][format].indexOf(endDate);

        switch (inclusivity) {
            case Inclusivity.OnlyRightBorder:
                firstDateIndex = Math.min(firstDateIndex + 1, lastDateIndex);
                break;
            case Inclusivity.OnlyLeftBorder:
                lastDateIndex = Math.max(firstDateIndex, lastDateIndex - 1);
                break;
            case Inclusivity.WithoutBorder:
                firstDateIndex = Math.min(firstDateIndex + 1, lastDateIndex);
                lastDateIndex = Math.max(firstDateIndex, lastDateIndex - 1);
                break;
        }

        return DateRange.rangeDatesCache[unitOfTime][format].slice(firstDateIndex, lastDateIndex + 1);
    }

    // TODO @mozalov: при переписывании отчетов вместо этого попробовать заюзать getCachedDatesRange
    static getDatesRange(args: IGetDatesRangeArgs): string[] {
        const {
            fromDate,
            toDate,
            format,
            rangeBy = UnitOfTime.Day,
            removeLast
        } = args;
    
        if (!fromDate && !toDate) return [];
        if (!fromDate) return [toDate];
        if (!toDate) return [fromDate];
        if (fromDate === toDate) return [fromDate];
        const toDateDayjs = memoizedHotelDate(toDate, format);
        const fromDateDayjs = memoizedHotelDate(fromDate, format);
        if (toDateDayjs.isBefore(fromDateDayjs)) {
            return [];
        }
    
        const fromDateInMs = fromDateDayjs.valueOf();
        const toDateInMs = toDateDayjs.valueOf();
        const datesRange = DateMs
            .range(fromDateInMs, toDateInMs, rangeBy)
            .map(ms => hotelDate(ms, DateFormat.UnixMsTimestamp).format(format));
    
        if (removeLast) datesRange.pop();
        return datesRange;
    }
}
