import {Middleware} from "redux";
import {AsyncActionFn, IAsyncAction} from "./ThunkAction";

export interface IAsyncActionMiddlewareSuccess {
    type: string;
    response: unknown;
    [key: string]: unknown;
}

export interface IAsyncActionMiddlewareError {
    type: string;
    error: unknown;
    [key: string]: unknown;
}

const createAsyncActionMiddleware = (): Middleware => (
    () => next => async (action: IAsyncAction) => {
        const {asyncAction, type, callback, onError, ...rest} = action;

        // Если это обычный action - пропустить дальше
        if (!asyncAction) {
            return next(action);
        }

        const {REQUEST, SUCCESS, FAILURE} = type;

        next({...rest, type: REQUEST});

        try {
            const response = (asyncAction as Promise<unknown>).then
                ? await asyncAction
                : await (asyncAction as AsyncActionFn<unknown>)();

            const result = next({...rest, response, type: SUCCESS});

            if (callback && typeof callback === "function") {
                callback(response);
            }

            return result as IAsyncActionMiddlewareSuccess;
        } catch (error) {
            onError && onError(error);
            return next({...rest, error, type: FAILURE}) as IAsyncActionMiddlewareError;
        }
    }
);

export default createAsyncActionMiddleware();
