import cn from "classnames";
import {Sticky} from "@skbkontur/react-ui";
import raf from "raf-throttle";
import {BookingLightboxHelper} from "../BookingLightboxHelper";
import styles from "./BookingLightboxPanel.scss";

interface IBookingLightboxPanelProps {
    isShown: boolean;
    isStatic?: boolean;
}

const BookingLightboxPanel = (props: React.PropsWithChildren<IBookingLightboxPanelProps>) => {
    const {isShown, isStatic, children} = props;

    const panelRef = React.useRef<HTMLDivElement>();

    React.useEffect(() => {
        if (isStatic) {
            panelRef.current.classList.remove(styles.fixed);
        } else {
            const scrollContainer = BookingLightboxHelper.getScrollElement();
            const onScroll = raf(() => {
                const panel = panelRef.current;
                const isFixed = panel.classList.contains(styles.fixed);
                if (panel.offsetTop === 0) {
                    panel.classList.add(styles.fixed);
                } else if (isFixed) {
                    panel.classList.remove(styles.fixed);
                }
            });
            onScroll();
            scrollContainer.addEventListener("scroll", onScroll);
            return () => scrollContainer.removeEventListener("scroll", onScroll);
        }
    }, [isShown, isStatic]);

    const panel = (
        <div ref={panelRef} className={cn(styles.panel, {[styles.showed]: isShown})}>
            {children}
        </div>
    );

    return isStatic
        ? panel
        : (
            <Sticky side="bottom" offset={0}>
                {panel}
            </Sticky>
        );
};
BookingLightboxPanel.displayName = "BookingLightboxPanel";
export default BookingLightboxPanel;
