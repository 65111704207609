import {useSelector} from "react-redux";
import {IHourlyObject} from "../data/HourlyObjects";
import {IAppState} from "../store/AppState";

interface IWithIsDeleted {
    isDeleted?: boolean;
}

const getNotDeletedItems = <TItem extends IWithIsDeleted>(items: TItem[]): TItem[] => (
    (items || []).filter(item => !item.isDeleted)
);

export const useNotDeletedHourlyObjects = (): IHourlyObject[] => {
    const {hourlyObjects} = useSelector((state: IAppState) => state.hourlyObjects);

    return React.useMemo(() => (
        getNotDeletedItems(hourlyObjects).map(hourlyObjects => ({
            ...hourlyObjects,
            items: getNotDeletedItems(hourlyObjects.items)
        }))
    ), [hourlyObjects]);
};
