import {useTranslation} from "@skbkontur/i18n";
import {DelayLoader} from "@skbkontur/hotel-components/delayLoader";
import {DateCalculate, DateFormat, DateCompare} from "@skbkontur/hotel-date";
import CalendarDay from "../Day/CalendarDay";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {CalendarDayOfWeek, CalendarMonthDataMapType, CalendarMonthNames} from "../../../data/Calendar/Calendar";
import {CalendarHelper, ICalendarDayBorderRadiusConfig} from "../../../data/Calendar/CalendarHelper";
import {CalendarContext} from "../Provider/CalendarContext";
import styles from "./CalendarMonth.scss";

const getDayOfWeekByIndex = (index: number): string => CalendarDayOfWeek[index % 7];

interface ICalendarMonthProps {
    monthNumberFromZero: number;
    year: number;
    width: number;
    isSingleMonth: boolean;
    offsetInMinutes?: number;
    monthData?: CalendarMonthDataMapType;
}

const CalendarMonth = (props: ICalendarMonthProps) => {
    const {
        monthNumberFromZero: month,
        year,
        width,
        monthData,
        isSingleMonth,
        offsetInMinutes
    } = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleCalendar);
    const {loading} = React.useContext(CalendarContext);

    const {minPrice, maxPrice} = React.useMemo(() => (
        CalendarHelper.getMinMaxMonthPrices(monthData)
    ), [monthData]);

    const daysWithSpaces = React.useMemo(() => (
        CalendarHelper.getDaysInMonthArrayWithSpaces(year, month)
    ), [year, month]);

    const daysDisabledMap = React.useMemo(() => {
        const today = DateCalculate.getTodayWithTimezone(DateFormat.FullDateYearFirst, offsetInMinutes);
        return daysWithSpaces.reduce((acc: Record<number, boolean>, day: number) => {
            const date = DateCalculate.getDate(year, month, day, DateFormat.FullDateYearFirst);
            acc[day] = DateCompare.isBeforeDateByDay(date, today, DateFormat.FullDateYearFirst);
            return acc;
        }, {} as Record<number, boolean>);
    }, [daysWithSpaces]);

    const dayBorderRadiusConfigMap = React.useMemo(() => (
        daysWithSpaces.reduce((acc: Record<number, ICalendarDayBorderRadiusConfig>, day: number, index: number) => {
            acc[day] = CalendarHelper.getDayBorderRadiusConfig(monthData, day, index, daysDisabledMap);
            return acc;
        }, {} as Record<number, ICalendarDayBorderRadiusConfig>)
    ), [monthData, daysWithSpaces, daysDisabledMap]);

    return (
        <section className={styles.month} style={{width}}>
            <div className={styles.monthTitle}>
                {t(`months.${CalendarMonthNames[month]}`)}
            </div>
            <DelayLoader active={loading && !monthData}>
                <div className={styles.monthGrid}>
                    {daysWithSpaces.map((day, index) => {
                        const dayData = monthData?.[day];
                        const dayPrice = dayData?.price;
                        return (
                            <CalendarDay
                                day={day}
                                data={dayData}
                                dayOfWeek={t(`shortDaysOfWeek.${getDayOfWeekByIndex(index)}`)}
                                key={`${getDayOfWeekByIndex(index)}-${day}-${month}`}
                                small={isSingleMonth}
                                disabled={!day || daysDisabledMap[day]}
                                isMin={minPrice !== null && minPrice === dayPrice}
                                isMax={maxPrice !== null && maxPrice === dayPrice}
                                borderRadiusConfig={dayBorderRadiusConfigMap[day]}
                            />
                        );
                    })}
                </div>
            </DelayLoader>
        </section>
    );
};
CalendarMonth.displayName = "CalendarMonth";
export default React.memo(CalendarMonth);
