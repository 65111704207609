import {Reducer} from "redux";
import {IBookingPromoCodeApplyResultInfo} from "../../data/PromoCode";
import {KnownPromoCodeActions, PromoCodeActionTypes} from "./promoCodeActionCreators";

export interface IPromoCodeState {
    isLoading: boolean;
    error?: Error;
    applyResult: IBookingPromoCodeApplyResultInfo;
    isAvailable: boolean;
}

const initialState = {
    isLoading: false,
    error: null,
    isAvailable: false,
    applyResult: null
} as IPromoCodeState;

export const promoCodeReducer: Reducer<IPromoCodeState> = (
    state: IPromoCodeState = initialState,
    action: KnownPromoCodeActions
) => {
    switch (action.type) {
        case PromoCodeActionTypes.GET_IS_ANY_AVAILABLE_REQUEST:
        case PromoCodeActionTypes.CHECK_REQUEST:
        case PromoCodeActionTypes.CLEAR_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case PromoCodeActionTypes.GET_IS_ANY_AVAILABLE_ERROR:
        case PromoCodeActionTypes.CLEAR_ERROR:
        case PromoCodeActionTypes.CHECK_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.error,
                isAnyPromoCodeAvailable: true
            };
        case PromoCodeActionTypes.GET_IS_ANY_AVAILABLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAvailable: action.response
            };
        case PromoCodeActionTypes.CHECK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                applyResult: action.response
            };
        case PromoCodeActionTypes.CLEAR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                applyResult: null
            };
        default:
            return state;
    }
};
