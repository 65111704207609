import cn from "classnames";
import color from "color";
import {useRef} from "@skbkontur/hotel-hooks/react";
import {Loader} from "@skbkontur/react-ui";
import {useHistory} from "react-router";
import {Colors} from "@skbkontur/hotel-styles";
import {IWithClassName} from "@skbkontur/hotel-data/compositions";
import styles from "./RoundButton.scss";

interface IRoundButtonProps extends IWithClassName {
    icon: React.ReactElement;
    onClick?: (event?: React.MouseEvent) => void;
    href?: string;
    backgroundColor?: string;
    backgroundAlways?: boolean;
    fontColor?: string;
    disabled?: boolean;
    dataTID?: string;
    loading?: boolean;
    clickable?: boolean;
}

// TODO после добавления возможности кастомизации svg (https://yt.skbkontur.ru/issue/FMS-5782 ) объеденить с SvgRoundButton
const RoundButton = (props: IRoundButtonProps) => {
    const {
        icon,
        onClick,
        backgroundColor = "#fff",
        backgroundAlways = false,
        loading,
        dataTID,
        href,
        fontColor,
        className: rootClassName,
        clickable
    } = props;

    let {disabled} = props;
    disabled = loading || disabled;

    const contentClassName = cn(styles.content, {
        [styles.disabled]: disabled
    });

    const className = cn(styles.root, rootClassName, {
        [styles.clickable]: !disabled && (!!href || !!onClick || clickable)
    });

    const [rootRef, setRootRef] = useRef<HTMLDivElement>();
    const history = useHistory();

    const bgColor = color(backgroundColor)
        .darken(0.08)
        .toString();

    const hoverBackground = backgroundAlways ? (
        color(bgColor)
            .darken(0.1)
            .toString()
    ) : bgColor;

    const finalFontColor = fontColor || color(Colors.fontGrayColor)
        .alpha(0.8)
        .toString();

    const disabledFontColor = color(backgroundColor)
        .darken(0.372)
        .toString();

    const hoverColor = Colors.fontGrayColor;

    const cssBackgroundColor = backgroundAlways ? bgColor : "transparent";
    const cssFill = disabled ? disabledFontColor : finalFontColor;

    React.useEffect(() => {
        rootRef.current.style.backgroundColor = cssBackgroundColor;
    }, [cssBackgroundColor]);

    React.useEffect(() => {
        rootRef.current.style.fill = cssFill;
    }, [cssFill]);

    const onMouseEnter = () => {
        if (disabled) return;
        rootRef.current.style.backgroundColor = hoverBackground;
        rootRef.current.style.fill = hoverColor;
    };

    const onMouseLeave = () => {
        rootRef.current.style.backgroundColor = cssBackgroundColor;
        rootRef.current.style.fill = cssFill;
    };

    const handleClick = (event: React.MouseEvent) => {
        if (disabled) return;
        if (href) history.push(href);
        else onClick?.(event);
    };

    return (
        <div
            data-tid={dataTID}
            data-tid-props={JSON.stringify({disabled, loading})}
            className={className}
        >
            <Loader
                active={loading}
                type="mini"
                caption={null}
                delayBeforeSpinnerShow={0}
            >
                <div
                    data-tid={dataTID}
                    data-prop-disabled={disabled}
                    /** TODO Need to remove 'data-tid-props' after full migration to Playwright */
                    data-tid-props={JSON.stringify({disabled})}
                    className={contentClassName}
                    ref={setRootRef}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={handleClick}
                >
                    {icon}
                </div>
            </Loader>
        </div>
    );
};

RoundButton.displayName = "RoundButton";
export default RoundButton;
