import {ObjectUtils} from "@skbkontur/hotel-utils";
import {CSSThemePropertyType, ICSSTheme} from "./CSSTheme";

export type CSSThemePrefixType = string | ((keyPath: string[]) => string);

export class CSSThemeHelper {
    static objectThemeToCSSTheme(theme: object, cssPrefix: CSSThemePrefixType, excludeKeys: RegExp): ICSSTheme {
        const cssTheme = {} as ICSSTheme;
        const collectCSSProperties = (value: object | CSSThemePropertyType, path: string[] = []) => {
            if (value === undefined) {
                return;
            }
            if (ObjectUtils.isObject(value)) {
                Object.keys(value).forEach(key => {
                    if (!excludeKeys?.test(key))
                        collectCSSProperties(value[key], [...path, key]);
                });
                return;
            }
            if (typeof value === "string" || value === null) {
                const keyPrefix = typeof cssPrefix === "function" ? cssPrefix(path) : cssPrefix;
                const propertyName = this.getCSSPropertyName(path, keyPrefix);
                cssTheme[propertyName] = value === "" ? null : value;
                return;
            }
            // eslint-disable-next-line no-console
            console.error(`Unsupported widget theme parameter type="${JSON.stringify(value)}" path="${path.join(" => ")}". Check the docs.`);
        };
        collectCSSProperties(theme);
        return cssTheme;
    }

    static applyCSSTheme(el: HTMLElement, cssTheme: ICSSTheme) {
        Object.keys(cssTheme).forEach(propertyName => {
            el.style.setProperty(propertyName, cssTheme[propertyName]);
        });
    }

    private static getCSSPropertyName = (path: string[], cssPrefix: string) => {
        const pathsWithPrefix = cssPrefix ? [cssPrefix, ...path] : path;
        return `--${pathsWithPrefix.filter(Boolean).join("-")}`;
    };
}