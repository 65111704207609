import {DateCalculate, DateCompare, DateFormat, DateHelper, UnitOfTime} from "@skbkontur/hotel-date";
import {IAvailabilitiesHourlySearchParams, IHourlyObjectSearchParams} from "../../data/SearchParams";

export interface IHourlyAvailablePeriodDate {
    fromDate: string;
    toDate: string;
}

export const MAX_HOUR_IN_NEW_DAY = 12;

export class HourlyObjectsAvailableHelper {
    static getPeriodDate = (date: string,  offsetInMinutes?: number): IHourlyAvailablePeriodDate => {
        const todayInHotelTimeZone = DateCalculate.getTodayWithTimezone(DateFormat.FullDateYearFirstWithTime, offsetInMinutes);

        const isToday = DateCompare.isSame({
            firstDate: date,
            secondDate: DateHelper.convert(todayInHotelTimeZone, DateFormat.FullDateYearFirstWithTime, DateFormat.FullDateDayFirst),
            format: DateFormat.FullDateDayFirst,
            unitOfTime: UnitOfTime.Day
        });

        const isBeforeDate = DateCompare.isBeforeDateByDay(
            date,
            DateHelper.convert(todayInHotelTimeZone, DateFormat.FullDateYearFirstWithTime, DateFormat.FullDateDayFirst)
        );

        const fromDate = (isToday || isBeforeDate)
            ? todayInHotelTimeZone
            : DateHelper.convert(date,  DateFormat.FullDateDayFirst, DateFormat.FullDateYearFirstWithTime);

        const nextDay =  DateCalculate.add({
            amount: 1,
            date: fromDate,
            format: DateFormat.FullDateYearFirstWithTime,
            formatOut: DateFormat.FullDateYearFirst,
            unitOfTime: UnitOfTime.Day
        });

        const toDate = DateCalculate.add({
            amount: MAX_HOUR_IN_NEW_DAY,
            date: nextDay,
            format: DateFormat.FullDateYearFirst,
            formatOut: DateFormat.FullDateYearFirstWithTime,
            unitOfTime: UnitOfTime.Hour
        });

        return {fromDate, toDate};
    };

    static getAvailabilitiesHourlyParams = (searchParams: IHourlyObjectSearchParams): IAvailabilitiesHourlySearchParams => {
        const {fromDate, toDate} = HourlyObjectsAvailableHelper.getPeriodDate(searchParams.fromDate);

        return {...searchParams, fromDate, toDate};
    };
}
