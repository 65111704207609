import {memoize} from "@skbkontur/hotel-utils";
import {memoizedUTCHotelDate} from "../hotelDate";
import {DateFormat} from "./DateFormat";
import {UnitOfTime} from "./Date";

export class DateMs {
    static startOfFor = (date: number, unitOfTime: MsUnitOfTimeType): number => {
        const startOf = START_OF_METHODS_BY_UNIT_OF_TIME[unitOfTime];
        return startOf(date);
    };

    static add = (dateMs: number, amount: number, unitOfTime: UnitOfTime = UnitOfTime.Day): number => (
        ADD_METHODS_BY_UNIT_OF_TIME[unitOfTime](dateMs, amount)
    );

    static range = (startDate: number, endDate: number, unitOfTime: UnitOfTime, step = 1): number[] => {
        const range = [];
        let date = startDate;
        const add = ADD_METHODS_BY_UNIT_OF_TIME[unitOfTime];

        while (date <= endDate) {
            range.push(date);
            date = add(date, step);
        }

        return range;
    };

    static isMsPeriodsOverlaps = (aStartMs: number, aEndMs: number, bStartMs: number, bEndMs: number) => (
        aStartMs < bEndMs && bStartMs < aEndMs
    );

    static dateToMs = memoize((date: string, format: DateFormat = DateFormat.FullDateDayFirst): number => (
        memoizedUTCHotelDate(date, format).valueOf()
    ));

    static msToDate = memoize((dateMs: number, format: DateFormat = DateFormat.FullDateDayFirst) => (
        memoizedUTCHotelDate(dateMs, DateFormat.UnixMsTimestamp).format(format)
    ));

    static getDatesDifference(args: IGetDatesDifferentArgs): number {
        const {
            firstDate,
            secondDate,
            unitOfTime = UnitOfTime.Day,
            zeroIsMinValue = true
        } = args;

        const firstStartedOfDate = DateMs.startOfFor(firstDate, unitOfTime);
        const secondStartedOfDate = DateMs.startOfFor(secondDate, unitOfTime);

        const diff = MS_TO_UNIT_OF_TIME[unitOfTime];

        const different = diff(secondStartedOfDate - firstStartedOfDate);

        return zeroIsMinValue ? Math.max(different, 0) : different;
    }

    static getNearestDateFromInterval = memoize((currentDate: number, minDate: number, maxDate: number): number => (
        minDate && currentDate <= minDate
            ? minDate
            : maxDate && currentDate >= maxDate
                ? maxDate
                : currentDate
    ));

    static getHotelCurrentMs = (offsetInMinutes: number) => Date.now() + offsetInMinutes * 60 * 1000;
}

export interface IGetDatesDifferentArgs {
    firstDate: number;
    secondDate: number;
    unitOfTime?: MsUnitOfTimeType;
    zeroIsMinValue?: boolean;
}

const ADD_METHODS_BY_UNIT_OF_TIME = {
    [UnitOfTime.Millisecond]: (dateMs: number, amount: number): number => (
        dateMs + amount
    ),

    [UnitOfTime.Second]: (dateMs: number, amount: number): number => {
        const date = new Date(dateMs);
        return date.setUTCSeconds(date.getUTCSeconds() + amount);
    },

    [UnitOfTime.Minute]: (dateMs: number, amount: number): number => {
        const date = new Date(dateMs);
        return date.setUTCMinutes(date.getUTCMinutes() + amount);
    },

    [UnitOfTime.Hour]: (dateMs: number, amount: number): number => {
        const date = new Date(dateMs);
        return date.setUTCHours(date.getUTCHours() + amount);
    },

    [UnitOfTime.Day]: (dateMs: number, amount: number): number => {
        const date = new Date(dateMs);
        return date.setUTCDate(date.getUTCDate() + amount);
    },

    [UnitOfTime.Week]: (dateMs: number, amount: number): number => {
        const date = new Date(dateMs);
        const daysNumberToSet = date.getUTCDate() + 7 * amount;
        return date.setUTCDate(daysNumberToSet);
    },

    [UnitOfTime.Month]: (dateMs: number, amount: number): number => {
        const date = new Date(dateMs);
        return date.setUTCMonth(date.getUTCMonth() + amount);
    },

    [UnitOfTime.Year]: (dateMs: number, amount: number): number => {
        const date = new Date(dateMs);
        return date.setUTCFullYear(date.getUTCFullYear() + amount);
    },
};

const START_OF_METHODS_BY_UNIT_OF_TIME = {
    [UnitOfTime.Hour]: (dateMs: number): number => {
        const date = new Date(dateMs);
        return date.setUTCHours(date.getUTCHours(), 0, 0, 0);
    },

    [UnitOfTime.Day]: (dateMs: number): number => {
        const date = new Date(dateMs);
        return date.setUTCHours(0, 0, 0, 0);
    }
};

const MS_TO_UNIT_OF_TIME = {
    [UnitOfTime.Hour]: (ms: number): number => (
        ms / (1000 * 60 * 60)
    ),

    [UnitOfTime.Day]: (ms: number): number => (
        MS_TO_UNIT_OF_TIME[UnitOfTime.Hour](ms) / 24
    )
};

type MsUnitOfTimeType = UnitOfTime.Day | UnitOfTime.Hour;
