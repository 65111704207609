import {CSSThemeHelper, CSSThemePrefixType} from "./CSSThemeHelper";

interface IUseCSSThemeProps {
    theme: object;
    ref?: React.RefObject<HTMLElement>;
    cssPrefix?: CSSThemePrefixType;
    excludeKeys?: RegExp;
}

export const useCSSTheme = (props: IUseCSSThemeProps) => {
    const {theme, ref, cssPrefix, excludeKeys} = props;

    React.useEffect(() => {
        const el = ref.current;
        if (theme && Object.keys(theme).length && el) {
            const cssTheme = CSSThemeHelper.objectThemeToCSSTheme(theme, cssPrefix, excludeKeys);
            CSSThemeHelper.applyCSSTheme(el, cssTheme);
        }
    }, [theme]);
};