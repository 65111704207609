import {Gapped} from "@skbkontur/react-ui";
import cn from "classnames";
import {DateFormat, DateHelper} from "@skbkontur/hotel-date";
import {useTranslation} from "@skbkontur/i18n";
import {useSelector} from "react-redux";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {IAppState} from "../../store/AppState";
import {Currency} from "@skbkontur/hotel-data/currency";
import styles from "./HourlyObjectsList.scss";
import {IHourlyObjectsItemAvailableTime} from "./HourlyObjectsListAvailableTime";

interface IHourlyObjectsTimeItemProps extends IHourlyObjectsItemAvailableTime {
    withoutPlaceholderPrice?: boolean;
    isNotAvailable?: boolean;
}

const HourlyObjectsTimeItem = (props: IHourlyObjectsTimeItemProps) => {
    const {timestamp, price, withoutPlaceholderPrice, isNotAvailable} = props;
    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const offsetInMinutes = useSelector((state: IAppState) => state.hotelInfo.info?.timeZone?.offsetInMinutes);

    const className = cn(styles.timeItem, {[styles.timeItemDisable]: isNotAvailable});

    return (
        <Gapped gap={0} vertical className={className}>
            <div>
                {DateHelper.convertWithTimezone(timestamp, {
                    formatIn: DateFormat.UnixMsTimestamp,
                    formatOut: DateFormat.OnlyTime,
                    offsetInMinutes,
                })}
            </div>
            {(!withoutPlaceholderPrice || isNotAvailable) && (
                <div className={cn(styles.timeItemPlaceholder, {
                    [styles.timeItemPrice]: !isNotAvailable
                })}
                >
                    {isNotAvailable
                        ? t("notAvailable")
                        : t("price", {price: Currency.formatDisplayValue(price, true)})}
                </div>
            )}
        </Gapped>
    );
};

export default HourlyObjectsTimeItem;
