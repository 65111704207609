import {useMemoObject} from "@skbkontur/hotel-hooks/memo";
import {createBooking} from "../../store/booking/bookingActionCreators";
import {clearAccommodations} from "../../store/accommodations/accommodationsActionCreators";
import {useCommonBookingProvider} from "../../hooks/useCommonBookingProvider";
import {BookingKind} from "../../data/BookingKind";
import BookingLightbox from "./BookingLightbox/BookingLightbox";
import {BookingContext} from "./BookingContext";

export const BookingProvider = (props: React.PropsWithChildren<object>) => {
    const {children} = props;

    const {
        isBookingLightboxOpened,
        isBookingProcess,
        handleBooking,
        closeBookingLightbox,
        openBookingLightbox
    } = useCommonBookingProvider({
        kind: BookingKind.Daily,
        clearAccommodations,
        createBooking
    });

    return (
        <BookingContext.Provider
            value={useMemoObject({
                isBookingProcess,
                openBookingLightbox,
            })}
        >
            {children}
            {isBookingLightboxOpened && <BookingLightbox onBooking={handleBooking} onClose={closeBookingLightbox}/>}
        </BookingContext.Provider>
    );
};
