import {IHourlyObject} from "../../data/HourlyObjects";
import {CustomThunkAction} from "../ThunkAction";

export enum HourlyObjectsActionTypes {
    GET_HOURLY_OBJECTS_REQUEST = "roomCategories/GET_HOURLY_OBJECTS_REQUEST",
    GET_HOURLY_OBJECTS_SUCCESS = "roomCategories/GET_HOURLY_OBJECTS_SUCCESS",
    GET_HOURLY_OBJECTS_ERROR = "roomCategories/GET_HOURLY_OBJECTS_ERROR",
}

interface IHourlyObjectsRequestAction {
    type: HourlyObjectsActionTypes.GET_HOURLY_OBJECTS_REQUEST;
}

interface IHourlyObjectsSuccessAction {
    type: HourlyObjectsActionTypes.GET_HOURLY_OBJECTS_SUCCESS;
    response: IHourlyObject[];
}

interface IHourlyObjectsErrorAction {
    type: HourlyObjectsActionTypes.GET_HOURLY_OBJECTS_ERROR;
    error: Error;
}

export type KnownHourlyObjectsAction =
    | IHourlyObjectsRequestAction
    | IHourlyObjectsSuccessAction
    | IHourlyObjectsErrorAction;

export const getHourlyObjects = (): CustomThunkAction<IHourlyObject[]> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {hourlyApi}) => (
        dispatch({
            type: {
                REQUEST: HourlyObjectsActionTypes.GET_HOURLY_OBJECTS_REQUEST,
                SUCCESS: HourlyObjectsActionTypes.GET_HOURLY_OBJECTS_SUCCESS,
                FAILURE: HourlyObjectsActionTypes.GET_HOURLY_OBJECTS_ERROR,
            },
            asyncAction: hourlyApi.getEntities(),
        })
    )
);
