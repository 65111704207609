import {DatePicker} from "@skbkontur/react-ui";
import {tooltip, ValidationInfo, ValidationWrapper} from "@skbkontur/react-ui-validations";
import HtmlHelper from "../../helpers/HtmlHelper";
import styles from "./DatesPeriodControl.scss";
import {DateCheck} from "@skbkontur/hotel-date";

export interface IDatePeriodField {
    value: string;
    placeholder: React.ReactNode;
    onChange: (value: string) => void;
}

interface IDatesPeriodPickerProps {
    field: IDatePeriodField;
    validationInfo?: ValidationInfo;
    minDate?: string;
    maxDate?: string;
    width?: string | number;
}

interface IDatesPeriodPickerState {
    isFocused: boolean;
}

export default class DatesPeriodPicker extends React.Component<IDatesPeriodPickerProps, IDatesPeriodPickerState> {
    state: IDatesPeriodPickerState = {
        isFocused: false
    };

    pickerRef: DatePicker = null;
    outerRef: React.RefObject<HTMLDivElement>;

    constructor(props: IDatesPeriodPickerProps) {
        super(props);
        this.outerRef = React.createRef();
    }

    getMenuAlign(): "left" | "right" {
        const outerDomElement = this.outerRef.current;
        if (outerDomElement) {
            const position = HtmlHelper.getAbsolutePosition(outerDomElement);
            const width = outerDomElement.offsetWidth;
            if (position.right + width < 250) return "right";
        }
        return "left";
    }

    render() {
        const {field, minDate, maxDate, validationInfo, width} = this.props;
        const {placeholder, value} = field;
        const showPlaceholder = placeholder && !this.state.isFocused && !value;

        return (
            <div className={styles.datePickerWrapper} ref={this.outerRef}>
                {showPlaceholder && (
                    <span className={styles.placeholder} onClick={this.focusPicker}>
                        {placeholder}
                    </span>
                )}
                <ValidationWrapper
                    validationInfo={validationInfo}
                    renderMessage={tooltip("top left")}
                >
                    <DatePicker
                        ref={this.setPickerRef}
                        value={value}
                        size={"medium"}
                        width={width || "100%"}
                        onValueChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        minDate={minDate}
                        maxDate={maxDate}
                        menuAlign={this.getMenuAlign()}
                    />
                </ValidationWrapper>
            </div>
        );
    }

    focusPicker = () => this.pickerRef.focus();
    setPickerRef = (ref: DatePicker) => this.pickerRef = ref;
    isFocused = () => this.state.isFocused;

    handleFocus = () => this.setState({isFocused: true});
    handleBlur = () => this.setState({isFocused: false});
    handleChange = (value: string) => {
        // value строка в формате dd.mm.yyyy
        if(DateCheck.isValid(value))
            this.props.field.onChange(value);
    };
}
