import {ThemeContext, ThemeFactory} from "@skbkontur/react-ui";
import React from "react";
import WidgetStore from "../../WidgetStore";
import {ThemeHelper} from "../../theme/ThemeHelper";
import {BM_DEFAULT_REACT_UI_THEME} from "../../theme/ThemeConstants";
import {useColorsCSSTheme} from "../../theme/useColorsCSSTheme";

const WidgetCommonTheme = (props: React.PropsWithChildren<object>) => {
    const {children} = props;
    const {theme} = WidgetStore.settings;
    const {common} = theme || {};
    const {colors} = common || {};

    const bodyRef = React.useRef(document.body);

    const commonReactUITheme = React.useMemo(() => {
        const widgetReactUITheme = ThemeHelper.getCommonReactUITheme(theme);
        return ThemeFactory.create(widgetReactUITheme, BM_DEFAULT_REACT_UI_THEME);
    }, [theme]);

    const finalColorsTheme = React.useMemo(() => ({
        ...colors,
        /** Fallback React UI colors for custom buttons */
        btnPayBg: commonReactUITheme.btnPayBg,
        btnPayHoverBg: commonReactUITheme.btnPayHoverBg
    }), [colors, commonReactUITheme]);

    useColorsCSSTheme({ref: bodyRef, theme: finalColorsTheme});

    return (
        <ThemeContext.Provider value={commonReactUITheme}>
            {children}
        </ThemeContext.Provider>
    );
};
WidgetCommonTheme.displayName = "WidgetCommonTheme";
export default WidgetCommonTheme;