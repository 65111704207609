import {Gapped} from "@skbkontur/react-ui";
import styles from "./BookingAccommodationsPanel.scss";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import {useTranslation} from "@skbkontur/i18n";
import {RoomCategorySearchParamsContext} from "../../../../SearchParams/SearchParamsContext";
import {AccommodationsContext} from "../../../../Accommodations/AccommodationsContext";
import {SelectedAccommodationsHelper} from "../../../../Accommodations/helpers/SelectedAccommodationsHelper";
import BookingAccommodationsPanelWarning from "./BookingAccommodationsPanelWarning";
import {BookingLightboxStep, BookingLightboxStepsContext} from "../../Steps/BookingLightboxStepsContext";
import BookingLightboxPanel from "../../Panel/BookingLightboxPanel";
import BookingLightboxSumWithLabel from "../../SumWithLabel/BookingLightboxSumWithLabel";
import {DeviceContext} from "../../../../Device/DeviceContext";
import {bookingLightboxOccupancyAnalyticsEvents} from "../../../../../analytics/bookingLightboxOccupancyAnalyticsEvents";
import {bookingLightboxRatesAnalyticsEvents} from "../../../../../analytics/bookingLightboxRatesAnalyticsEvents";
import PrimaryButton from "../../../../../components/PrimaryButton/PrimaryButton";
import {useSelector} from "react-redux";
import {IAppState} from "../../../../../store/AppState";
import {RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";
import {createMapBy} from "@skbkontur/hotel-utils";

interface IBookingAccommodationsPanelProps {
    isValid: boolean;
}

const BookingAccommodationsPanel = (props: IBookingAccommodationsPanelProps) => {
    const {isValid} = props;
    const {tcn} = useTranslation(TranslationNamespaces.Common);

    const {isDesktopMode} = React.useContext(DeviceContext);
    const {goToStep} = React.useContext(BookingLightboxStepsContext);
    const {params: {adultsCount, kidsCount}} = React.useContext(RoomCategorySearchParamsContext);
    const {selectedAccommodationsMap, accommodationsPricesMap} = React.useContext(AccommodationsContext);
    const roomCategories = useSelector((state: IAppState) => state.roomCategories.roomCategories);
    const roomCategoriesMap = createMapBy(roomCategories, rc => rc.id);

    const bookings = SelectedAccommodationsHelper.toBookingAccommodations(selectedAccommodationsMap);
    const bookingsCount = bookings.length;
    const showPanel = !!bookingsCount;

    const hostelAdultsCount = bookings.reduce((count, {roomCategoryId, adultsCount}) => (
        count + (RoomCategoryHelper.isHostelCategory(roomCategoriesMap[roomCategoryId]) ? adultsCount : 0)
    ), 0);

    const allAdultsCount = bookings.reduce((count, {adultsCount}) => count + adultsCount, 0);
    const allChildrenCount = bookings.reduce((count, {childrenCount}) => count + childrenCount, 0);
    const notUsedAdultsCount = Math.max(allAdultsCount - adultsCount, 0);
    const notUsedHostelAdultsCount =  Math.min(notUsedAdultsCount, hostelAdultsCount);

    const notEnoughAdultsCount = Math.max(adultsCount - allAdultsCount, 0);
    const notEnoughChildrenCount = Math.max(kidsCount - allChildrenCount - notUsedHostelAdultsCount, 0);
    const notEnoughCounts = notEnoughAdultsCount > 0 || notEnoughChildrenCount > 0;

    const disabled = !isValid || notEnoughCounts;
    const minRatesSum = SelectedAccommodationsHelper.getMinRatesSum(selectedAccommodationsMap, accommodationsPricesMap);

    const goToRates = () => {
        let step: BookingLightboxStep;
        const isRatesFilled = SelectedAccommodationsHelper.isRatesFilled(bookings);
        if (isRatesFilled) {
            bookingLightboxRatesAnalyticsEvents.trackGoToPayment();
            step = BookingLightboxStep.FinishOrder;
        } else {
            bookingLightboxOccupancyAnalyticsEvents.trackGoToRates(bookings.length);
            step = BookingLightboxStep.SelectRates;
        }
        goToStep(step, {scrollToStart: true});
    };

    return (
        <BookingLightboxPanel isShown={showPanel}>
            <div className={styles.root}>
                <div className={styles.sumWithLabel}>
                    <BookingLightboxSumWithLabel
                        sum={minRatesSum}
                        isHostel={false}
                        accommodationsCount={bookingsCount}
                    />
                </div>
                <Gapped className={styles.actions} gap={20}>
                    {notEnoughCounts && (
                        <BookingAccommodationsPanelWarning
                            adultsCount={notEnoughAdultsCount}
                            childrenCount={notEnoughChildrenCount}
                        />
                    )}
                    {(isDesktopMode || !notEnoughCounts) && (
                        <PrimaryButton className={styles.button} onClick={goToRates} disabled={disabled}>
                            {tcn("buttons.continue")}
                        </PrimaryButton>
                    )}
                </Gapped>
            </div>
        </BookingLightboxPanel>
    );
};
BookingAccommodationsPanel.displayName = "BookingAccommodationsPanel";
export default React.memo(BookingAccommodationsPanel);
