import {DeviceContext, DeviceMode, DeviceSize} from "./DeviceContext";
import {tracker} from "@skbkontur/km-tracker";
import WidgetStore from "../../widget/WidgetStore";
import {useMount} from "@skbkontur/hotel-hooks/react";
import {useMemoObject} from "@skbkontur/hotel-hooks/memo";

export const getDeviceMode = (width: number): DeviceMode => {
    switch (true) {
        case width > DeviceSize.DesktopLargeSize:
            return DeviceMode.DesktopLarge;
        case width >= DeviceSize.DesktopSize:
            return DeviceMode.Desktop;
        case width >= DeviceSize.DesktopMiniSize:
            return DeviceMode.DesktopMini;
        case width >= DeviceSize.TableSize:
            return DeviceMode.Tablet;
        case width >= DeviceSize.MobileLarge:
            return DeviceMode.MobileLarge;
        default:
            return DeviceMode.Mobile;
    }
};

const DESKTOP_MODES = [DeviceMode.DesktopMini, DeviceMode.Desktop, DeviceMode.DesktopLarge];
const MOBILE_MODES = [DeviceMode.Mobile, DeviceMode.MobileLarge];

const DeviceProvider = (props: React.PropsWithChildren<object>) => {
    const {children} = props;

    const [width, setWidth] = React.useState<number>();
    const widthRef = React.useRef<number>(width);

    const deviceMode = getDeviceMode(width);
    const isDesktopMode = DESKTOP_MODES.includes(deviceMode);
    const isMobileMode = MOBILE_MODES.includes(deviceMode);

    useMount(() => {
        const updateWindowDimensions = () => {
            const currentWidth = window.innerWidth;
            if (currentWidth !== widthRef.current) {
                setWidth(widthRef.current = currentWidth);
            }
        };
        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);

        tracker.trackEvent("loadApp", "loadApp", "Загрузка страницы", {
            device: deviceMode,
            isWidget: WidgetStore.isWidget(),
            widgetVersion: WidgetStore?.settings?.version
        });

        return () => window.removeEventListener("resize", updateWindowDimensions);
    });

    return (
        <DeviceContext.Provider value={useMemoObject({deviceMode, isDesktopMode, isMobileMode})}>
            {children}
        </DeviceContext.Provider>
    );
};
DeviceProvider.displayName = "DeviceProvider";
export default DeviceProvider;
