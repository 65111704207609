import {useTranslation} from "@skbkontur/i18n";
import {ValidationContainer} from "@skbkontur/react-ui-validations";
import SliderWithGallery from "../../../../components/SliderWithGallery/SliderWithGallery";
import {TranslationNamespaces} from "../../../../constants/TranslationNamespaces";
import {IBookingOrderState} from "../../../../data/BookingOrder";
import {Compare} from "../../../../helpers/CompareHelper";
import {useHourlyObjectById} from "../../../../hooks/useHourlyObjectById";
import WidgetStore from "../../../../widget/WidgetStore";
import {BookingOrderContext} from "../../../BookingOrder/BookingOrderContext";
import {HourlyAccommodationsContext} from "../../../HourlyAccommodations/HourlyAccommodationsContext";
import styles from "./HourlyBookingLightboxOrder.scss";
import HourlyObjectEmpty from "../../../../images/empty/HourlyObjectEmpty.png";
import {HourlyObjectsSearchParamsContext} from "../../../SearchParams/SearchParamsContext";
import {IHourlyObjectItem} from "../../../../data/HourlyObjects";
import {Gapped} from "@skbkontur/react-ui";
import {IFeatureWithCount, useSelectedHotelFeatures} from "./useSelectedHotelFeatures";
import MeasureControl from "../../../../components/MeasureControl/MeasureControl";
import {Measure} from "../../../../data/HotelFeature";
import {SumWithLocale} from "../../../../components/SumWithLocale/SumWithLocale";
import {useHourlyRate} from "../../../../hooks/useHourlyRate";
import OrderFormComment from "../../../BookingOrder/Fields/OrderFormComment";
import OrderFormEmail from "../../../BookingOrder/Fields/OrderFormEmail";
import OrderFormFio from "../../../BookingOrder/Fields/OrderFormFio";
import OrderFormIsAgreed from "../../../BookingOrder/Fields/OrderFormIsAgreed";
import OrderFormPhone from "../../../BookingOrder/Fields/OrderFormPhone";
import {useSelector} from "react-redux";
import {IAppState} from "../../../../store/AppState";
import {PaymentSystem} from "../../../../data/Payment";
import {useGetPaymentSourceSettingsId} from "../../../../hooks/useGetPaymentSourceSettingsId";
import {BookingContactInfoHelper} from "../../../../helpers/BookingContactInfoHelper";
import {useHourlyPeriod} from "./useHourlyPeriod";
import {HourlyBookingType} from "../../../../data/HourlyBooking";
import {HourlyBookingLightboxHelper} from "../HourlyBookingLightboxHelper";
import BookingMakeOrder from "../../../../components/BookingMakeOrder/BookingMakeOrder";
import {PaymentContext} from "../../../Payment/PaymentContext";
import {BookingPaymentSum} from "../../../../components/BookingPaymentSum/BookingPaymentSum";
import {usePaymentSystem} from "../../../../hooks/usePaymentSystem";
import {HourlyBookingOverbookingContext} from "../../../BookingOverbooking/BookingOverbookingContext";
import {Currency} from "@skbkontur/hotel-data/currency";
import {useMemoObject} from "@skbkontur/hotel-hooks/memo";

interface IHourlyBookingLightboxOrderProps {
    onBooking: (bookingParams: HourlyBookingType) => Promise<void>;
}

const initialState: IBookingOrderState = {
    customer: {fio: "", email: "", phone: ""},
    comment: "",
    isAgreed: false,
};

const HourlyBookingLightboxOrder = (props: IHourlyBookingLightboxOrderProps) => {
    const {onBooking} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const isLoading = useSelector((state: IAppState) => state.hourlyBooking.isLoading);
    const offsetInMinutes = useSelector((state: IAppState) => state.hotelInfo.info.timeZone?.offsetInMinutes);
    const {isPaymentSystemEnabled} = usePaymentSystem();
    const getPaymentSourceSettingsId = useGetPaymentSourceSettingsId();

    const validationRef = React.useRef<ValidationContainer>();
    const isBookingProcessRef = React.useRef(false);

    const [form, setForm] = React.useState<IBookingOrderState>(initialState);

    const {params} = React.useContext(HourlyObjectsSearchParamsContext);
    const {selectedAccommodation, accommodationPrices} = React.useContext(HourlyAccommodationsContext);
    const {isOverbookingLoading, isOverbooking, stopWatchOverbooking} = React.useContext(HourlyBookingOverbookingContext);
    const {isPaymentProcess, paymentUrl} = React.useContext(PaymentContext);
    const {hourlyObjectItemId, hourlyObjectId, hourlyRateId} = selectedAccommodation;

    const hotelFeatures = useSelectedHotelFeatures();

    const [waitPaymentFromSystem, setWaitPaymentFromSystem] = React.useState<PaymentSystem>();

    const prepaymentSum = HourlyBookingLightboxHelper.getTotalPrepayment(hourlyRateId, accommodationPrices);
    const hasPrepayment = isPaymentSystemEnabled && Currency.getFloatValue(prepaymentSum) > 0;
    const isTBankPaymentProcess = isPaymentProcess && waitPaymentFromSystem === PaymentSystem.TBankSbp;

    const {
        totalHours,
        totalSum
    } = HourlyBookingLightboxHelper.getTotalInfo({
        selectedAccommodation,
        accommodationPrices,
        hotelFeatures,
        params,
    });

    const {isNoneRefundable, freeCancellationHoursCount} = useHourlyRate(hourlyRateId);
    const bookingFeatures = useSelectedHotelFeatures();
    const hourlyObject = useHourlyObjectById(hourlyObjectId);
    const {timeFrom, timeTo} = params;
    const {date, hourFrom, hourTo} = useHourlyPeriod(timeFrom, timeTo);
    const {imageMetas, items, name} = hourlyObject;

    const item = hourlyObjectItemId && items.find((el: IHourlyObjectItem) => el.id === hourlyObjectItemId);

    const book = React.useCallback(
        async (paymentSource?: PaymentSystem) => {
            if (isBookingProcessRef.current) return;
            isBookingProcessRef.current = true;
            if (await validationRef.current.validate() && !(await isOverbooking())) {
                stopWatchOverbooking();
                const bookingsToCreate = HourlyBookingLightboxHelper.getBookingsToCreate(
                    selectedAccommodation,
                    form,
                    timeFrom,
                    timeTo,
                    offsetInMinutes
                );
                const contactInfo = BookingContactInfoHelper.getContactInfo(form);

                const paymentSourceSettingsId = getPaymentSourceSettingsId(paymentSource);

                const prepaymentSum =
                    accommodationPrices.find(el => el.rateId === selectedAccommodation.hourlyRateId)?.prepaymentSum ||
                    Currency.zero();

                const bookingParams: HourlyBookingType = {
                    paymentSourceSettingsId,
                    paymentSystem: paymentSource,
                    prepaymentSum: Currency.getFloatValue(prepaymentSum),
                    bookings: [{...bookingsToCreate}],
                    contactInfo,
                };

                await onBooking(bookingParams);
                setWaitPaymentFromSystem(paymentSource);
            }
            isBookingProcessRef.current = false;
        },
        [selectedAccommodation, accommodationPrices, form, params, offsetInMinutes, getPaymentSourceSettingsId]
    );

    return (
        <BookingOrderContext.Provider value={useMemoObject({form, setForm, book})}>
            <Gapped vertical gap={10}>
                <div className={styles.header}>
                    <div className={styles.sliderContainer}>
                        <SliderWithGallery
                            imageMetas={imageMetas.sort(Compare.byOrder)}
                            emptyImage={WidgetStore.getUrlWithBase(HourlyObjectEmpty)}
                        />
                    </div>
                    <div className={styles.description}>
                        <span className={styles.title}>{item?.name || name}</span>
                        <span className={styles.date}>
                            {date} {t("Select.from")} {hourFrom} {t("Select.to")} {hourTo}
                        </span>
                        {bookingFeatures.map((el: IFeatureWithCount) => (
                            <span key={el.id} className={styles.item}>
                                {el.name} • {el.count} <MeasureControl measure={Measure.Counter} />
                            </span>
                        ))}
                    </div>
                </div>
                <div className={styles.total}>
                    {bookingFeatures.length
                        ? t("totalWithFeature", {
                            totalHours,
                            featureCount: bookingFeatures.length,
                            totalSum,
                            reactParams: {
                                SumWithLocale: <SumWithLocale sum={totalSum} />,
                            },
                        })
                        : t("total", {
                            totalHours,
                            totalSum,
                            reactParams: {
                                SumWithLocale: <SumWithLocale sum={totalSum} />,
                            },
                        })}
                </div>
                <div className={styles.contactDetails}>{t("contactDetails")}</div>
                <div className={styles.fieldWrapper}>
                    <ValidationContainer ref={validationRef}>
                        <OrderFormFio />
                        <OrderFormPhone />
                        <OrderFormEmail />
                        <OrderFormComment />
                        <OrderFormIsAgreed />
                    </ValidationContainer>
                </div>
                {!isNoneRefundable && !freeCancellationHoursCount && !hasPrepayment && (
                    <div className={styles.sum}>
                        {t("prepayment")}: <SumWithLocale sum={0} />
                    </div>
                )}
                {hasPrepayment && (
                    <div className={styles.prepaymentBox}>
                        <BookingPaymentSum sumValue={prepaymentSum} />
                    </div>
                )}
                <BookingMakeOrder
                    isTBankPaymentProcess={isTBankPaymentProcess}
                    hasPrepayment={hasPrepayment}
                    paymentUrl={paymentUrl}
                    isOverbookingLoading={isOverbookingLoading}
                    isLoading={isLoading}
                />
            </Gapped>
        </BookingOrderContext.Provider>
    );
};

HourlyBookingLightboxOrder.display = "HourlyBookingLightboxOrder";
export default HourlyBookingLightboxOrder;
