import {CustomThunkAction} from "../ThunkAction";
import {IBookingAccommodation} from "../../data/Booking";
import WidgetStore from "../../widget/WidgetStore";
import {AccommodationsPricesHelper} from "../../providers/Accommodations/helpers/AccommodationsPricesHelper";

export enum BookingActionTypes {
    CREATE_BOOKING_REQUEST = "booking/CREATE_BOOKING_REQUEST",
    CREATE_BOOKING_SUCCESS = "booking/CREATE_BOOKING_SUCCESS",
    CREATE_BOOKING_ERROR = "booking/CREATE_BOOKING_ERROR"
}

interface ICreateBookingRequestAction {
    type: BookingActionTypes.CREATE_BOOKING_REQUEST;
}

interface ICreateBookingSuccessAction {
    type: BookingActionTypes.CREATE_BOOKING_SUCCESS;
    response: string[];
}

interface ICreateBookingErrorAction {
    type: BookingActionTypes.CREATE_BOOKING_ERROR;
    error: Error;
}

export type KnownAccommodationsAction = ICreateBookingRequestAction
    | ICreateBookingSuccessAction
    | ICreateBookingErrorAction;

export const createBooking = (bookings: IBookingAccommodation[]): CustomThunkAction<string[]> => (
    // @ts-expect-error Use new Redux instead of fixing this types
    (dispatch, getState, {bookingApi}) => dispatch({
        type: {
            REQUEST: BookingActionTypes.CREATE_BOOKING_REQUEST,
            SUCCESS: BookingActionTypes.CREATE_BOOKING_SUCCESS,
            FAILURE: BookingActionTypes.CREATE_BOOKING_ERROR
        },
        asyncAction: async () => {
            const createdBookings = await bookingApi.createBooking(bookings);
            const {roomCategories: {roomCategories}, accommodations: {accommodations}} = getState();
            if (WidgetStore.settings) {
                const pricesMap = AccommodationsPricesHelper.createPricesMap(accommodations);
                WidgetStore.settings.hooks.onBooking(createdBookings.map(b => ({
                    id: b.id,
                    comment: b.comment,
                    customer: b.customer,
                    fromDate: b.checkin,
                    toDate: b.checkout,
                    adultsCount: b.adultsCount,
                    kidsCount: b.childrenCount,
                    roomCategory: roomCategories.find(rc => rc.id === b.roomCategoryId).name,
                    ...AccommodationsPricesHelper.getBookingPrice(b, pricesMap)
                })));
            }
            return createdBookings.map(b => b.id);
        }
    })
);
