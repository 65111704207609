export enum DateFormat {
    /** Full date year first */
    FullDateYearFirstWithDashes = "YYYY-MM-DD",
    FullDateYearFirst = "YYYY.MM.DD",
    FullDateYearFirstWithTimeWithDashes = "YYYY-MM-DD HH-mm-ss",
    FullDateYearFirstWithTime = "YYYY-MM-DDTHH:mm",
    FullDateYearFirstWithTimeWithSeconds = "YYYY-MM-DDTHH:mm:ss",

    /** Full date day first */
    FullDateDayFirst = "DD.MM.YYYY",
    FullDateDayFirstWithDashes = "DD-MM-YYYY",
    FullDateDayFirstWithTimeWithDashes = "DD-MM-YYYY HH-mm-ss",
    FullDateDayFirstWithTimeWithColons = "DD.MM.YYYY HH:mm",

    /** Other formats */
    OnlyDay = "D",
    OnlyMonth = "MMMM",
    MonthWithYear = "MMMM YYYY",
    OnlyShortMonth = "MMM",
    OnlyMonthNumber = "MM",
    OnlyYear = "YYYY",
    DayOfWeek = "dd",
    DayWithTextMonth = "D MMMM",
    DayWithTextMonthAndYear = "D MMMM YYYY",
    OnlyTime = "HH:mm",
    OnlyTimeWithSeconds = "HH:mm:ss",
    OnlyHours = "HH",
    OnlyMinutes = "mm",
    DayWithMonthAndDayOfWeek = "D MMMM, dddd",
    DayWithShortMonthAndShortDayOfWeek = "D MMM, dd",

    ShortMonthAndYear = "MM/YY",
    UnixMsTimestamp = "x",
    TimeZone = "Z",
    FullDateYearFirstWithTimeWithSecondsWithTimeZone = "YYYY-MM-DDTHH:mm:ssZ"
}
