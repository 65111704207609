import {useTranslation} from "@skbkontur/i18n";
import {useSelector} from "react-redux";
import RoomAvailabilityCalendarWithFilter
    from "../../../../components/RoomAvailabilityCalendarWithFilter/RoomAvailabilityCalendarWithFilter";
import {TranslationNamespaces} from "../../../../constants/TranslationNamespaces";
import {CalendarMode} from "../../../../data/Calendar/Calendar";
import {IAppState} from "../../../../store/AppState";
import {RoomCategorySearchParamsContext} from "../../../SearchParams/SearchParamsContext";
import RoomAvailabilityCalendar from "../../../../components/RoomAvailabilityCalendar/RoomAvailabilityCalendar";
import BookingLightboxContainer from "../BookingLightboxContainer";
import {WidgetType} from "../../../../widget/settings/WidgetType";
import WidgetTypeTheme from "../../../../widget/components/WidgetTheme/WidgetTypeTheme";
import styles from "./BookingDatesNotAvailableCalendar.scss";

const BookingDatesNotAvailableCalendar = () => {
    const {params} = React.useContext(RoomCategorySearchParamsContext);
    const {roomCategoryId} = params;

    const roomCategories = useSelector((state: IAppState) => state.roomCategories.roomCategories);
    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    const title = roomCategoryId ? (
        t("accommodations.warnings.forCategory", {
            reactParams: {
                CategoryName: roomCategories.find(rc => rc.id === roomCategoryId).name
            }
        })
    ) : (
        t("accommodations.warnings.forTheseDates")
    );

    return (
        <>
            <BookingLightboxContainer>
                <div className={styles.emptyTextTitle}>
                    {title}
                </div>
                <div className={styles.emptyText}>
                    {t("accommodations.warnings.forTheseDatesDescription", {raw: true})}
                </div>
            </BookingLightboxContainer>
            <WidgetTypeTheme type={WidgetType.AvailabilityCalendar}>
                {roomCategoryId ? (
                    <RoomAvailabilityCalendar
                        searchParams={params}
                        roomCategoryId={roomCategoryId}
                        calendarMode={CalendarMode.TwoMonths}
                        inlayMode
                        withContainer
                    />
                ) : (
                    <RoomAvailabilityCalendarWithFilter
                        calendarMode={CalendarMode.TwoMonths}
                        inlayMode
                    />
                )}
            </WidgetTypeTheme>
        </>
    );
};
BookingDatesNotAvailableCalendar.displayName = "BookingDatesNotAvailableCalendar";
export default BookingDatesNotAvailableCalendar;
