import {getItemIndexById, IWithId, IWithOrder} from "./Compositions";

export class OrderHelper {
    static swapOrders = <TItem extends IWithOrder & IWithId>(
        items: TItem[],
        firstItemId: string,
        secondItemId: string
    ): TItem[] => {
        const firstItemIndex = getItemIndexById(items, firstItemId);
        const secondItemIndex = getItemIndexById(items, secondItemId);
        const newItems = [...items];

        const firstItem = newItems[firstItemIndex];
        const secondItem = newItems[secondItemIndex];

        newItems[firstItemIndex] = {...firstItem, order: secondItem.order};
        newItems[secondItemIndex] = {...secondItem, order: firstItem.order};

        return newItems;
    };
}