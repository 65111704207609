import {IBookingAccommodationVariant} from "../../../data/Booking";
import {uniq} from "lodash";
import {IOccupancy, IRoomCategoryAccommodation} from "../../../data/Accommodation";
import {IAccommodationsVariantsMap, IOccupancyWithValue} from "../AccommodationsMaps";
import {FREE_CHILDREN_COUNT} from "../../../data/HotelInfo";
import {captureSentryError, SentryErrorType} from "@skbkontur/hotel-sentry";
import {IRoomCategory, RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";
import {Compare} from "../../../helpers/CompareHelper";
import {createMapBy} from "@skbkontur/hotel-utils";

export class AccommodationsVariantsHelper {
    static getAdultsCountVariants = (occupancyVariants: IBookingAccommodationVariant[]): number[] => (
        uniq(occupancyVariants.map(ov => ov.adultsCount))
    );

    static getChildrenCountVariants = (occupancyVariants: IBookingAccommodationVariant[], adultsCount: number): number[] => (
        uniq(occupancyVariants.filter(ov => ov.adultsCount === adultsCount).map(ov => ov.childrenCount))
    );

    static createVariantsMap = (
        roomCategoryAccommodations: IRoomCategoryAccommodation[],
        roomCategories: IRoomCategory[]
    ): IAccommodationsVariantsMap => {
        const roomCategoryMap = createMapBy(roomCategories, rc => rc.id);
        return (
            roomCategoryAccommodations?.reduce((acc, item) => {
                const {roomCategoryId, accommodations} = item;
                const roomCategory = roomCategoryMap[roomCategoryId];
                const occupancies = accommodations.map(({mainOccupancy, additionalOccupancy}) => ({
                    mainOccupancy,
                    additionalOccupancy
                }));
                if (!roomCategory) {
                    captureSentryError(
                        {error: "Unexpected room category in BM search", roomCategoryId},
                        SentryErrorType.Inconsistency
                    );
                    return acc;
                }
                return {
                    ...acc,
                    [roomCategoryId]: this.getVariantsMap(roomCategory, occupancies)
                };
            }, {} as IAccommodationsVariantsMap) || {}
        );
    };

    private static getVariantsMap = (
        roomCategory: IRoomCategory,
        occupancies: IOccupancy[]
    ): IOccupancyWithValue<IBookingAccommodationVariant[]>[] => {
        const {roomCategoryType} = roomCategory;

        if (RoomCategoryHelper.isHostel(roomCategoryType))
            return [{
                mainOccupancy: 0,
                additionalOccupancy: 0,
                value: [{adultsCount: 1, childrenCount: 0, mainAccommodation: 1, additionalAccommodation: 0}]
            }];

        const orderedOccupancies = [...occupancies].sort(Compare.occupancies);
        const variantsChecker = new AccommodationsVariantsChecker();

        return orderedOccupancies.reduce((result, occupancy) => {
            const {mainOccupancy, additionalOccupancy} = occupancy;
            const placesCount = RoomCategoryHelper.getPlacesCountByOccupancy(mainOccupancy, roomCategory) + additionalOccupancy;
            const placesVariants = this.getPlacesVariants(placesCount);

            const unusedVariants = placesVariants.filter(variantsChecker.isNotUsedVariant);
            unusedVariants.forEach(variantsChecker.addUsedVariant);

            return [
                ...result,
                {
                    mainOccupancy,
                    additionalOccupancy,
                    value: unusedVariants
                }
            ];
        }, [] as IOccupancyWithValue<IBookingAccommodationVariant[]>[]);
    };

    private static getPlacesVariants = (placesCount: number): IBookingAccommodationVariant[] => {
        const results = [];
        for (let adultsCount = 1; adultsCount <= placesCount; ++adultsCount) {
            const maxChildrenCount = placesCount - adultsCount + FREE_CHILDREN_COUNT;
            for (let childrenCount = 0; childrenCount <= maxChildrenCount; ++childrenCount) {
                results.push({adultsCount, childrenCount});
            }
        }
        return results;
    };
}

class AccommodationsVariantsChecker {
    private usedVariants = new Set<string>();

    isNotUsedVariant = ({adultsCount, childrenCount}: IBookingAccommodationVariant) => (
        !this.usedVariants.has(`${adultsCount}_${childrenCount}`)
    );

    addUsedVariant = ({adultsCount, childrenCount}: IBookingAccommodationVariant) => {
        this.usedVariants.add(`${adultsCount}_${childrenCount}`);
    };
}
