import {Nullable} from "@skbkontur/react-ui/typings/utility-types";
import {last} from "lodash";

type KnownKeyType = string | number;
export type KeySelectorType<TItem> = (v: TItem, index?: number) => KnownKeyType;
export type ItemPipeType<TItem, TResult> = (v: TItem, index?: number) => TResult;

const isEmptyKey = (key: KnownKeyType) => key === undefined || key === null;

export const createMapBy = <TItem, TResult = TItem>(
    array: TItem[],
    keySelector: KeySelectorType<TItem>,
    itemPipe?: ItemPipeType<TItem, TResult>
): Record<string, TResult> => (
        array.reduce((result, item, index) => {
            const key = keySelector(item, index);
            if (isEmptyKey(key))
                return result;
            result[key] = itemPipe ? itemPipe(item, index) : item;
            return result;
        }, {})
    );

export const createArrayMapBy = <TItem>(
    array: TItem[],
    keySelector: KeySelectorType<TItem>
): Record<string, TItem[]> => (
    array.reduce((result, item) => {
        const key = keySelector(item);
        if (isEmptyKey(key))
            return result;
        result[key] = result[key] || [];
        result[key].push(item);
        return result;
    }, {} as Record<string, TItem[]>)
);

export const splitWith = <TItem>(
    array: TItem[],
    separator: (current: TItem, previous: TItem) => boolean
): TItem[][] => {
    const result: TItem[][] = [];
    for (let i = 0, l = array.length; i < l; ++i) {
        const current = array[i];
        if (i === 0 || (i > 0 && separator(current, array[i - 1]))) result.push([current]);
        else last(result).push(current);
    }
    return result;
};

export const createDeepMapBy = <TItem, TMap>(
    array: TItem[],
    keySelectors: KeySelectorType<TItem>[],
    multiple?: boolean
): TMap => (
    array.reduce((acc, item) => {
        keySelectors.reduce((acc, selector, index) => {
            const key = selector(item);
            if (acc === undefined || isEmptyKey(key))
                return;

            const isLastIndex = index === keySelectors.length - 1;
            if (isLastIndex) {
                return acc[key] = multiple ? [...(acc[key] || []), item] : item;
            }
            return acc[key] = acc[key] || {};
        }, acc);
        return acc;
    }, {} as TMap)
);

export const copyArrayShallowSafety = <TItem>(array: Nullable<TItem[]>) => (
    [...(array || [])]
);

export const sortByFunc = <TItem extends object, TValue>(
    array: TItem[],
    iterator: (i: TItem) => TValue,
    sortFunc: (a: TValue, b: TValue) => number
) => (
        array.sort((a, b) => sortFunc(iterator(a), iterator(b)))
    );

export const isArrayEquals = <T>(arrA: T[], arrB: T[]) => {
    if (!arrA || !arrB || arrA.length !== arrB.length)
        return false;

    for (let i = 0, l = arrA.length; i < l; ++i)
        if (arrA[i] !== arrB[i])
            return false;

    return true;
};

export const filterByIncluding = <TItem extends object, TInclude>(
    list: TItem[],
    pathToInclude: (item: TItem) => TInclude,
    includesList: TInclude[]
): TItem[] => (
        list.filter(item => includesList.includes(pathToInclude(item)))
    );

type FilterType<TItem extends object> = (item: TItem, index?: number) => boolean;

export const everyFilters = <TItem extends object>(filters: FilterType<TItem>[]): FilterType<TItem> => (
    (item: TItem, index?: number): boolean => (
        filters.every(filter => filter(item, index))
    )
);

export const someFilters = <TItem extends object>(filters: FilterType<TItem>[]): FilterType<TItem> => (
    (item: TItem, index?: number): boolean => (
        filters.some(filter => filter(item, index))
    )
);
