import cn from "classnames";
import styles from "./BookingSearchForm.scss";
import {WidgetClientGlobalStyles} from "../../widget/WidgetClientGlobalStyles";
import WidgetStore from "../../widget/WidgetStore";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {useSelector} from "react-redux";
import {IAppState} from "../../store/AppState";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import {IThemeElementButton} from "../../widget/theme/ThemeWidgets";

interface IBookingSearchFormButtonProps {
    onClick: () => void;
    isButtonUsed: boolean;
    theme?: IThemeElementButton;
}

const BookingSearchFormButton = (props: IBookingSearchFormButtonProps) => {
    const {isButtonUsed, onClick, theme} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    const isLoading = useSelector((state: IAppState) => state.booking.isLoading || state.accommodations.isLoading);

    const btnClasses = cn(styles.button, {
        [styles.used]: isButtonUsed,
        [WidgetClientGlobalStyles.WidgetBookingControlButtonContainer]: WidgetStore.isWidget(),
    });

    const buttonType = isButtonUsed ? "default" : "pay";

    return (
        <div className={btnClasses}>
            <PrimaryButton
                loading={isLoading}
                width="100%"
                use={buttonType}
                onClick={onClick}
                theme={theme}
            >
                {t("checkAvailability")}
            </PrimaryButton>
        </div>
    );
};
BookingSearchFormButton.displayName = "BookingSearchFormButton";
export default BookingSearchFormButton;
