import {padEnd} from "lodash";
import {NumberUtils, StringUtils} from "@skbkontur/hotel-utils";

export interface ICurrency {
    roubles: number;
    copecks: number;
}

export enum RoundingRule {
    None,
    DiscardCopecks,
    MathematicalLogic
}

const CURRENCY_BIG_LIMIT = 1000000000000000;

export class Currency {
    private static zeroCurrency: ICurrency;

    static create(arg1: number | string | ICurrency = null, arg2: number | string = null): ICurrency {
        if (arguments.length === 2)
            return Currency.normalize(Currency.initFromRoublesCopecks(arg1 as (number | string), arg2));
        else if (StringUtils.isNullOrEmpty(arg1 as string))
            return Currency.zero();
        else if (typeof (arg1) === "string")
            return Currency.normalize(Currency.initFromString(arg1));
        else if (typeof (arg1) === "number")
            return Currency.normalize(Currency.initFromNumber(arg1));
        else
            return Currency.normalize(arg1);
    }

    static isNoCurrency(currency: ICurrency): boolean {
        if (!currency)
            return true;
        return isNaN(currency.roubles) || isNaN(currency.copecks) || currency.roubles.toString().length > 13;
    }

    static zero = (): ICurrency => (
        Currency.zeroCurrency ? Currency.zeroCurrency : Currency.zeroCurrency = {roubles: 0, copecks: 0}
    );

    static fromTotalCopecks = (totalCopecks: number): ICurrency => (
        Currency.create((totalCopecks - totalCopecks % 100) / 100, Math.floor(totalCopecks % 100))
    );

    static subtract(left: ICurrency, right: ICurrency): ICurrency {
        if (!left && !right)
            return Currency.zero();
        if (!left)
            return Currency.create(-right.roubles, -right.copecks);
        if (!right)
            return left;
        const floatLeft = Currency.getFloatValue(left);
        const floatRight = Currency.getFloatValue(right);
        return Currency.initFromNumber(floatLeft - floatRight);
    }

    static multiply = (currency: ICurrency, multiplier: number): ICurrency => {
        if (!currency) return null;
        const increasedFloat = Currency.getFloatValue(currency);
        return Currency.initFromNumber(increasedFloat * multiplier);
    };

    static divide = (currency: ICurrency, divider: number): ICurrency => {
        if (!currency) return null;
        const dividedFloat = Currency.getFloatValue(currency);
        return Currency.initFromNumber(dividedFloat / divider);
    };

    static getVat = (currency: ICurrency, vatPercent: number): ICurrency => {
        if (!currency) return null;
        const currencyFloat = Currency.getFloatValue(currency);
        return Currency.initFromNumber(currencyFloat / (1 + vatPercent / 100) * vatPercent / 100);
    };

    static split = (sum: ICurrency, count: number): ICurrency[] => {
        const defaultParts = Array.from({length: count}, () => Currency.zero());

        if (Currency.equals(sum, Currency.zero()))
            return defaultParts;

        const partValue = Currency.initFromNumber(Currency.getFloatValue(sum) / count);
        return defaultParts.map((_, index) => (
            index < count - 1
                ? partValue
                : Currency.subtract(sum, Currency.multiply(partValue, count - 1))
        ));
    };

    static add(left: ICurrency, right: ICurrency): ICurrency {
        if (!left && !right)
            return Currency.zero();
        if (!left)
            return right;
        if (!right)
            return left;
        const floatLeft = Currency.getFloatValue(left);
        const floatRight = Currency.getFloatValue(right);
        return Currency.initFromNumber(floatLeft + floatRight);
    }

    static sum(items: ICurrency[]): ICurrency {
        if (!items || !items.length)
            return Currency.zero();
        return items.reduce(Currency.add, Currency.zero());
    }

    static initFromNumber(valueDouble: number): ICurrency {
        const roubles = valueDouble < 0 ? Math.ceil(valueDouble) : Math.floor(valueDouble);
        const thousandsCopecks = Math.round((valueDouble - roubles) * 1000);
        const copecks = Math.round(thousandsCopecks / 10);
        return copecks === 100 ? {roubles: roubles + 1, copecks: 0}
            : copecks === -100 ? {roubles: roubles - 1, copecks: 0}
                : {roubles, copecks};
    }

    static parse(currencyString: string): ICurrency {
        if (StringUtils.isNullOrEmpty(currencyString))
            return Currency.zero();
        const result = Currency.create(currencyString);
        if (Currency.isBigLength(result))
            throw new Error("Превышено максимально допустимое количество символов");
        if (Currency.isNoCurrency(result))
            throw new Error("Введенное значение содержит недопустимые символы");
        return result;
    }

    private static initFromString(currencyString: string): ICurrency {
        if (StringUtils.isNullOrEmpty(currencyString))
            return Currency.zero();
        currencyString = currencyString.replace(/[ \t\u00A0]+/g, "");

        const isNegative = currencyString.charAt(0) === "-";
        const components = (currencyString.indexOf(".") >= 0) ? currencyString.split(".")
            : currencyString.split(",");

        const _roubles = NumberUtils.toNumber(StringUtils.isNullOrEmpty(components[0]) ? 0 : components[0]);
        let _copecks = NumberUtils.toNumber(StringUtils.isNullOrEmpty(components[1]) ? 0 : components[1]);

        switch (components.length) {
            case 1:
                return Currency.initFromRoublesCopecks(components[0], 0);
            case 2:

                if (isNaN(_roubles) || isNaN(_copecks))
                    return Currency.initTrash();
                else {
                    _copecks = NumberUtils.toNumber(StringUtils.isNullOrEmpty(components[1]) ? 0 : padEnd(components[1], 2, "0"));
                    if (isNegative)
                        _copecks = -_copecks;
                    return Currency.initFromRoublesCopecks(_roubles, _copecks);
                }
            default:
                return Currency.initTrash();
        }
    }

    private static initTrash = (): ICurrency => (
        {roubles: NaN, copecks: NaN}
    );

    static equals(first: ICurrency, second: ICurrency): boolean {
        if (!first && !second)
            return true;
        if (Currency.isNoCurrency(first) || Currency.isNoCurrency(second))
            return false;
        return first.roubles === second.roubles && first.copecks === second.copecks;
    }

    static arraysEquals(firstArray: ICurrency[], secondArray: ICurrency[]): boolean {
        if (!firstArray && !secondArray)
            return true;
        if (!firstArray || !secondArray)
            return false;
        if (firstArray.length !== secondArray.length)
            return false;
        for (let i = 0, l = firstArray.length; i < l; ++i)
            if (!Currency.equals(firstArray[i], secondArray[i]))
                return false;
        return true;
    }

    static format(currency: ICurrency): string {
        if (Currency.isNoCurrency(currency))
            return "0";
        const absRoubles = Math.abs(currency.roubles);
        const absCopecks = Math.abs(currency.copecks);
        const roublesGroups = Currency.splitByThousands(absRoubles).join(" ");
        return (Currency.isNegative(currency) ? "-" : "") + roublesGroups + "." + (absCopecks < 10 ? "0" : "") + absCopecks;
    }

    static formatDisplayValue(currency: ICurrency, roundCurrencyToRoubles: boolean): string {
        let result = "";
        const digits = 3;
        currency = currency || Currency.zero();

        const roubles = Math.abs(currency.roubles).toString();
        let iter = Math.floor(roubles.length / digits);
        if (roubles.length % digits === 0)
            iter = iter - 1;

        result += roubles.substr(0, roubles.length - digits * iter);
        for (let i = iter; i > 0; i--) {
            result += "\u00A0" + roubles.substr(roubles.length - digits * i, digits);
        }

        const absCopecks = Math.abs(currency.copecks);
        const copecks = (Math.floor(absCopecks / 10) === 0) ? "0" + absCopecks : absCopecks + "";

        result += (currency.copecks === 0 && roundCurrencyToRoubles === true) ? "" : "." + copecks;
        if (currency.roubles < 0 || currency.copecks < 0) {
            result = "-" + result;
        }
        return result;
    }

    private static splitByThousands(value: number): string[] {
        const result: string[] = [];

        while (value > 1000) {
            result.push(StringUtils.insertLeadingZero(value % 1000, 3));
            value = Math.floor(value / 1000);
        }
        result.push(String(value));
        return result.reverse();
    }

    private static initFromRoublesCopecks = (roubles: string | number, copecks: number | string): ICurrency => (
        {roubles: NumberUtils.toNumber(roubles || 0), copecks: NumberUtils.toNumber(copecks || 0)}
    );

    private static normalize = (currency: ICurrency): ICurrency => (
        Currency.setTotalCopecks(Currency.getTotalCopecks(currency))
    );

    static getTotalCopecks = (currency: ICurrency): number => (
        currency.roubles * 100 + currency.copecks
    );

    static getFloatValue = (currency: ICurrency): number => (
        currency ? currency.roubles + currency.copecks / 100 : 0
    );

    static getString = (currency: ICurrency): string => {
        const numberPrice = Currency.getFloatValue(currency);
        const priceMoreThanMillion = numberPrice >= 1000000;
        const priceLessThan1000trillion = numberPrice < CURRENCY_BIG_LIMIT;

        return priceMoreThanMillion && priceLessThan1000trillion
            ? NumberUtils.formatLargeNumber(numberPrice)
            : numberPrice.toString();
    };

    static isBigLength = (currency: ICurrency): boolean => (
        Currency.getTotalCopecks(currency) > CURRENCY_BIG_LIMIT - 1
    );

    static hasValue = (currency: ICurrency): boolean => (
        !!currency.roubles || !!currency.copecks
    );

    static invert = (currency: ICurrency): ICurrency => {
        if (!currency) return Currency.zero();
        return Currency.create(-currency.roubles, -currency.copecks);
    };

    static abs = (currency: ICurrency): ICurrency => (
        Currency.isNegative(currency) ? Currency.invert(currency) : currency
    );

    static isNegative = (currency: ICurrency): boolean => (
        !Currency.isPositive(currency) && !Currency.isZero(currency)
    );

    static isPositive = (currency: ICurrency): boolean => (
        currency.roubles > 0 || currency.roubles === 0 && currency.copecks > 0
    );

    static isZero = (currency: ICurrency): boolean => (
        currency && currency.roubles === 0 && currency.copecks === 0
    );

    static isZeroOrNull = (currency: ICurrency): boolean => (
        !currency || Currency.isZero(currency)
    );

    static percent = (percent: number, currency: ICurrency): ICurrency => (
        Currency.fromTotalCopecks(Currency.round(Currency.getTotalCopecks(currency) * percent / 100))
    );

    private static round(value: number): number {
        const eps = 1e-8;
        const difference = value - Math.floor(value);
        return (Math.abs(0.5 - difference) > eps)
            ? Math.round(value)
            : Math.ceil(value);
    }

    static smaller = (left: ICurrency, right: ICurrency): boolean => (
        !left || !right ? false : Currency.isNegative(Currency.subtract(left, right))
    );

    static greater = (left: ICurrency, right: ICurrency): boolean => (
        !left || !right ? false : Currency.isNegative(Currency.subtract(right, left))
    );

    static min = (left: ICurrency, right: ICurrency): ICurrency => (
        Currency.smaller(left, right) ? left : right
    );

    static max = (left: ICurrency, right: ICurrency): ICurrency => (
        Currency.greater(left, right) ? left : right
    );

    static getMin = (currencies: ICurrency[]): ICurrency => (
        currencies.reduce(Currency.min, {roubles: Number.POSITIVE_INFINITY, copecks: 0})
    );

    static getMax = (currencies: ICurrency[]): ICurrency => (
        currencies.reduce(Currency.max, {roubles: Number.NEGATIVE_INFINITY, copecks: 0})
    );

    static roundToRoubles = (currency: ICurrency): ICurrency => (
        Currency.create(0, Math.round(Currency.getTotalCopecks(currency) / 100) * 100)
    );

    static roundByRule(currency: ICurrency, roundRule: RoundingRule) {
        if (roundRule === RoundingRule.None)
            return currency;
        if (roundRule === RoundingRule.DiscardCopecks)
            return {Roubles: currency.roubles, Copecks: 0};
        if (roundRule === RoundingRule.MathematicalLogic)
            return this.roundToRoubles(currency);
        throw new Error("unexpected round rule!");
    }

    static setTotalCopecks = (totalCopecks: number): ICurrency => ({
        roubles: (totalCopecks - totalCopecks % 100) / 100,
        copecks: Math.floor(totalCopecks % 100)
    });

    static getDisplayValue = (currencyValue: ICurrency): number => {
        if (!currencyValue || Currency.isNoCurrency(currencyValue))
            return null;
        return Currency.getFloatValue(currencyValue);
    };
}
