import {Select, Gapped} from "@skbkontur/react-ui";
import cn from "classnames";
import {IRoomCategory} from "@skbkontur/hotel-data/roomCategory";
import {RoomCategoryLabel} from "@skbkontur/hotel-components/roomCategoryLabel";
import {createMapBy} from "@skbkontur/hotel-utils";
import {Ellipsis} from "@skbkontur/hotel-components/ellipsis";
import WidgetStore from "../../widget/WidgetStore";
import {CalendarMode} from "../../data/Calendar/Calendar";
import {CalendarContext} from "../Calendar/Provider/CalendarContext";
import styles from "./RoomAvailabilityCalendarSelect.scss";

interface IRoomAvailabilityCalendarSelectProps {
    categories: IRoomCategory[];
    roomCategoryId: string;
    onValueChange: (newRoomCategoryId: string) => void;
}

const RoomAvailabilityCalendarSelect = (props: IRoomAvailabilityCalendarSelectProps) => {
    const {categories, roomCategoryId, onValueChange} = props;

    const {calendarMode} = React.useContext(CalendarContext);

    const categoriesMap = createMapBy(categories, rc => rc.id);
    const items = categories.map(rc => rc.id);

    const renderValue = (roomCategoryId: string) => (
        categoriesMap[roomCategoryId].name
    );

    const renderSelectItem = (roomCategoryId: string) => (
        <Gapped gap={4} vertical>
            <div className={styles.selectItemTitle}>{renderValue(roomCategoryId)}</div>
            <RoomCategoryLabel
                category={categoriesMap[roomCategoryId]}
                modifyImageUrl={WidgetStore.getUrlWithBase}
                small
                withoutTooltip
            />
            <Ellipsis className={styles.selectItemDescription} lineClamp={2}>
                {categoriesMap[roomCategoryId]?.description}
            </Ellipsis>
        </Gapped>
    );

    return (
        <Select<string>
            className={cn(styles.select, {[styles.fullWidth]: calendarMode === CalendarMode.OneMonth})}
            onValueChange={onValueChange}
            value={roomCategoryId}
            items={items}
            renderItem={renderSelectItem}
            renderValue={renderValue}
            size="medium"
            menuWidth={400}
        />
    );
};
RoomAvailabilityCalendarSelect.displayName = "RoomAvailabilityCalendarSelect";
export default RoomAvailabilityCalendarSelect;
