import "./ImportLocales";
import dayjs from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport";
import utc from "dayjs/plugin/utc";
import isoWeek from "dayjs/plugin/isoWeek";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import duration, {CreateDurationType} from "dayjs/plugin/duration";

dayjs.extend(objectSupport);
dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(duration); // https://day.js.org/docs/en/durations/durations

declare module "dayjs" {
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-unused-vars
    interface Dayjs {
        duration: CreateDurationType;
    }
}

export default dayjs;
