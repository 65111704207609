import BookingLightboxSuccess from "../../components/Lightbox/BookingLightboxSuccess/BookingLightboxSuccess";
import {BookingSuccessContext} from "./BookingSuccessContext";
import {useMemoObject} from "@skbkontur/hotel-hooks/memo";

export const BookingSuccessProvider = (props: React.PropsWithChildren<object>) => {
    const {children} = props;

    const [isSuccessLightboxOpened, setIsSuccessLightboxOpened] = React.useState(false);

    const openBookingLightboxSuccess = () => setIsSuccessLightboxOpened(true);
    const closeBookingLightboxSuccess = () => setIsSuccessLightboxOpened(false);

    return (
        <BookingSuccessContext.Provider value={useMemoObject({isSuccessLightboxOpened, openBookingLightboxSuccess})}>
            {children}
            {isSuccessLightboxOpened && <BookingLightboxSuccess onClose={closeBookingLightboxSuccess} />}
        </BookingSuccessContext.Provider>
    );
};
