import {IIconProps, IconTypes} from "./Icon";
import {QuestionCircleIcon16Solid} from "@skbkontur/icons/QuestionCircleIcon16Solid";
import {QuestionCircleIcon16Light} from "@skbkontur/icons/QuestionCircleIcon16Light";
import {QuestionCircleIcon20Regular} from "@skbkontur/icons/QuestionCircleIcon20Regular";

type HelpDotIconTypes = IconTypes.Solid16 | IconTypes.Light16 | IconTypes.Regular20;

interface IHelpDotIconProps extends IIconProps {
    type: HelpDotIconTypes;
}

const HelpDotIcon = ({type, ...iconProps}: IHelpDotIconProps) => {
    switch (type) {
        case IconTypes.Solid16:
            return <QuestionCircleIcon16Solid {...iconProps} />;
        case IconTypes.Light16:
            return <QuestionCircleIcon16Light {...iconProps} />;
        case IconTypes.Regular20:
            return <QuestionCircleIcon20Regular {...iconProps} />;
    }
};
HelpDotIcon.displayName = "HelpDotIcon";
export default HelpDotIcon;
