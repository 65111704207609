import {useMemoObject} from "@skbkontur/hotel-hooks/memo";
import {CalendarMode} from "../../../data/Calendar/Calendar";
import {DeviceContext} from "../../../providers/Device/DeviceContext";
import {CalendarContext} from "./CalendarContext";

export interface ICalendarProviderProps {
    calendarMode: CalendarMode;
}

const CalendarProvider = (props: React.PropsWithChildren<ICalendarProviderProps>) => {
    const {children, calendarMode} = props;

    const {isMobileMode} = React.useContext(DeviceContext);

    const getMonthsCount = () => (
        calendarMode === CalendarMode.OneMonth
            ? 1
            : isMobileMode ? 1 : 2
    );

    const [monthsToDisplay, setMonthsToDisplay] = React.useState<1 | 2>(getMonthsCount());
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        setMonthsToDisplay(getMonthsCount());
    }, [calendarMode, isMobileMode]);

    return (
        <CalendarContext.Provider value={useMemoObject({
            calendarMode,
            monthsToDisplay,
            loading,
            setLoading
        })}
        >
            {children}
        </CalendarContext.Provider>
    );
};
CalendarProvider.displayName = "CalendarProvider";
export default CalendarProvider;
