import HourlyObjectsCard from "../EntityCard/HourlyObject/HourlyObjectsCard";
import HourlyObjectsListCheckButton from "./HourlyObjectsListCheckButton";
import {HourlyBookingContext} from "../../providers/HourlyBooking/HourlyBookingContext";
import {Flipper} from "react-flip-toolkit";
import HourlyObjectsListFlipper from "./HourlyObjectsListFlipper";
import {IHourlyObjectEntity, useHourlyObjectsEntities} from "./hooks/useHourlyObjectsEntities";
import styles from "../RoomCategoriesList/RoomCategoriesList.scss";

const HourlyObjectsList = () => {
    const {isBookingProcess, openBookingLightbox} = React.useContext(HourlyBookingContext);
    const hourlyObjects = useHourlyObjectsEntities();

    return (
        <div className={styles.container}>
            <Flipper flipKey={JSON.stringify(hourlyObjects.map(rc => rc.key))}>
                {hourlyObjects.map((el: IHourlyObjectEntity, index: number) => (
                    <HourlyObjectsListFlipper
                        key={el.key}
                        hourlyObjectId={el.hourlyObject.id}
                        isLast={index === hourlyObjects.length - 1}
                    >
                        <HourlyObjectsCard
                            autoPlaySlider={!isBookingProcess}
                            hourlyObjectItemId={el.hourlyObjectItemId}
                            hourlyObject={el.hourlyObject}
                            isListView
                        >
                            <HourlyObjectsListCheckButton
                                hourlyObjectItemId={el.hourlyObjectItemId}
                                hourlyObjectId={el.hourlyObject.id}
                                onClick={openBookingLightbox}
                            />
                        </HourlyObjectsCard>
                    </HourlyObjectsListFlipper>
                ))}
            </Flipper>
        </div>
    );
};
HourlyObjectsList.displayName = "HourlyObjectsList";
export default HourlyObjectsList;
