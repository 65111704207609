/**
 * Тип виджета
 *
 * @remarks
 * На одной странице может быть встроено несколько типов виджета.
 * В том числе несколько виджетов одного типа: например форма бронирования вверху страницы и форма бронирования внизу страницы.
 */
export enum WidgetType {
    /**
     * Форма поиска номеров
     */
    BookingForm = "bookingForm",
    /**
     * Вертикальная форма поиска номеров
     * @deprecated Устаревший тип виджета. Используйте тип {@link WidgetType.BookingForm} с указанием настройки расположения.
     */
    VerticalBookingForm = "verticalBlock",
    /**
     * Горизонтальная форма поиска номеров
     * @deprecated Устаревший тип виджета. Используйте тип {@link WidgetType.BookingForm} с указанием настройки расположения.
     */
    HorizontalBookingForm = "horizontalBlock",
    RoomsList = "roomsList",
    HourlyObjectsList = "hourlyObjectsList",
    AvailabilityCalendar = "availabilityCalendar",
    /**
     * Календарь на 2 месяца
     * @deprecated Устаревший тип виджета. Используйте тип {@link WidgetType.AvailabilityCalendar} с указанием числа месяцев для отображения
     */
    HorizontalAvailabilityCalendar = "horizontalAvailabilityCalendar",
    /**
     * Календарь на 1 месяца
     * @deprecated Устаревший тип виджета. Используйте тип {@link WidgetType.AvailabilityCalendar} с указанием числа месяцев для отображения
     */
    VerticalAvailabilityCalendar = "verticalAvailabilityCalendar",
    ShowCheckAvailabilityButtonForMobileDevices = "showCheckAvailabilityButtonForMobileDevices"
}