import {captureSentryError, SentryErrorType} from "@skbkontur/hotel-sentry";
import HotelBookingSearchForm from "../../../components/BookingSearchForm/HotelBookingSearchForm";
import {withWidgetContainerAndRefHOC} from "../Widget/withWidgetContainerAndRefHOC";
import {WidgetClass} from "../../Widget";
import {WidgetType} from "../../settings/WidgetType";


interface IWidgetBookingFormProps {
    widget: WidgetClass;
}

const WidgetBookingForm = (props: IWidgetBookingFormProps) => {
    const {widget} = props;
    const {settings} = widget;

    const getInline = () => {
        switch (settings.type) {
            case WidgetType.HorizontalBookingForm:
                return true;
            case WidgetType.VerticalBookingForm:
                return false;
            case WidgetType.BookingForm:
                return !!settings.inline;
            default:
                captureSentryError(`Unexpected widget calendar type ${settings.type}`, SentryErrorType.SwitchCase);
        }
    };

    return (
        <HotelBookingSearchForm inline={getInline()}/>
    );
};
WidgetBookingForm.displayName = "WidgetBookingControl";
export default withWidgetContainerAndRefHOC(WidgetBookingForm);
