import cn from "classnames";
import styles from "../RoomAvailabilityCalendarWithFilter/RoomAvailabilityCalendarWithFilter.scss";
import {CalendarMode} from "../../data/Calendar/Calendar";

export interface IRoomAvailabilityCalendarBaseProps {
    inlayMode?: boolean;
    calendarMode: CalendarMode;
}

const RoomAvailabilityCalendarContainer = (props: React.PropsWithChildren<IRoomAvailabilityCalendarBaseProps>) => {
    const {inlayMode, children, calendarMode} = props;

    return (
        <div
            className={cn(styles.container, {
                [styles.inlay]: !!inlayMode,
                [styles.singleMonth]: calendarMode === CalendarMode.OneMonth,
                [styles.twoMonths]: calendarMode === CalendarMode.TwoMonths
            })}
        >
            {children}
        </div>
    );
};
RoomAvailabilityCalendarContainer.displayName = "RoomAvailabilityCalendarContainer";
export default RoomAvailabilityCalendarContainer;