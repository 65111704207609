import cn from "classnames";
import styles from "./EntityCard.scss";

interface IEntityCardSliderProps {
    miniMode?: boolean;
}

const EntityCardSlider = ({miniMode, children}: React.PropsWithChildren<IEntityCardSliderProps>) => (
    <div className={cn(styles.slider, {[styles.miniMode]: miniMode})}>
        {children}
    </div>
);
EntityCardSlider.displayName = "EntityCardSlider";
export default EntityCardSlider;
