import {createRoot} from "react-dom/client";
import {Upgrade} from "@skbkontur/react-ui/lib/Upgrades";
import {tracker} from "@skbkontur/km-tracker";
import {getStageENV, isStageLocal} from "@skbkontur/hotel-process-env";
import {trackerENVType} from "@skbkontur/km-tracker/dist/typings/src/resourses/endpoints";
import {initSentry} from "@skbkontur/hotel-sentry";
import {createBrowserHistory} from "history";
import {WidgetSettingsRoots} from "./widget/settings/WidgetSettingsRoots";
import {IWidgetPublicInitialSettings} from "./widget/settings/WidgetInitialSettings";
import WidgetStore from "./widget/WidgetStore";
import WidgetApp from "./widget/WidgetApp";
import {WidgetSettingsInitializer} from "./widget/settings/WidgetSettingsInitializer";
import {WidgetClass} from "./widget/Widget";
import {KnownAllWidgetSettings} from "./widget/settings/WidgetInstanceSettings";

/** To override user site styles */
Upgrade.setSpecificityLevel(1);
module?.hot?.accept();

const widgetAppRef = React.createRef<WidgetApp>();
const widgetSettingsInitializer = new WidgetSettingsInitializer();
const history = createBrowserHistory();

export const init = (initialSettings: IWidgetPublicInitialSettings, baseUrl?: string) => {
    if (initialSettings?.version === "2") {
        widgetSettingsInitializer.initializeSettingsV2(initialSettings);
        WidgetStore.baseUrl = initialSettings.baseUrl.replace(/\/$/, "");
    } else {
        widgetSettingsInitializer.initializeSettingsV1(initialSettings);
        WidgetStore.baseUrl = baseUrl.replace(/\/$/, "");
    }

    tracker.initialize({
        siteID: 154,
        trackerENV: getStageENV() as trackerENVType,
        options: {catchPageVisits: true, catchUnloadEvent: true},
        devMode: isStageLocal()
    });

    initSentry({
        options: {
            dsn: "https://e4f2ddb21207410aa0af246214fa95d0@sentry.kontur.host/301"
        },
        useProfiling: false,
        reactRouterOptions: {history}
    });

    try {
        if (widgetSettingsInitializer.validator.isValidationOk()) {
            const {hotelId} = WidgetStore.settings;
            const appRootId = WidgetSettingsRoots.getAppRootId(hotelId);

            if (!document.getElementById(appRootId)) {
                // eslint-disable-next-line no-console
                console.log("Widget settings validation is OK");


                const container = WidgetSettingsRoots.createAppRoot(hotelId);
                const root = createRoot(container);
                root.render(<WidgetApp ref={widgetAppRef}/>);
            } else {
                // eslint-disable-next-line no-console
                console.log("Widget already exist - skip initializing, just update the current widget...");
                widgetAppRef.current?.refreshApp();
            }
        }
    } catch (error) {
        WidgetStore.settings.hooks?.onError(error);
        throw error;
    }
};

export const add = (widget: KnownAllWidgetSettings): string => {
    const {type} = widget;

    if (!widgetSettingsInitializer.validator.isValidType(type))
        return;

    const widgetId = widgetSettingsInitializer.addWidget(widget);
    if (!widgetId)
        return;

    widgetAppRef.current?.refreshApp();
    return widgetId;
};

export const isExists = (widgetId: string): boolean => {
    const widget = WidgetStore.getWidgetById(widgetId);
    if (!isWidgetExists(widgetId, widget, "find"))
        return;
};

export const remove = (widgetId: string): string => {
    const widget = WidgetStore.getWidgetById(widgetId);
    if (!isWidgetExists(widgetId, widget, "remove"))
        return;

    WidgetStore.removeElement(widgetId);
    widgetAppRef.current?.refreshApp();

    return widgetId;
};

export const update = (widgetId?: string) => {
    if (!widgetId) {
        WidgetStore.settings.widgets.forEach(w => w.update());
        return;
    }

    const widget = WidgetStore.getWidgetById(widgetId);
    if (!isWidgetExists(widgetId, widget, "update"))
        return;

    widget.update();
};

const isWidgetExists = (wantedWidgetId: string, widget: WidgetClass | null, action: string): widget is WidgetClass => {
    if (!widget) {
        // eslint-disable-next-line no-console
        console.error(`Can't ${action} widget with id="${wantedWidgetId}". Widget instance was not found in the settings. You are refers to the widget that wasn't initialized.`);
        return false;
    }
    if (!widget.exist) {
        // eslint-disable-next-line no-console
        console.error(`Can't ${action} widget with id="${wantedWidgetId}". Widget instance exist, but widget not rendered because of another errors.`);
        return false;
    }
    return true;
};