import WidgetStore from "../WidgetStore";
import {WidgetSettingsNormalizer} from "./WidgetSettingsNormalizer";
import {
    IWidgetInitialSettingsV1,
    IWidgetInitialSettings
} from "./WidgetInitialSettings";
import {WidgetSettingsValidator} from "./WidgetSettingsValidator";
import {IWidgetHooks} from "./WidgetSettingsHooks";
import {WidgetInsertType} from "./WidgetCommon";
import {KnownAllWidgetSettings} from "./WidgetInstanceSettings";
import {IWidgetSettingsAppearance} from "./WidgetSettings";
import {WidgetType} from "./WidgetType";

export class WidgetSettingsInitializer {
    validator: WidgetSettingsValidator = new WidgetSettingsValidator();

    initializeSettingsV1 = (initial: IWidgetInitialSettingsV1) => {
        const {id, hooks, type, form, roomsList} = initial;

        WidgetStore.addWidgetSettings({
            hotelId: id,
            version: null,
            widgets: [],
            hooks: this.initializeHooks(hooks)
        });

        if (this.validator.isValidType(type) && form) {
            this.addWidget({type, appearance: form});
        }

        if (roomsList) {
            this.addWidget({type: WidgetType.RoomsList, appearance: roomsList});
        }

        // eslint-disable-next-line no-console
        console.warn(
            "You are using the deprecated version of widget settings." +
            "Please generate a new version of the widget settings in the hotel website settings."
        );
    };

    initializeSettingsV2 = (initial: IWidgetInitialSettings) => {
        const {hotelId, version, hooks, theme} = initial;

        WidgetStore.addWidgetSettings({
            hotelId,
            version,
            widgets: [],
            hooks: this.initializeHooks(hooks),
            theme
        });
    };

    private initializeHooks = (hooks: IWidgetHooks) => ({
        onError: this.makeHookSafety(hooks?.onError),
        onBooking: this.makeHookSafety(hooks?.onBooking),
        onHourlyBooking: this.makeHookSafety(hooks?.onHourlyBooking),
        onInit: this.makeHookSafety(hooks?.onInit),
    });

    addWidget = (widgetSettings: KnownAllWidgetSettings): string => {
        const {appearance, type} = widgetSettings;

        if (!this.validator.isValidAppearance(appearance, type)) {
            /* eslint-disable-next-line no-console */
            console.error(`Invalid widget settings with type="${type}"`);
            return;
        }

        return WidgetStore.addWidget({
            ...widgetSettings,
            appearance: this.normalizeContainer(appearance)
        });
    };

    /* eslint-disable  @typescript-eslint/no-explicit-any *//* Because of common hook */
    private makeHookSafety = <THook extends (...args: any) => void>(hook: THook): THook => (
        (...args) => {
            try {
                return hook?.(...args);
            } catch (error) {
                // error intended to a client, so there is no need for Sentry
                /* eslint-disable-next-line no-console */
                console.error(`error with widget initialization hook: ${JSON.stringify(error)}`);
            }
        }
    ) as THook;

    private normalizeContainer = (appearance: IWidgetSettingsAppearance): IWidgetSettingsAppearance => ({
        insert: appearance.insert || WidgetInsertType.After,
        container: WidgetSettingsNormalizer.normalizeContainer(appearance.container)
    });
}
